import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  GET_ALL_Status_URL,
  GET_MAINTENANCE_STATUS_URL,
  GET_ALL_MODULES,
  GET_ALL_ADMIN_PROJECTS,
  GET_ALL_COPY_GEOPGRAPHICAL_LOCATION_TO_TEMPLATE,
  GET_ALL_COPY_STAKEHOLDER_TO_TEMPLATE,
  GET_ALL_COPY_VALUE_CHAIN_TO_TEMPLATE,
  GET_ALL_COPY_SOURCES_TO_TEMPLATE,
  GET_ALL_COPY_APS_TO_TEMPLATE,
  GET_ALL_COPY_ALL_TO_TEMPLATE,
  GET_ALL_COPY_IRO_MODULE_TEMPLATE,
} from "../../services/Api";

const handleApiError = (error, rejectWithValue) => {
  console.error("API Error:", error);
  return rejectWithValue(
    error.response?.data?.message ||
      error.response?.data?.title ||
      error.message ||
      "API Error"
  );
};

const apiRequest = async (method, url, data, token, rejectWithValue) => {
  try {
    const response = await axios({
      method,
      url,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    return handleApiError(error, rejectWithValue);
  }
};

export const getAllAdminProjects = createAsyncThunk(
  "getAllAdminProjects",
  async ({ token }, { rejectWithValue }) => {
    return apiRequest(
      "get",
      GET_ALL_ADMIN_PROJECTS,
      [],
      token,
      rejectWithValue
    );
  }
);

export const getAllStatus = createAsyncThunk(
  "getAllStatus",
  async ({ token }, { rejectWithValue }) => {
    return apiRequest("get", GET_ALL_Status_URL, null, token, rejectWithValue);
  }
);

export const getMaintainenceStatus = createAsyncThunk(
  "getMaintainenceStatus",
  async (
    { isActive, messageWhenInactive, lastModifiedBy, token },
    { rejectWithValue }
  ) => {
    const data = {
      status: isActive,
      messageWhenInactive,
      lastModifiedBy,
    };
    return apiRequest(
      "post",
      GET_MAINTENANCE_STATUS_URL,
      data,
      token,
      rejectWithValue
    );
  }
);

export const getAllModules = createAsyncThunk(
  "getAllModules",
  async ({ token }, { rejectWithValue }) => {
    return apiRequest("get", GET_ALL_MODULES, null, token, rejectWithValue);
  }
);

export const copyAllModuleTablesTotemplate = createAsyncThunk(
  "copyAllModuleTablesTotemplate",
  async ({ moduleId, token }, { rejectWithValue }) => {
    return apiRequest(
      "get",
      `${GET_ALL_COPY_ALL_TO_TEMPLATE}/${moduleId}`,
      null,
      token,
      rejectWithValue
    );
  }
);

export const copySourcesToTemplate = createAsyncThunk(
  "copySourcesToTemplate",
  async ({ moduleId, token }, { rejectWithValue }) => {
    return apiRequest(
      "get",
      `${GET_ALL_COPY_SOURCES_TO_TEMPLATE}/${moduleId}`,
      null,
      token,
      rejectWithValue
    );
  }
);

export const copyValueChainToTemplate = createAsyncThunk(
  "copyValueChainToTemplate",
  async ({ moduleId, token }, { rejectWithValue }) => {
    return apiRequest(
      "get",
      `${GET_ALL_COPY_VALUE_CHAIN_TO_TEMPLATE}/${moduleId}`,
      null,
      token,
      rejectWithValue
    );
  }
);

export const copyActProServiceToTemplate = createAsyncThunk(
  "copyActProServiceToTemplate",
  async ({ moduleId, token }, { rejectWithValue }) => {
    return apiRequest(
      "get",
      `${GET_ALL_COPY_APS_TO_TEMPLATE}/${moduleId}`,
      null,
      token,
      rejectWithValue
    );
  }
);

export const copyGeographicalLocationToTemplate = createAsyncThunk(
  "copyGeographicalLocationToTemplate",
  async ({ moduleId, token }, { rejectWithValue }) => {
    return apiRequest(
      "get",
      `${GET_ALL_COPY_GEOPGRAPHICAL_LOCATION_TO_TEMPLATE}/${moduleId}`,
      null,
      token,
      rejectWithValue
    );
  }
);

export const copyStakeHolderToTemplate = createAsyncThunk(
  "copyStakeHolderToTemplate",
  async ({ moduleId, token }, { rejectWithValue }) => {
    return apiRequest(
      "get",
      `${GET_ALL_COPY_STAKEHOLDER_TO_TEMPLATE}/${moduleId}`,
      null,
      token,
      rejectWithValue
    );
  }
);

export const copyModuleIROToTemplate = createAsyncThunk(
  "copyModuleIROToTemplate",
  async ({ moduleId, token }, { rejectWithValue }) => {
    return apiRequest(
      "get",
      `${GET_ALL_COPY_IRO_MODULE_TEMPLATE}/${moduleId}`,
      null,
      token,
      rejectWithValue
    );
  }
);

const initialState = {
  isLoading: false,
  data: null,
  isError: false,
  statusUpdate: false,
  errorMessage: "",
};

const handlePending = (state) => {
  state.isLoading = true;
  state.errorMessage = "";
};

const handleFulfilled = (state, action) => {
  state.isLoading = false;
  if (
    action.type === getAllModules.fulfilled.type ||
    action.type === getAllAdminProjects.fulfilled.type
  ) {
    state.data = Array.isArray(action.payload) ? action.payload : null;
  } else {
    state.data = action.payload;
  }
};

const handleRejected = (state, action) => {
  state.isLoading = false;
  state.isError = true;
  state.errorMessage = action.payload;
};

const getAllStatusSlice = createSlice({
  name: "allStatus",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllStatus.pending, handlePending)
      .addCase(getAllStatus.fulfilled, handleFulfilled)
      .addCase(getAllStatus.rejected, handleRejected)
      .addCase(getMaintainenceStatus.pending, handlePending)
      .addCase(getMaintainenceStatus.fulfilled, (state, action) => {
        handleFulfilled(state, action);
        state.statusUpdate = true;
      })
      .addCase(getMaintainenceStatus.rejected, handleRejected)
      .addCase(getAllModules.pending, handlePending)
      .addCase(getAllModules.fulfilled, handleFulfilled)
      .addCase(getAllModules.rejected, handleRejected)
      .addCase(copyAllModuleTablesTotemplate.pending, handlePending)
      .addCase(copyAllModuleTablesTotemplate.fulfilled, handleFulfilled)
      .addCase(copyAllModuleTablesTotemplate.rejected, handleRejected)
      .addCase(copySourcesToTemplate.pending, handlePending)
      .addCase(copySourcesToTemplate.fulfilled, handleFulfilled)
      .addCase(copySourcesToTemplate.rejected, handleRejected)
      .addCase(copyValueChainToTemplate.pending, handlePending)
      .addCase(copyValueChainToTemplate.fulfilled, handleFulfilled)
      .addCase(copyValueChainToTemplate.rejected, handleRejected)
      .addCase(copyActProServiceToTemplate.pending, handlePending)
      .addCase(copyActProServiceToTemplate.fulfilled, handleFulfilled)
      .addCase(copyActProServiceToTemplate.rejected, handleRejected)
      .addCase(copyGeographicalLocationToTemplate.pending, handlePending)
      .addCase(copyGeographicalLocationToTemplate.fulfilled, handleFulfilled)
      .addCase(copyGeographicalLocationToTemplate.rejected, handleRejected)
      .addCase(copyStakeHolderToTemplate.pending, handlePending)
      .addCase(copyStakeHolderToTemplate.fulfilled, handleFulfilled)
      .addCase(copyStakeHolderToTemplate.rejected, handleRejected)
      .addCase(getAllAdminProjects.pending, handlePending)
      .addCase(getAllAdminProjects.fulfilled, handleFulfilled)
      .addCase(getAllAdminProjects.rejected, handleRejected)
      .addCase(copyModuleIROToTemplate.pending, handlePending)
      .addCase(copyModuleIROToTemplate.fulfilled, handleFulfilled)
      .addCase(copyModuleIROToTemplate.rejected, handleRejected);
  },
});

export default getAllStatusSlice.reducer;