import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

const GaugeChart = ({ value, min, max, label }) => {
  const svgRef = useRef(null);

  useEffect(() => {
    const width = 300;
    const height = 120;
    const radius = Math.min(width, height) * 0.8;
    const clampedValue = Math.max(min, Math.min(value, max));
    let arcAngle = 1.6;

    if (max !== min) {
      const scale = d3
        .scaleLinear()
        .domain([min, max])
        .range([-Math.PI / 2, Math.PI / 2]);
      arcAngle = scale(clampedValue);
    }
    const svg = d3
      .select(svgRef.current)
      .attr("width", width)
      .attr("height", height);

    svg.selectAll("*").remove();

    const g = svg
      .append("g")
      .attr("transform", `translate(${width / 2}, ${height - 10})`);

    const backgroundArc = d3
      .arc()
      .innerRadius(radius * 0.6)
      .outerRadius(radius)
      .startAngle(-Math.PI / 2)
      .endAngle(Math.PI / 2);

    g.append("path").attr("d", backgroundArc).attr("fill", "#E6E6E9");

    const valueArc = d3
      .arc()
      .innerRadius(radius * 0.6)
      .outerRadius(radius)
      .startAngle(-Math.PI / 2)
      .endAngle(arcAngle);

    g.append("path").attr("d", valueArc).attr("fill", "#FFE600");

    svg
      .append("text")
      .attr("x", width / 2)
      .attr("y", height - 30)
      .attr("text-anchor", "middle")
      .attr("font-size", "24px")
      .attr("font-weight", "bold")
      .attr("fill", "#444")
      .text(parseInt(clampedValue));

    svg
      .append("text")
      .attr("x", width / 2 - radius * 1.2)
      .attr("y", height - 11)
      .attr("text-anchor", "middle")
      .attr("font-size", "12px")
      .attr("font-weight", "bold")
      .attr("fill", "#666")
      .text(min);

    svg
      .append("text")
      .attr("x", width / 2 + radius * 1.2)
      .attr("y", height - 10)
      .attr("text-anchor", "middle")
      .attr("font-size", "12px")
      .attr("font-weight", "bold")
      .attr("fill", "#666")
      .text(max);
  }, [value, min, max]);

  return (
    <div className="flex flex-col items-center">
      <h3 className="text-lg font-semibold mb-2">{label}</h3>
      <svg ref={svgRef}></svg>
    </div>
  );
};

export default GaugeChart;
