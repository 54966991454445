import React, { useState, useRef } from "react";
import "./ReferenceListTab.scss";
import { MotifBadge } from "@ey-xd/motif-react";
import PropTypes from "prop-types";

const ReferenceListTab = ({
  cardHeader,
  sourcesData,
  valueChain,
  aps,
  stakeholder,
  geographies,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const tabsHeaderRef = useRef(null);

  const tabs = [
    {
      header: "Sources",
      content: sourcesData,
    },
    {
      header: "Valuechain",
      content: valueChain,
    },
    { header: "APS", content: aps },
    {
      header: "Stakeholders",
      content: stakeholder,
    },
    {
      header: "Geographies",
      content: geographies,
    },
  ];

  const handleMouseDown = (e) => {
    const slider = tabsHeaderRef.current;
    slider.isDown = true;
    slider.startX = e.pageX - slider.offsetLeft;
    slider.scrollLeft = slider.scrollLeft;
  };

  const handleMouseLeave = () => {
    const slider = tabsHeaderRef.current;
    slider.isDown = false;
  };

  const handleMouseUp = () => {
    const slider = tabsHeaderRef.current;
    slider.isDown = false;
  };

  const handleMouseMove = (e) => {
    const slider = tabsHeaderRef.current;
    if (!slider.isDown) return;
    e.preventDefault();
    const x = e.pageX - slider.offsetLeft;
    const walk = (x - slider.startX) * 2; // 2 for faster scroll
    slider.scrollLeft = slider.scrollLeft - walk;
  };

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <div className="tabs-container">
      <h2 className="referenceList-title">{cardHeader}</h2>
      <div
        className="tabs-header"
        ref={tabsHeaderRef}
        onMouseDown={handleMouseDown}
        onMouseLeave={handleMouseLeave}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
      >
        {tabs.map((tab, index) => (
          <button
            key={index}
            className={`tab-button ${activeTab === index ? "active" : ""}`}
            onClick={() => handleTabClick(index)}
            aria-label={`Switch to ${tab.header} tab`}
          >
            {tab.header}
            <MotifBadge
              className={`iro-reference-badge ${
                activeTab === index ? "active" : ""
              }`}
              value={tab.content.length}
            ></MotifBadge>
          </button>
        ))}
      </div>

      <div className="tabs-content">
        {tabs[activeTab].content.map((item) => (
          <p key={item.id} className="iro-reference-list-item">
            {item.referenceList} {item.name}
          </p>
        ))}
      </div>
    </div>
  );
};

// PropTypes validation
ReferenceListTab.propTypes = {
  cardHeader: PropTypes.string.isRequired, // Card header must be a string
  sourcesData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      referenceList: PropTypes.string,
      name: PropTypes.string,
    })
  ).isRequired, // Array of objects for sourcesData
  valueChain: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      referenceList: PropTypes.string,
      name: PropTypes.string,
    })
  ).isRequired, // Array of objects for valueChain
  aps: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      referenceList: PropTypes.string,
      name: PropTypes.string,
    })
  ).isRequired, // Array of objects for aps
  stakeholder: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      referenceList: PropTypes.string,
      name: PropTypes.string,
    })
  ).isRequired, // Array of objects for stakeholder
  geographies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      referenceList: PropTypes.string,
      name: PropTypes.string,
    })
  ).isRequired, // Array of objects for geographies
};

export default ReferenceListTab;
