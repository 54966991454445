import React, { useState, useEffect, forwardRef } from "react";
import {
  MotifLabel,
  MotifButton,
  MotifInput,
  MotifFormField,
  MotifErrorMessage,
  MotifSelect,
  MotifOption,
} from "@ey-xd/motif-react";
import PropTypes from "prop-types";
import { TextField, IconButton } from "@mui/material";
import "./IroAssessmentModal.scss";
import { useTranslation } from "react-i18next";
import { Cross, InfoIcon } from "../../../constants/icons/Icons";
import Select from "react-select";
import DeleteModal from "../deleteModal/DeleteModal";
import CustomTooltip from "../../../constants/customTooltip/CustomTooltip";

const MAX_CHAR_LIMIT = 3000;

const IroAssessmentModal = forwardRef(
  (
    {
      iroType,
      onClose,
      modalMode,
      modalTitle,
      ratingDropDownArray,
      saveButtonLabel,
      updateButtonLabel,
      cancelButtonLabel,
      valueChainDropDown,
      handleDoneClick,
      deleteButtonLabel,
      ratingLabel,
      descriptionLabel,
      iroTypeLabel,
      valueChainLabel,
      actualPotentialLabel,
      filteredUpdateData,
      moduleId,
      moduleIROId,
      handleDelete,
      infoRating,
      likelihood,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const isEditMode = modalMode === "edit";
    const [selectedTab, setSelectedTab] = useState("Basic");
    const [info, setInfo] = useState(null);
    const [basicFormData, setBasicFormData] = useState({
      actual: null,
      valueChainId: [],
    });
    const [tabFormData, setTabFormData] = useState({
      Scale: { id: 0, description: "" },
      Scope: { id: 0, description: "" },
      Remediability: { id: 0, description: "" },
      Magnitude: { id: 0, description: "" },
      Likelihood: { id: 0, description: "" },
    });
    const [actualValidation, setActualValidation] = useState("");
    const [descriptionErrors, setDescriptionErrors] = useState({});
    const [ratingErrors, setRatingErrors] = useState({});
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [deleteItemID, setDeleteItemID] = useState(null);
    const [tabConditions, setTabConditions] = useState({
      scaleDisabled: true,
      scopeDisabled: true,
      remediabilityDisabled: true,
      magnitudeDisabled: true,
      likelihoodDisabled: true,
    });

    React.useImperativeHandle(ref, () => ({
      handleDeleteCloseModal,
    }));

    useEffect(() => {
      if (filteredUpdateData && isEditMode) {
        setBasicFormData({
          actual: filteredUpdateData.assessment.isActual,
          valueChainId: filteredUpdateData.valueChainIds,
        });
        setTabFormData({
          Scale: {
            id: filteredUpdateData.assessment.moduleRatingScaleId || null,
            description:
              filteredUpdateData.assessment.ratingScaleDescription || "",
          },
          Scope: {
            id: filteredUpdateData.assessment.moduleRatingScopeId || null,
            description:
              filteredUpdateData.assessment.ratingScopeDescription || "",
          },
          Remediability: {
            id:
              filteredUpdateData.assessment.moduleRatingRemediabilityId || null,
            description:
              filteredUpdateData.assessment.ratingRemediabilityDescription ||
              "",
          },
          Magnitude: {
            id: filteredUpdateData.assessment.moduleRatingMagnitudeId || null,
            description:
              filteredUpdateData.assessment
                .ratingRatingMagnitudeIdDescription || "",
          },
          Likelihood: {
            id: filteredUpdateData.assessment.moduleRatingLikelihoodId || null,
            description:
              filteredUpdateData.assessment.ratingLikelihoodDescription || "",
          },
        });
      }
    }, [filteredUpdateData, isEditMode]);

    useEffect(() => {
      const isActual = basicFormData.actual === true;
      const type = iroType;

      if (iroType === "Risk" || iroType === "Opportunity") {
        setBasicFormData((prev) => ({ ...prev, actual: false }));
      }

      setTabConditions({
        scaleDisabled: type === "Risk" || type === "Opportunity",
        scopeDisabled: type === "Risk" || type === "Opportunity",
        remediabilityDisabled:
          type !== "Negative impact",
        magnitudeDisabled: !(type === "Risk" || type === "Opportunity"),
        likelihoodDisabled: isActual,
      });
    }, [basicFormData.actual, iroType]);

    const validateFields = () => {
      let isValid = true;
      let firstErrorTab = null;
      const newDescriptionErrors = {};
      const newRatingErrors = {};

      if (basicFormData.actual === null) {
        setActualValidation("Actual/Potential is required");
        if (!firstErrorTab) firstErrorTab = "Basic";
        isValid = false;
      } else {
        setActualValidation("");
      }

      const tabs = [
        "Scale",
        "Scope",
        "Remediability",
        "Magnitude",
        "Likelihood",
      ];
      for (const tab of tabs) {
        if (tabConditions[`${tab.toLowerCase()}Disabled`]) continue;

        const description = tabFormData[tab].description || "";

        if (description.length >= MAX_CHAR_LIMIT) {
          newDescriptionErrors[
            tab
          ] = `Description should not exceed ${MAX_CHAR_LIMIT} characters`;
          if (!firstErrorTab) firstErrorTab = tab;
          isValid = false;
        } else {
          newDescriptionErrors[tab] = "";
        }

         // Validate rating dropdown selection
         if (!tabFormData[tab].id) {
          newRatingErrors[tab] = `${tab} rating is required`;
          if (!firstErrorTab) firstErrorTab = tab;
          isValid = false;
        } else {
          newRatingErrors[tab] = "";
        }
      }

      setDescriptionErrors(newDescriptionErrors);
      setRatingErrors(newRatingErrors);

      if (!isValid && firstErrorTab) {
        setSelectedTab(firstErrorTab);
      }

      return isValid;
    };

    const handleSubmit = () => {
      if (validateFields()) {
        const data = {
          moduleId,
          id: isEditMode ? filteredUpdateData.assessment.id : undefined,
          moduleIROId,
          isPositive: basicFormData.positive,
          isActual: basicFormData.actual,
          moduleRatingScopeId: tabFormData.Scope.id || null,
          ratingScopeDescription: tabFormData.Scope.description || "",
          moduleRatingScaleId: tabFormData.Scale.id || null,
          ratingScaleDescription: tabFormData.Scale.description || "",
          moduleRatingRemediabilityId: tabFormData.Remediability.id || null,
          ratingRemediabilityDescription:
            tabFormData.Remediability.description || "",
          moduleRatingMagnitudeId: tabFormData.Magnitude.id || null,
          ratingMagnitudeIdDescription: tabFormData.Magnitude.description || "",
          moduleRatingLikelihoodId: tabFormData.Likelihood.id || null,
          ratingLikelihoodDescription: tabFormData.Likelihood.description || "",
          valueChainIds: Array.isArray(basicFormData.valueChainId)
            ? [
                ...new Set(
                  basicFormData.valueChainId
                    .filter((id) => id !== null && !isNaN(id))
                    .map((id) => id)
                ),
              ]
            : basicFormData.valueChainId || [],
        };
        handleDoneClick(data);
        onClose();
      }
    };

    const handleBasicChange = (field, value) => {
      setBasicFormData((prev) => ({
        ...prev,
        [field]: value,
      }));
    };

    const handleTabChange = (tab, field, value) => {
      setTabFormData((prev) => {
        const updated = {
          ...prev,
          [tab]: {
            ...prev[tab],
            [field]: value,
          },
        };

        return updated;
      });
    };

    const handleValueChainChange = (selectedOptions) => {
      setBasicFormData((prev) => ({
        ...prev,
        valueChainId: selectedOptions
          ? selectedOptions.map((option) => option.value)
          : [],
      }));
    };

    const valueChainOptions = valueChainDropDown.map((item) => ({
      value: item.id,
      label: item.referenceList,
    }));

    const handleDeleteClick = () => {
      setIsDeleteModalOpen(true);
      setDeleteItemID(filteredUpdateData.assessment.id);
    };

    const handleDeleteCloseModal = () => {
      setIsDeleteModalOpen(false);
      onClose();
    };

    const getDefinitionByTypeAndId = (type, givenId) => {
      const result = infoRating.find((item) => item.id === givenId);
      if (result) {
        const positiveKey = `${type}DefinitionPositive`;
        const negativeKey = `${type}DefinitionNegative`;
        return {
          [positiveKey]: result[positiveKey] || "Not available",
          [negativeKey]: result[negativeKey] || "Not available",
        };
      } else {
        return "No matching ID found";
      }
    };

    const handleMouseEnter = (tab, id) => {
      const definitions = getDefinitionByTypeAndId(tab, id);
      setInfo(
        (definitions[`${tab}DefinitionNegative`] === "undefined"
          ? ""
          : definitions[`${tab}DefinitionNegative`]) +
          "  " +
          (definitions[`${tab}DefinitionPositive`] === "undefined"
            ? ""
            : definitions[`${tab}DefinitionPositive`])
      );
    };

    let actualValue = "";
    if (basicFormData.actual !== null) {
      actualValue = basicFormData.actual ? "true" : "false";
    }

    const renderTabContent = () => {
      switch (selectedTab) {
        case "Scale":
        case "Scope":
        case "Remediability":
        case "Magnitude":
        case "Likelihood":
          return (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "92.7%",
                  paddingLeft: "4em",
                  paddingBottom: "2%",
                }}
              >
                <MotifLabel
                  className="iro-label"
                  style={{ marginRight: "8px" }}
                >
                  {ratingLabel}
                </MotifLabel>

                <div style={{ position: "relative", flex: "1 1" }}>
                  <Select
                    key={selectedTab}
                    value={
                      selectedTab === "Likelihood"
                        ? likelihood.find(
                            (option) =>
                              option.id === tabFormData[selectedTab].id
                          )
                        : ratingDropDownArray.find(
                            (option) =>
                              option.id === tabFormData[selectedTab].id
                          )
                    }
                    className="iro-textField"
                    onChange={(selectedOption) =>
                      handleTabChange(selectedTab, "id", selectedOption.id)
                    }
                    options={
                      selectedTab === "Likelihood"
                        ? likelihood
                        : ratingDropDownArray
                    }
                    getOptionLabel={(option) =>
                      `${option.val} - ${option.label}`
                    }
                    getOptionValue={(option) => option.id}
                  />
                  <CustomTooltip
                    placement="left"
                    title={info}
                    className="info-tooltip"
                  >
                    <IconButton
                      className="info-icon"
                      style={{
                        position: "absolute",
                        right: "2em",
                        top: "50%",
                        transform: "translateY(-50%)",
                        zIndex: 1,
                      }}
                      onMouseEnter={() =>
                        handleMouseEnter(
                          selectedTab.toLocaleLowerCase(),
                          tabFormData[selectedTab].id
                        )
                      }
                    >
                      <InfoIcon />
                    </IconButton>
                  </CustomTooltip>
                </div>
              </div>
              {ratingErrors[selectedTab] && (
                
                <MotifErrorMessage className="RatingError">
                  {ratingErrors[selectedTab]}
                </MotifErrorMessage>
              )}
              
              <div className="iro-form-row">
                <MotifLabel className="iro-label">
                  {descriptionLabel}
                </MotifLabel>
                <TextField
                  placeholder=""
                  multiline
                  fullWidth
                  rows={4}
                  value={tabFormData[selectedTab].description}
                  onChange={(e) =>
                    handleTabChange(selectedTab, "description", e.target.value)
                  }
                />
              </div>
              {descriptionErrors[selectedTab] && (
                <MotifErrorMessage>
                  {descriptionErrors[selectedTab]}
                </MotifErrorMessage>
              )}
            </>
          );
        case "Basic":
          return (
            <>
              <div className="iro-form-row">
                <MotifLabel className="iro-label">{iroTypeLabel}</MotifLabel>
                <MotifFormField className="iro-textField">
                  <MotifInput hideClearButton value={iroType} disabled />
                </MotifFormField>
              </div>

              <div className="iro-form-row">
                <MotifLabel className="iro-label">{valueChainLabel}</MotifLabel>
                <Select
                  isMulti
                  value={valueChainOptions.filter((option) =>
                    basicFormData.valueChainId.includes(option.value)
                  )}
                  onChange={handleValueChainChange}
                  options={valueChainOptions}
                  className="iro-textField"
                />
              </div>

              <div className="iro-form-row">
                <MotifLabel className="iro-label">
                  {actualPotentialLabel}
                </MotifLabel>
                <MotifSelect
                  value={actualValue}
                  className="iro-textField"
                  onChange={(e) => handleBasicChange("actual", e === "true")}
                  disabled={iroType === "Risk" || iroType === "Opportunity"}
                >
                  <MotifOption value="true">Actual</MotifOption>
                  <MotifOption value="false">
                    {iroType === "Risk" || iroType === "Opportunity"
                      ? "------"
                      : "Potential"}
                  </MotifOption>
                </MotifSelect>
              </div>
              {actualValidation && (
                <MotifErrorMessage className="error-message-iro-modal">
                  {actualValidation}
                </MotifErrorMessage>
              )}
            </>
          );
        default:
          return null;
      }
    };

    return (
      <div className="iro-modal-overlay">
        <div className="iro-modal modal">
          <div className="iro-modal-header">
            <h2>{modalTitle}</h2>
          </div>
          <div className="iro-modal-body">
            <div className="iro-modal-sidebar">
              <ul>
                {[
                  "Basic",
                  "Scale",
                  "Scope",
                  "Remediability",
                  "Magnitude",
                  "Likelihood",
                ].map((tab) => (
                  <li key={tab}>
                    <button
                      type="button"
                      className={`sidebar-item ${
                        selectedTab === tab ? "active" : ""
                      }`}
                      onClick={() => setSelectedTab(tab)}
                      disabled={tabConditions[`${tab.toLowerCase()}Disabled`]}
                    >
                      {t(tab)}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
            <div className="modal-content">
              {renderTabContent()}
              <div className="iro-modal-footer">
                {isEditMode && (
                  <MotifButton
                    onClick={handleDeleteClick}
                    className="modal-delete-button"
                    style={{ marginRight: "10px" }}
                  >
                    {deleteButtonLabel} &nbsp;
                    <Cross />
                  </MotifButton>
                )}
                <MotifButton onClick={onClose} className="modal-cancel-button">
                  {cancelButtonLabel}
                </MotifButton>
                <MotifButton
                  onClick={handleSubmit}
                  className="modal-done-button"
                >
                  {isEditMode ? updateButtonLabel : saveButtonLabel}
                </MotifButton>
              </div>
            </div>
          </div>
        </div>

        {isDeleteModalOpen && (
          <DeleteModal
            isOpen={isDeleteModalOpen}
            onClose={handleDeleteCloseModal}
            setName={t("DeleteLabel")}
            labelText={t("DeletConfirmationSources")}
            onCancel={handleDeleteCloseModal}
            handleDeleteClick={handleDelete}
            cancelButtonLabel={t("Cancel")}
            deleteButtonLabel={t("DeleteLabel")}
          />
        )}
      </div>
    );
  }
);

IroAssessmentModal.propTypes = {
  iroType: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  modalMode: PropTypes.string,
  modalTitle: PropTypes.string,
  ratingDropDownArray: PropTypes.array,
  likelihood: PropTypes.array,
  saveButtonLabel: PropTypes.string,
  updateButtonLabel: PropTypes.string,
  cancelButtonLabel: PropTypes.string,
  valueChainDropDown: PropTypes.array,
  handleDoneClick: PropTypes.func.isRequired,
  deleteButtonLabel: PropTypes.string,
  ratingLabel: PropTypes.string,
  descriptionLabel: PropTypes.string,
  iroTypeLabel: PropTypes.string,
  valueChainLabel: PropTypes.string,
  actualPotentialLabel: PropTypes.string,
  filteredUpdateData: PropTypes.object,
  moduleId: PropTypes.string,
  moduleIROId: PropTypes.string,
  handleDelete: PropTypes.func,
  infoRating: PropTypes.array,
};

export default IroAssessmentModal;