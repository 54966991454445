import React from "react";
import PropTypes from "prop-types";
import { Grid, Card, Box, Typography, Button, IconButton } from "@mui/material";
import { Edit, DgaAR, DgaEdit } from "../../../constants/icons/Icons";
import CustomTooltip from "../../../constants/customTooltip/CustomTooltip";

const buttonStyles = {
  backgroundColor: "#ffffff",
  color: "#724BC3",
  textTransform: "none",
  cursor: "auto",
  border: "1px solid #5F5F72",
  boxShadow: "none",
  "&:hover": {
    backgroundColor: "#ffffff",
    color: "#724BC3",
    boxShadow: "none",
  },
  marginLeft: "10px",
};

const AssessmentCard = ({ item, onEdit, onARClick, isDisabled }) => {
  const {
    id,
    dpId,
    dpName,
    paragraph,
    fullTextDisclosure,
    conditionalOrAlternativeDP,
    dataType,
    voluntary,
    appendixBESRS2,
    phaseIn,
    appendixC,
    maturityLevelId,
  } = item;

  const renderButton = (label) => (
    <Button variant="contained" sx={buttonStyles} size="small">
      {label}
    </Button>
  );

  const getBorderColor = (maturityLevelId) => {
    switch (maturityLevelId) {
      case 0:
        return "#F6F6FA";
      case 1:
        return "#C9251C";
      case 2:
        return "#FF9831";
      case 3:
        return "#FF810A";
      case 4:
        return "#8CE8AD";
      case 5:
        return "#168736";
      default:
        return "#BFBFC8";
    }
  };

  return (
    <Grid item xs={12} key={id}>
      <Card
        variant="outlined"
        sx={{
          display: "flex",
          alignItems: "center",
          padding: 2,
          borderColor: getBorderColor(maturityLevelId),
          borderWidth: 2,
        }}
      >
        <Box flex={1}>
          <Typography
            variant="subtitle1"
            sx={{ color: " #2E2E38", marginBottom: 2, paddingLeft: 1 }}
          >
            <Box component="span" fontWeight="bold">
              {dpId}
            </Box>
            {" - "}
            <Box component="span" fontWeight="normal">
              {dpName}
            </Box>
            <Box component="span" fontWeight="bold">
              <CustomTooltip
                title={fullTextDisclosure || "N/A"}
                arrow
                placement="top"
              >
                &nbsp; &nbsp; <DgaEdit /> {paragraph}
              </CustomTooltip>
            </Box>
          </Typography>
          {conditionalOrAlternativeDP &&
            renderButton(conditionalOrAlternativeDP)}
          {dataType && renderButton(dataType)}
          {voluntary && renderButton(voluntary)}
          {appendixBESRS2 && renderButton(appendixBESRS2)}
          {phaseIn && renderButton(phaseIn)}
          {appendixC && renderButton(appendixC)}
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {!isDisabled && (
            <IconButton
              onClick={() => onARClick(item)}
              sx={{
                backgroundColor: "inherit",
                color: "inherit",
                cursor: "pointer",
              }}
            >
              <DgaAR />
            </IconButton>
          )}
          <IconButton onClick={() => onEdit(item)}>
            <Edit />
          </IconButton>
        </Box>
      </Card>
    </Grid>
  );
};

AssessmentCard.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    dpId: PropTypes.string,
    dpName: PropTypes.string.isRequired,
    paragraph: PropTypes.string.isRequired,
    fullTextDisclosure: PropTypes.string,
    conditionalOrAlternativeDP: PropTypes.string,
    dataType: PropTypes.string,
    voluntary: PropTypes.string,
    appendixBESRS2: PropTypes.string,
    phaseIn: PropTypes.string,
    appendixC: PropTypes.string,
    maturityLevelId: PropTypes.number.isRequired,
  }).isRequired,
  onEdit: PropTypes.func.isRequired,
  onARClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default AssessmentCard;
