export const addSheet = (workbook, sheetName, data) => {
  const groupHeadersByTypeAndName = (
    items,
    typeKey,
    nameKey,
    isGeography = false
  ) => {
    return items.reduce((acc, item) => {
      const type = isGeography ? "" : item[typeKey];
      const name = item[nameKey];
      const key = isGeography ? name : `${type} - ${name}`;
      if (!acc[type]) acc[type] = {};
      if (!acc[type][name]) acc[type][name] = key;
      return acc;
    }, {});
  };

  const sourcesGrouped = groupHeadersByTypeAndName(
    data.flatMap((item) => item.sources || []),
    "sourceType",
    "name"
  );

  const valueChainGrouped = groupHeadersByTypeAndName(
    data.flatMap((item) => item.valueChain || []),
    "valueChainType",
    "name"
  );

  const stakeholdersGrouped = groupHeadersByTypeAndName(
    data.flatMap((item) => item.stakeholders || []),
    "stakeHolderType",
    "name"
  );

  const geographiesGrouped = groupHeadersByTypeAndName(
    data.flatMap((item) => item.geographies || []),
    "name",
    "name",
    true
  );

  const actProServicesGrouped = groupHeadersByTypeAndName(
    data.flatMap((item) => item.actProServices || []),
    "actProServiceType",
    "name"
  );

  const flattenGroupedHeaders = (groupedHeaders) => {
    return Object.values(groupedHeaders).flatMap((group) =>
      Object.values(group)
    );
  };

  const sourcesHeaders = flattenGroupedHeaders(sourcesGrouped);
  const valueChainHeaders = flattenGroupedHeaders(valueChainGrouped);
  const stakeholderHeaders = flattenGroupedHeaders(stakeholdersGrouped);
  const geographyHeaders = flattenGroupedHeaders(geographiesGrouped);
  const actProServiceHeaders = flattenGroupedHeaders(actProServicesGrouped);

  const dynamicHeaders = [
    ...sourcesHeaders,
    ...valueChainHeaders,
    ...stakeholderHeaders,
    ...geographyHeaders,
    ...actProServiceHeaders,
  ];

  const staticHeaders = [
    "Section",
    "ESRS",
    "Topic",
    "SubTopic",
    "SubSubTopic",
    "Explanation",
  ];
  const headers = [...staticHeaders, ...dynamicHeaders];
  const worksheetData = [headers];
  const worksheet = workbook.addWorksheet(sheetName);
  worksheet.addRows(worksheetData);
  const extraHeaderRow = worksheet.insertRow(1, []);

  extraHeaderRow.getCell(1).alignment = {
    vertical: "middle",
    horizontal: "center",
  };
  extraHeaderRow.getCell(1).font = {
    bold: true,
    color: { argb: "FFFFFFFF" },
  };
  extraHeaderRow.getCell(1).fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "FF000000" },
  };

  let currentCol = staticHeaders.length + 1;

  const mergeAndStyleHeader = (startCol, endCol, headerText) => {
    worksheet.mergeCells(1, startCol, 1, endCol);
    const cell = worksheet.getCell(1, startCol);
    cell.value = headerText;
    cell.alignment = { vertical: "middle", horizontal: "center" };
    cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF000000" },
    };
  };

  mergeAndStyleHeader(1, staticHeaders.length, " ");

  const sectionEndColumns = {};

  if (sourcesHeaders.length > 0) {
    const endCol = currentCol + sourcesHeaders.length - 1;
    mergeAndStyleHeader(currentCol, endCol, "Sources");
    sectionEndColumns.sources = endCol;
    currentCol += sourcesHeaders.length;
  }

  if (valueChainHeaders.length > 0) {
    const endCol = currentCol + valueChainHeaders.length - 1;
    mergeAndStyleHeader(currentCol, endCol, "Value Chain");
    sectionEndColumns.valueChain = endCol;
    currentCol += valueChainHeaders.length;
  }

  if (stakeholderHeaders.length > 0) {
    const endCol = currentCol + stakeholderHeaders.length - 1;
    mergeAndStyleHeader(currentCol, endCol, "Stakeholders");
    sectionEndColumns.stakeholders = endCol;
    currentCol += stakeholderHeaders.length;
  }

  if (geographyHeaders.length > 0) {
    const endCol = currentCol + geographyHeaders.length - 1;
    mergeAndStyleHeader(currentCol, endCol, "Geographies");
    sectionEndColumns.geographies = endCol;
    currentCol += geographyHeaders.length;
  }

  if (actProServiceHeaders.length > 0) {
    const endCol = currentCol + actProServiceHeaders.length - 1;
    mergeAndStyleHeader(currentCol, endCol, "ActProServices");
    sectionEndColumns.actProServices = endCol;
    currentCol += actProServiceHeaders.length;
  }

  const headerRow = worksheet.getRow(2);
  headerRow.eachCell((cell, colNumber) => {
    cell.font = { bold: true, size: 12 };
    cell.alignment = {
      vertical: "middle",
      horizontal: "center",
      textRotation: colNumber > staticHeaders.length ? 90 : 0,
    };
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFD3D3D3" },
    };
    cell.border = {
      left: { style: "thin", color: { argb: "FF000000" } },
      bottom: { style: "thin", color: { argb: "FF000000" } },
    };
  });

  const groupedData = data.reduce((acc, item) => {
    const groupKey = `${item.sources
      ?.map((s) => s.sourceType)
      .join(",")} | ${item.valueChain
      ?.map((vc) => vc.valueChainType)
      .join(",")}`;
    if (!acc[groupKey]) acc[groupKey] = [];
    acc[groupKey].push(item);
    return acc;
  }, {});

  Object.keys(groupedData).forEach((key) => {
    const group = groupedData[key];
    group.forEach((item) => {
      const row = [
        item.section || "N/A",
        item.esrs || "N/A",
        item.topic || "N/A",
        item.subTopic || "N/A",
        item.subSubTopic || "N/A",
        item.definition || "N/A",
      ];
      const getHeaderValue = (header, item, headers, key) => {
        if (headers.includes(header)) {
          const foundItem = item[key]?.find(
            (i) => `${i[key + "Type"]} - ${i.name}` === header
          );
          if (foundItem) {
            return foundItem.isActive ? "X" : "";
          }
          return "";
        }
        return "";
      };

      const getGeographyHeaderValue = (header, item) => {
        if (geographyHeaders.includes(header)) {
          const geography = item.geographies?.find(
            (geo) => geo.name === header
          );
          if (geography) {
            return geography.isActive ? "X" : "";
          }
          return "";
        }
        return "";
      };

      dynamicHeaders.forEach((header) => {
        let value = getHeaderValue(header, item, sourcesHeaders, "sources");
        if (!value)
          value = getHeaderValue(header, item, valueChainHeaders, "valueChain");
        if (!value)
          value = getHeaderValue(
            header,
            item,
            stakeholderHeaders,
            "stakeholders"
          );
        if (!value) value = getGeographyHeaderValue(header, item);
        if (!value)
          value = getHeaderValue(
            header,
            item,
            actProServiceHeaders,
            "actProServices"
          );
        row.push(value);
      });

      worksheetData.push(row);
    });
  });

  worksheet.addRows(worksheetData.slice(1));

  worksheetData.forEach((row, rowIndex) => {
    const dataRow = worksheet.getRow(rowIndex + 2);
    dataRow.eachCell((cell, colNumber) => {
      cell.border = {
        top: { style: "thin", color: { argb: "FF000000" } },
        left: { style: "thin", color: { argb: "FF000000" } },
        bottom: { style: "thin", color: { argb: "FF000000" } },
        right: { style: "thin", color: { argb: "FF000000" } },
      };

      if (
        sectionEndColumns.sources === colNumber ||
        sectionEndColumns.valueChain === colNumber ||
        sectionEndColumns.stakeholders === colNumber ||
        sectionEndColumns.geographies === colNumber ||
        sectionEndColumns.actProServices === colNumber
      ) {
        cell.border.right = { style: "medium", color: { argb: "FF000000" } };
      }

      if (cell.value === "X") {
        cell.alignment = {
          vertical: "middle",
          horizontal: "center",
        };
      }
    });
  });

  worksheet.eachRow((row, rowIndex) => {
    if (rowIndex > 1) {
      const sectionCell = row.getCell(1);
      sectionCell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
    }
  });

  worksheet.columns = headers.map((header, colNumber) => {
    const maxLength = Math.max(
      header.length,
      ...worksheetData.map((row) =>
        row[colNumber] ? row[colNumber].toString().length : 0
      )
    );
    return { width: Math.min(Math.max(maxLength + 2, 10), 50) };
  });

  worksheet.views = [{ state: "frozen", ySplit: 2 }];
};
