import ExcelJS from "exceljs";

export const handleDownloadIroReport = async (iroReportingData) => {
  if (!iroReportingData) return;

  try {
    const apiData = Array.isArray(iroReportingData)
      ? iroReportingData
      : [iroReportingData];

    const workbook = new ExcelJS.Workbook();
    const sheetNames = new Set();

    if (apiData.length === 0) {
      workbook.addWorksheet("Blank Sheet");
    } else {
      for (const [index, item] of apiData.entries()) {
        let baseSheetName = `${index + 1}.${
          item.iroDetails?.name || "IRO"
        }`.replace(/[\\/*?[\]:]/g, "");
        if (baseSheetName.length > 31) {
          baseSheetName = baseSheetName.substring(0, 31);
        }
        baseSheetName = baseSheetName.replace(/^'|'$/g, "");

        let sheetName = baseSheetName;
        let counter = 1;
        while (sheetNames.has(sheetName)) {
          const suffix = `_${counter}`;
          const maxLength = 31 - suffix.length;
          sheetName = `${baseSheetName.substring(0, maxLength)}${suffix}`;
          counter++;
        }
        sheetNames.add(sheetName);

        const iroSheet = workbook.addWorksheet(sheetName);

        const iroHeader = [
          ["IRO", item.iroDetails?.name || "N/A"],
          ["Score", item.maxScore || "N/A"],
          ["Material", item.isAssessmentMaterial ? "Yes" : "No"],
          [
            "Pillar/Area",
            `${item.iroDetails?.pillar || "N/A"}/${
              item.iroDetails?.area || "N/A"
            }`,
          ],
          ["Type", item.iroDetails?.iroTypeName || "N/A"],
          ["Timeframe", item.iroDetails?.timeframeType || "N/A"],
          ["Description", item.iroDetails?.description || "N/A"],
        ];

        iroHeader.forEach((row) => {
          const headerRow = iroSheet.addRow(row);
          headerRow.eachCell((cell) => {
            cell.font = { bold: true };
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "ADD8E6" },
            };
            cell.alignment = {
              vertical: "middle",
              horizontal: "left",
              wrapText: true,
            };
            cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
          });
        });

        iroSheet.addRow([]);

        const contextTitleRow = iroSheet.addRow(["Context"]);
        contextTitleRow.eachCell((cell) => {
          cell.font = { bold: true, size: 12 };
          cell.alignment = { vertical: "middle", horizontal: "center" };
        });

        const contextHeader = [
          "Sustainability",
          "Valuechain",
          "Sources",
          "Aps",
          "Stakeholders",
          "Geography",
          "RACI Mapping",
        ];
        const colors = [
          "FF7F7F91",
          "FF4E4E5D",
          "FF26A04B",
          "FF229B9A",
          "FF188CE5",
          "FF9D7CD4",
          "FFFF4136",
        ];

        const contextHeaderRow = iroSheet.addRow(contextHeader);
        contextHeaderRow.eachCell((cell, colNumber) => {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: colors[colNumber - 1] },
          };
          cell.font = { bold: true, color: { argb: "FFFFFF" } };
          cell.alignment = {
            vertical: "middle",
            horizontal: "center",
            wrapText: true,
          };
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        });

        const formatText = (text, maxLength) => {
          const words = text.split(", ");
          let formattedText = "";
          let lineLength = 0;

          words.forEach((word, index) => {
            if (lineLength + word.length > maxLength) {
              formattedText += "\n";
              lineLength = 0;
            }
            formattedText += word;
            if (index < words.length - 1) {
              formattedText += ", ";
              lineLength += word.length + 2;
            }
          });

          return formattedText;
        };

        const contextDataRow = iroSheet.addRow([
          formatText(
            item.understandingTheContextList?.sustainabilityMatters.join(
              ", "
            ) || "N/A",
            10
          ),
          formatText(
            item.understandingTheContextList?.valueChains.join(", ") || "N/A",
            10
          ),
          formatText(
            item.understandingTheContextList?.sources.join(", ") || "N/A",
            10
          ),
          formatText(
            item.understandingTheContextList?.actProServices.join(", ") ||
              "N/A",
            10
          ),
          formatText(
            item.understandingTheContextList?.stakeHolders.join(", ") || "N/A",
            10
          ),
          formatText(
            item.understandingTheContextList?.geographicalLocations.join(
              ", "
            ) || "N/A",
            10
          ),
          formatText(
            ` ${item.iroDetails?.raci?.responsible || "N/A"}, ${
              item.iroDetails?.raci?.accountable || "N/A"
            },  ${item.iroDetails?.raci?.informed || "N/A"}`,
            50
          ),
        ]);

        contextDataRow.eachCell((cell) => {
          cell.alignment = {
            vertical: "middle",
            horizontal: "center",
            wrapText: true,
          };
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        });

        contextDataRow.height = Math.max(
          ...contextDataRow.values.map((value) => {
            const lines = value?.toString().split("\n").length || 1;
            return lines * 20;
          })
        );
        iroSheet.columns.forEach((column, colIndex) => {
          let maxLength = 0;
          column.eachCell({ includeEmpty: true }, (cell) => {
            const columnLength = cell.value ? cell.value.toString().length : 10;
            if (columnLength > maxLength) {
              maxLength = columnLength;
            }
          });

          if (colIndex === 0) {
            column.width = 30;
          } else {
            column.width = maxLength + 2;
          }
        });

        contextDataRow.eachCell((cell) => {
          cell.alignment = {
            vertical: "middle",
            horizontal: "center",
            wrapText: true,
          };
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        });

        contextDataRow.height = Math.max(
          ...contextDataRow.values.map((value) => {
            const lines = value?.toString().split("\n").length || 1;
            return lines * 20;
          })
        );

        iroSheet.addRow([]);

        const assessmentsTitleRow = iroSheet.addRow(["Assessments"]);
        assessmentsTitleRow.eachCell((cell) => {
          cell.font = { bold: true, size: 12 };
          cell.alignment = { vertical: "middle", horizontal: "center" };
        });

        const assessmentsHeader = [
          "Material",
          "Score",
          "Value Chain",
          "Positive/Negative",
          "Actual/Potential",
          "Scale",
          "Scale Description",
          "Scope",
          "Scope Description",
          "Remediability",
          "Remediability Description",
          "Magnitude",
          "Magnitude Description",
          "Likelihood",
          "Likelihood Description",
        ];

        const assessmentsHeaderRow = iroSheet.addRow(assessmentsHeader);
        assessmentsHeaderRow.eachCell((cell) => {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "0070C0" },
          };
          cell.font = { bold: true, color: { argb: "FFFFFF" } };
          cell.alignment = {
            vertical: "middle",
            horizontal: "center",
            wrapText: true,
          };
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        });

        item.assessments.forEach((assessment) => {
          const assessmentRow = iroSheet.addRow([
            assessment?.isMaterial ? "Yes" : "No",
            assessment?.score || "N/A",
            assessment?.valueChains?.join(", ") || "N/A",
            assessment?.isPositive ? "Positive" : "Negative",
            assessment?.isActual ? "Actual" : "Potential",
            assessment?.scaleLabel || "N/A",
            assessment?.ratingScaleDescription || "N/A",
            assessment?.scopeLabel || "N/A",
            assessment?.ratingScopeDescription || "N/A",
            assessment?.remediabilityLabel || "N/A",
            assessment?.ratingRemediabilityDescription || "N/A",
            assessment?.magnitudeLabel || "N/A",
            assessment?.ratingRatingMagnitudeIdDescription || "N/A",
            assessment?.likelihood || "N/A",
            assessment?.ratingLikelihoodDescription || "N/A",
          ]);
          assessmentRow.eachCell((cell) => {
            cell.alignment = {
              vertical: "middle",
              horizontal: "left",
              wrapText: true,
            };
            cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
          });

          assessmentRow.height = Math.max(
            ...assessmentRow.values.map((value) => {
              const lines = value?.toString().split("\n").length || 5;
              return lines * 15;
            })
          );
        });
        iroSheet.columns.forEach((column, colIndex) => {
          let maxLength = 0;
          column.eachCell({ includeEmpty: true }, (cell) => {
            const columnLength = cell.value ? cell.value.toString().length : 10;
            if (columnLength > maxLength) {
              maxLength = columnLength;
            }
          });

          if (colIndex === 0) {
            column.width = 30;
          } else {
            column.width = maxLength + 2;
          }
        });
      }
    }

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "IRO_Report.xlsx";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error generating Excel file:", error);
  }
};
