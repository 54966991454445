import React, { useEffect, useState, useCallback } from "react";
import GaugeChart from "../../../components/charts/GaugeChart";
import { useDispatch, useSelector } from "react-redux";
import {
  MotifButton,
  MotifProgressLoader,
  MotifSelect,
  MotifOption,
} from "@ey-xd/motif-react";
import ErrorModal from "../../../components/modals/error/errorModal";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  getChart1InScope,
  getChart2Assessed,
  getChart3Maturity,
  clearError,
} from "../../../features/slices/DGAContext";
import { handleError as handleLogoutError } from "../../../utils/handleError";
import "./Dashboard.scss";

const getCookie = (name) => {
  const regex = new RegExp(`(^| )${name}=([^;]+)`);
  const match = regex.exec(document.cookie);
  return match ? match[2] : null;
};

const Dashboard = ({
  onShortListClick,
  onLonglistClick,
  onIroReportClick,
  navigateToReporting,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { moduleId: moduleIdParam } = useParams();
  const moduleId = parseInt(moduleIdParam, 10);
  const [isLoading, setIsLoading] = useState(true);
  const token =
    useSelector((state) => state.user.jwtToken) ||
    getCookie("authToken") ||
    sessionStorage.getItem("authToken");
  const [gaugeData, setGaugeData] = useState([]);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [localErrorMessage, setLocalErrorMessage] = useState("");
  const { isError, isGenericError, errorMessage } = useSelector(
    (state) => state.dgaSustainabilityMatters
  );

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const [chart1Response, chart2Response, chart3Response] =
        await Promise.all([
          dispatch(getChart1InScope({ moduleId, token })),
          dispatch(getChart2Assessed({ moduleId, token })),
          dispatch(getChart3Maturity({ moduleId, token })),
        ]);
      const chart1 = chart1Response.payload.data || [];
      const chart2 = chart2Response.payload.data || [];
      const chart3 = chart3Response.payload.data || [];
      setGaugeData([
        {
          id: "dashboard-gauge-1",
          value: chart1.value,
          label: chart1.name,
          min: chart1.minValue,
          max: chart1.maxValue,
        },
        {
          id: "dashboard-gauge-2",
          value: chart2.value,
          label: chart2.name,
          min: chart2.minValue,
          max: chart2.maxValue,
        },
        {
          id: "dashboard-gauge-3",
          value: chart3.value,
          label: chart3.name,
          min: chart3.minValue,
          max: chart3.maxValue,
        },
      ]);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, moduleId, token]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const renderGaugeCharts = () =>
    gaugeData.map((gauge) => (
      <GaugeChart
        key={gauge.label}
        id={gauge.id}
        value={gauge.value}
        label={gauge.label}
        min={gauge.min}
        max={gauge.max}
      />
    ));

  const renderButtons = () => {
    const buttons = [
      { label: "Longlist", onClick: onLonglistClick },
      { label: "Shortlist", onClick: onShortListClick },
      { label: "IroReport", onClick: onIroReportClick },
      { label: "Material report", onClick: navigateToReporting },
    ];

    return buttons.map((button) => (
      <MotifButton
        key={button.label}
        className="dga-overview-row-reporting"
        aria-label={`Navigate to ${button.label}`}
        onClick={button.onClick}
      >
        {button.label}
      </MotifButton>
    ));
  };

  useEffect(() => {
    if (isError && errorMessage) {
      setLocalErrorMessage(errorMessage);
      setShowErrorModal(true);
    }
  }, [isError, errorMessage]);

  const handleCancelErrorModal = () => {
    setShowErrorModal(false);
    dispatch(clearError());
  };

  if (showErrorModal && localErrorMessage) {
    return (
      <ErrorModal
        setName={t("Error")}
        labelText={localErrorMessage}
        handleButtonClick={
          isGenericError
            ? () => handleLogoutError(dispatch)
            : handleCancelErrorModal
        }
        deleteButtonLabel={isGenericError ? t("Reload") : t("Cancel")}
      />
    );
  }

  return (
    <>
      {isLoading && (
        <MotifProgressLoader
          data-testid="loading-spinner"
          className="loader"
          show
          variant="default"
        />
      )}
      <div className="dga-overview-card">
        <div className="dga-understanding-div">
          <label className="dga-overview-header" htmlFor="dashboard-gauge">
            Datapoints
            <MotifSelect
              className="dga-dashboard-select"
              labelPosition="in"
              ariaLabelledBy="select-user-update-role-label"
              aria-label="Select a datapoint filter"
              visibleOptions={2}
              data-testid="userframework"
              value={"ALL"}
            >
              <MotifOption key="All" value="ALL">
                ALL
              </MotifOption>
            </MotifSelect>
          </label>

          <div className="dga-overview-gauge-content-div">
            {renderGaugeCharts()}
          </div>
        </div>
        <div className="dga-stakeholder-div">
          <label className="dga-overview-header" htmlFor="london-control">
            Report
          </label>
          <div id="london-control" className="dga-overview-content-div">
            {renderButtons()}
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
