import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ExcelJS from "exceljs";
import {
  createNewSustainabilityMatters,
  deleteSustainabilityMatters,
  fetchEsrsAr16,
  getAllSustainabilityMatters,
  updateSustainabilityMatters,
  clearError,
} from "../../../../features/slices/SustainabilityMatters";
import { fetchExcelData } from "../../../../features/slices/LongListData";
import { MotifProgressLoader } from "@ey-xd/motif-react";
import ContextSelection from "../../../../components/contextSelection/ContextSelection";
import TreeView from "../../../../components/treeView/TreeView";
import SustainabilityMattersModal from "../../../../components/modals/dmaModals/SustainabilityMattersModal";
import DeleteModal from "../../../../components/modals/deleteModal/DeleteModal";
import ErrorModal from "../../../../components/modals/error/errorModal";
import "./SustainabillityMatters.scss";
import { useTranslation } from "react-i18next";
import { handleError as handleLogoutError } from "../../../../utils/handleError";
import { addSheet } from "../../../../utils/excelUtils";

const getCookie = (name) => {
  const regex = new RegExp(`(^| )${name}=([^;]+)`);
  const match = regex.exec(document.cookie);
  return match ? match[2] : null;
};

const formatExcelData = (data) => {
  const workbook = new ExcelJS.Workbook();
  addSheet(workbook, "Sustainability Matters", data);
  return workbook;
};

const downloadExcel = async (data) => {
  const workbook = formatExcelData(data);
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = "sustainability_matters.xlsx";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};

// 1) Build a tree from flat data
const buildTreeFromFlatData = (flatData) => {
  const idToNodeMap = {};
  const rootNodes = [];

  // Build an id->node map
  flatData.forEach((item) => {
    idToNodeMap[item.id] = {
      ...item,
      label: item.label || item.name,
      itemId: item.id,
      allowEdit: item.allowEdit !== undefined ? item.allowEdit : true,
      children: [],
    };
  });

  // Link children
  flatData.forEach((item) => {
    const node = idToNodeMap[item.id];
    if (item.parentId != null && idToNodeMap[item.parentId]) {
      idToNodeMap[item.parentId].children.push(node);
    } else {
      // root node
      rootNodes.push(node);
    }
  });

  return rootNodes;
};

const SustainabilityMatters = () => {
  const [modal, setModal] = useState({ isOpen: false, title: "", mode: "add" });
  const [options, setOptions] = useState({ parent: [], esrsar16: [] });
  const [isExcelLoading, setIsExcelLoading] = useState(false);
  const [filteredUpdateData, setFilteredUpdateData] = useState();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [updateItemID, setUpdateItemID] = useState(null);
  const [deleteItemID, setDeleteItemID] = useState(null);
  const [updateTrigger, setUpdateTrigger] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { moduleId } = useParams();
  const dispatch = useDispatch();
  const token =
    useSelector((state) => state.user.jwtToken) ||
    getCookie("authToken") ||
    sessionStorage.getItem("authToken");
  const { t } = useTranslation();
  const [searchInput, setSearchInput] = useState("");
  const [smData, setSmData] = useState([]);
  const { isError, errorMessage, isGenericError } = useSelector(
    (state) => state.dmaSustainabilityMatters
  );
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [localErrorMessage, setLocalErrorMessage] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  // Create a list of possible parents from the tree
  const getEligibleParentOptions = useCallback((tree) => {
    const result = [];
    const traverse = (nodes) => {
      for (const node of nodes) {
        result.push({ value: node.id, label: node.label || node.name });
        if (node.children && node.children.length > 0) {
          traverse(node.children);
        }
      }
    };
    traverse(tree);
    return result;
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        // 2) Fetch the FLAT data from the API
        const [smResponse, esrsar16Response] = await Promise.all([
          dispatch(getAllSustainabilityMatters({ moduleId, token })),
          dispatch(fetchEsrsAr16({ token })),
        ]);
        const flatData = smResponse.payload.data || [];

        // 3) Convert flat data to hierarchical tree data
        const treeData = buildTreeFromFlatData(flatData);
        setSmData(treeData);

        // Create dropdown options from the hierarchical tree
        setOptions({
          esrsar16:
            esrsar16Response.payload.data.map((type) => ({
              value: type.id,
              label: type.name,
            })) || [],
          parent: getEligibleParentOptions(treeData),
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [dispatch, moduleId, token, updateTrigger, getEligibleParentOptions]);

  useEffect(() => {
    if (isError && errorMessage) {
      setLocalErrorMessage(errorMessage);
      setShowErrorModal(true);
    }
  }, [isError, errorMessage]);

  // For opening the Add/Edit modal
  const handleOpenModal = (mode, itemId) => {
    setModal({
      isOpen: true,
      mode,
      title:
        mode === "add"
          ? t("newSustainabilityMatters")
          : t("editSustainabilityMatters"),
    });
  };

  const handleCloseModal = () => {
    setModal((prev) => ({ ...prev, isOpen: false }));
    setUpdateItemID(null);
  };

  // Recursively find an item by ID in the hierarchical tree
  const findItemById = (data, id) => {
    for (let item of data) {
      if (item.id === id) return item;
      if (item.children) {
        const result = findItemById(item.children, id);
        if (result) return result;
      }
    }
    return null;
  };

  const handleEditClick = (id) => {
    handleOpenModal("edit", id);
    setFilteredUpdateData(findItemById(smData, id));
    setUpdateItemID(id);
    setDeleteItemID(id);
  };

  const handleDeleteClick = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteCloseModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDelete = async () => {
    if (isDeleting) return;
    setIsDeleting(true);
    setIsLoading(true);
    try {
      await dispatch(
        deleteSustainabilityMatters({
          sustainabilityMattersId: deleteItemID,
          moduleId,
          token,
        })
      ).unwrap();
      handleCloseModal();
      handleDeleteCloseModal();
      setUpdateTrigger((prev) => prev + 1);
    } catch (error) {
      console.error("Deletion failed:", error);
    } finally {
      setIsDeleting(false);
      setIsLoading(false);
    }
  };

  // Recursively find the parent item by ID
  const findParentById = (data, parentId) => {
    for (let item of data) {
      if (item.id === parentId) return item;
      if (item.children && item.children.length > 0) {
        const found = findParentById(item.children, parentId);
        if (found) return found;
      }
    }
    return null;
  };

  // API call to create a new sustainability matter
  const createNewSustainabilityMattersApi = async (data) => {
    if (data) {
      setIsLoading(true);
      try {
        let newSortOrder;
        // If no parentId, compute sort order for root
        if (data.parentId === null) {
          // Gather all nodes
          const allNodes = [];
          const gatherNodes = (nodes) => {
            for (const n of nodes) {
              allNodes.push(n);
              if (n.children) gatherNodes(n.children);
            }
          };
          gatherNodes(smData);

          const maxSortOrder = allNodes.reduce(
            (max, item) => (item.sortOrder > max ? item.sortOrder : max),
            0
          );
          newSortOrder = maxSortOrder + 1;
        } else {
          // If it has a parent, match parent's sort order
          const parentItem = findParentById(smData, data.parentId);
          newSortOrder = parentItem ? parentItem.sortOrder : 0;
        }

        const newData = { ...data, sortOrder: newSortOrder };
        await dispatch(
          createNewSustainabilityMatters({
            moduleId,
            sustainabilityMattersData: newData,
            token,
          })
        ).unwrap();
        setUpdateTrigger((prev) => prev + 1);
        handleCloseModal();
      } catch (error) {
        console.error("Creation failed:", error);
      } finally {
        setIsLoading(false);
      }
    } else {
      alert("Please fill in all required fields");
    }
  };

  // API call to update existing sustainability matter
  const updateSustainabilityMattersApi = async (data) => {
    if (data) {
      setIsLoading(true);
      try {
        const existingItem = findItemById(smData, updateItemID);
        const updatedData = { ...data, sortOrder: existingItem.sortOrder };
        const response = await dispatch(
          updateSustainabilityMatters({
            sustainabilityMattersId: updateItemID,
            sustainabilityMattersData: updatedData,
            moduleId,
            token,
          })
        ).unwrap();
        if (response.success) {
          handleCloseModal();
          setUpdateTrigger((prev) => prev + 1);
        }
      } catch (error) {
        console.error("Update failed:", error);
      }
      setIsLoading(false);
    } else {
      alert("Please fill in all required fields");
    }
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event);
  };

  // Filter the hierarchical tree by search text
  const filterTree = (items, query) => {
    if (!query) return items;
    return items
      .map((item) => {
        const labelMatch = item.label
          ?.toLowerCase()
          .includes(query.toLowerCase());
        const descMatch = item.description
          ?.toLowerCase()
          .includes(query.toLowerCase());

        if (labelMatch || descMatch) return item;

        if (item.children?.length) {
          const filteredChildren = filterTree(item.children, query);
          if (filteredChildren.length) {
            return { ...item, children: filteredChildren };
          }
        }
        return null;
      })
      .filter(Boolean);
  };

  const filteredItems = filterTree(smData, searchInput);

  const handleDownloadExcel = async () => {
    setIsExcelLoading(true);
    try {
      const excelResponse = await dispatch(
        fetchExcelData({ moduleId, token })
      ).unwrap();
      const exceResponseData = excelResponse?.data || [];
      await downloadExcel(exceResponseData);
    } catch (error) {
      console.error("Error fetching Excel data:", error);
    } finally {
      setIsExcelLoading(false);
    }
  };

  const handleCancelErrorModal = () => {
    setShowErrorModal(false);
    dispatch(clearError());
  };

  if (showErrorModal && localErrorMessage) {
    return (
      <ErrorModal
        setName={t("Error")}
        labelText={localErrorMessage}
        handleButtonClick={
          isGenericError
            ? () => handleLogoutError(dispatch)
            : handleCancelErrorModal
        }
        deleteButtonLabel={isGenericError ? t("Reload") : t("Cancel")}
      />
    );
  }

  return (
    <>
      {isLoading || isExcelLoading ? (
        <MotifProgressLoader
          data-testid="loading-spinner"
          className="loader"
          show
          variant="default"
        />
      ) : (
        <>
          <ContextSelection
            addButtonText="Add new"
            pageName="SM"
            downloadButtonText="Download longlist"
            onClickNewProject={() => handleOpenModal("add")}
            onClickNewDownload={handleDownloadExcel}
            onChangeSearchInput={handleSearchInputChange}
          />
          <div className="sm-file-explorer">
            <TreeView
              items={filteredItems}
              pageName="SM"
              defaultExpandedItems={[]}
              defaultSelectedItems="1"
              onEditClick={handleEditClick}
            />
          </div>
        </>
      )}

      {modal.isOpen && (
        <SustainabilityMattersModal
          onClose={handleCloseModal}
          modalTitle={modal.title}
          modalMode={modal.mode}
          topicNameLabel={t("TopicName")}
          parentLabel={t("Parent")}
          esrsAr16Label={t("ESRS_AR16")}
          esgLabel={t("ESG")}
          enterTopicNameLabel={t("EnterTopicName")}
          selectParentLabel={t("SelectParent")}
          isHumanRightsRelatedLabel={t("isHumanRightsRelated")}
          selectEsrsAr16Label={t("SelectESRS")}
          ExplanationLabel={t("Explanation (3000 characters)")}
          DescriptionLabel={t("Description (3000 characters)")}
          saveButtonLabel={t("addSustainabilityMatters")}
          updateButtonLabel={t("Update")}
          cancelButtonLabel={t("Cancel")}
          esrsar16Options={options.esrsar16}
          parentOptions={options.parent}
          isDisabled={isLoading}
          handleDoneClick={
            modal.mode === "add"
              ? createNewSustainabilityMattersApi
              : updateSustainabilityMattersApi
          }
          filteredUpdateData={filteredUpdateData}
          onDeleteClick={handleDeleteClick}
          DeleteLabel={t("DeleteLabel")}
          updateItemID={updateItemID}
        />
      )}

      {isDeleteModalOpen && (
        <DeleteModal
          isOpen={isDeleteModalOpen}
          onClose={handleDeleteCloseModal}
          setName={t("DeleteLabel")}
          labelText={t("DeletConfirmationSources")}
          onCancel={handleDeleteCloseModal}
          handleDeleteClick={handleDelete}
          cancelButtonLabel={t("Cancel")}
          deleteButtonLabel={t("DeleteLabel")}
          isDeleteDisabled={isDeleting}
        />
      )}
    </>
  );
};

export default SustainabilityMatters;