import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  MotifLabel,
  MotifButton,
  MotifInput,
  MotifFormField,
  MotifSelect,
  MotifOption,
  MotifMessage,
  MotifCheckbox,
  MotifErrorMessage,
  MotifToggleSwitch,
} from "@ey-xd/motif-react";
import PropTypes from "prop-types";
import { TableCell, TextField } from "@mui/material";
import ClientTable from "../../clientTable/ClientTable";
import TreeView from "../../treeView/TreeView";
import SearchIcon from "@mui/icons-material/Search";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import CreatableSelect from "react-select/creatable";
import CustomTooltip from "../../../constants/customTooltip/CustomTooltip";
import "./IroModal.scss";

const IroModal = ({
  onClose,
  modalMode,
  modalTitle,
  nameLabel,
  typeLabel,
  areaLabel,
  pillarLabel,
  selectAreaLabel,
  SelectPillar,
  BasicLabel,
  DescriptionLabel,
  TabDescriptionLabel,
  SourceLabel,
  ValueChainLabel,
  APSLabel,
  SustainablilityMattersLabel,
  StakeholdersLabel,
  GeographiesLabel,
  saveButtonLabel,
  updateButtonLabel,
  cancelButtonLabel,
  filterByLabel,
  pillarOptions,
  areaOptions,
  handleDoneClick,
  filteredUpdateData,
  fetchTabData,
  tabData,
  setTabData,
  updateTabData,
  disable,
  InputName,
  SelectTimeframe,
  TimeframeLabel,
  timeframeOptions,
  iroTypesOptions,
  onDeleteClick,
  DeleteLabel,
  SelectType,
  isHumanRightsRelatedLabel,
}) => {
  const isEditMode = modalMode === "edit";
  const isAddMode = modalMode === "add";
  const [selectedTab, setSelectedTab] = useState("Basic");
  const [name, setName] = useState("");
  const [pillar, setPillar] = useState("");
  const [timeframe, setTimeframe] = useState("");
  const [description, setDescription] = useState("");
  const [isHumanRightsRelated, setIsHumanRightsRelated] = useState(false);
  const [area, setArea] = useState("");
  const [type, setType] = useState(0);
  const [checkboxState, setCheckboxState] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [filterValue, setFilterValue] = useState("None");
  const [toggledItems, setToggledItems] = useState({});
  const [sustainabilityCheckboxState, setSustainabilityCheckboxState] =
    useState({});
  const [nameValidation, setNameValidation] = useState(null);
  const [pillarValidation, setPillarValidation] = useState(null);
  const [typeValidation, setTypeValidation] = useState(null);
  const [areaValidation, setAreaValidation] = useState(null);
  const [descriptionError, setDescriptionError] = useState("");
  const [changedCheckboxSustainability, setChangedCheckboxSustainability] =
    useState([]);
  const idPropertyMapping = useMemo(
    () => ({
      source: "moduleSourceId",
      aps: "moduleActProServiceId",
      valuechain: "moduleValueChainId",
      geographies: "moduleGeographicalLocationId",
      stakeholders: "moduleStakeHolderId",
      sustainablilitymatters: "id",
    }),
    []
  );

  // Maximum character limit
  const MAX_CHAR_LIMIT = 3000;

  useEffect(() => {
    const defaultTimeframe = timeframeOptions?.length
      ? timeframeOptions.find((option) => option.name === "Short")
      : null;

    if (filteredUpdateData && isEditMode) {
      setName(filteredUpdateData.name || "");
      setPillar(filteredUpdateData.pillar || "");
      setArea(filteredUpdateData.area);
      setDescription(filteredUpdateData.description);
      setTimeframe(
        filteredUpdateData.timeframeTypeId === 0
          ? defaultTimeframe?.id.toString()
          : filteredUpdateData.timeframeTypeId.toString()
      );
      setType(filteredUpdateData.iroTypeId.toString() || "");
      setIsHumanRightsRelated(filteredUpdateData.isHumanRightsRelated || false);
    } else if (isAddMode) {
      setTimeframe(defaultTimeframe?.id.toString());
    }
    setAreaValidation(null);
    setPillarValidation(null);
    setNameValidation(null);
  }, [filteredUpdateData, isEditMode, isAddMode, timeframeOptions]);

  const previousTab = useRef(selectedTab);

  useEffect(() => {
    if (
      !isAddMode &&
      selectedTab !== "Basic" &&
      previousTab.current !== selectedTab
    ) {
      fetchTabData(selectedTab.toLowerCase().replace(/\s+/g, ""));
      previousTab.current = selectedTab;
    }
  }, [selectedTab, isAddMode, fetchTabData]);

  useEffect(() => {
    const currentTab = selectedTab.toLowerCase().replace(/\s+/g, "");
    const idProperty = idPropertyMapping[currentTab];

    const setStateForItem = (item, newState) => {
      newState[item[idProperty]] = item.isActive;
      if (item.children) {
        item.children.forEach((child) => setStateForItem(child, newState));
      }
    };

    if (tabData[currentTab]) {
      const newState = {};
      tabData[currentTab].forEach((item) => {
        setStateForItem(item, newState);
      });
      if (currentTab === "sustainablilitymatters") {
        setSustainabilityCheckboxState(newState);
      } else {
        setCheckboxState(newState);
      }
    }
  }, [tabData, selectedTab, idPropertyMapping]);

  const generateUpdatePayload = () => changedCheckboxSustainability;

  const validateFields = () => {
    let isValid = true;
    let firstErrorTab = null;
    if (!area) {
      setAreaValidation("Area is required");
      isValid = false;
      if (!firstErrorTab) firstErrorTab = "Basic";
    } else {
      setAreaValidation("");
    }

    if (!pillar) {
      setPillarValidation("Pillar is required");
      isValid = false;
      if (!firstErrorTab) firstErrorTab = "Basic";
    } else {
      setPillarValidation("");
    }

    if (!type) {
      setTypeValidation("Type is required");
      isValid = false;
      if (!firstErrorTab) firstErrorTab = "Basic";
    } else {
      setTypeValidation("");
    }

    if (!name) {
      setNameValidation("Name is required");
      isValid = false;
      if (!firstErrorTab) firstErrorTab = "Basic";
    } else {
      setNameValidation("");
    }
    if (description?.length >= MAX_CHAR_LIMIT) {
      setDescriptionError(
        `Description should not exceed ${MAX_CHAR_LIMIT} characters`
      );
      isValid = false;
      if (!firstErrorTab) firstErrorTab = "Description";
    } else {
      setDescriptionError("");
    }

    return { isValid, firstErrorTab };
  };

  const handleSubmit = () => {
    const { isValid, firstErrorTab } = validateFields();

    if (!isValid) {
      if (firstErrorTab && selectedTab !== firstErrorTab) {
        setSelectedTab(firstErrorTab);
      }
    } else {
      if (isEditMode) {
        const tab = selectedTab.toLowerCase().replace(/\s+/g, "");
        if (tab === "sustainablilitymatters") {
          tabData.updatedsustainablilitymatters = generateUpdatePayload(
            tabData.sustainablilitymatters
          );
        }

        updateTabData(tab);
      }
      const data = {
        pillar: pillar || null,
        area: area,
        name: name,
        description: description || "",
        iroTypeId: parseInt(type, 10),
        timeframeTypeId: parseInt(timeframe, 10),
        isHumanRightsRelated: isHumanRightsRelated,
      };
      handleDoneClick(data);
    }
  };

  const handleCheckboxChange = (id) => {
    setCheckboxState((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));

    setTabData((prevTabData) => {
      const updatedTabData = { ...prevTabData };
      const currentTab = selectedTab.toLowerCase().replace(/\s+/g, "");
      const idProperty = idPropertyMapping[currentTab];

      const itemIndex = updatedTabData[currentTab].findIndex(
        (item) => item[idProperty] === id
      );

      if (itemIndex > -1) {
        const updatedItem = {
          ...updatedTabData[currentTab][itemIndex],
          isActive: !updatedTabData[currentTab][itemIndex].isActive,
        };

        updatedTabData[currentTab] = [
          ...updatedTabData[currentTab].slice(0, itemIndex),
          updatedItem,
          ...updatedTabData[currentTab].slice(itemIndex + 1),
        ];
      }

      setToggledItems((prevToggledItems) => ({
        ...prevToggledItems,
        [id]: true,
      }));
      setTimeout(() => {
        setToggledItems((prevToggledItems) => {
          const { [id]: removed, ...rest } = prevToggledItems;
          return rest;
        });
      }, 300);

      return updatedTabData;
    });
  };

  const handleSustainabilityCheckboxChange = (clickedId) => {
    // 1) Determine the new state for the clicked item
    const newIsActive = !sustainabilityCheckboxState[clickedId];

    // 2) Update local checkboxState (so your UI checkbox toggles)
    setSustainabilityCheckboxState((prev) => ({
      ...prev,
      [clickedId]: newIsActive,
    }));

    // 3) Utility to log changes in "changedCheckboxSustainability"
    const addToChangedCheckboxState = (id, isActive) => {
      setChangedCheckboxSustainability((prev) => {
        const idx = prev.findIndex(
          (x) => x.moduleSustainabilityMatterId === id
        );
        if (idx !== -1) {
          // Already in changed list; update or remove if toggled back
          const updated = [...prev];
          updated[idx].isActive = isActive;
          if (updated[idx].originalIsActive === isActive) {
            updated.splice(idx, 1);
          }
          return updated;
        }
        // Not found => add a new entry
        return [
          ...prev,
          {
            moduleSustainabilityMatterId: id,
            isActive,
            originalIsActive: !isActive, // adjust if needed
          },
        ];
      });
    };

    setTabData((prevTabData) => {
      const data = prevTabData.sustainablilitymatters || [];

      // 4) Create a **new** array (so we don't mutate the original, read-only one)
      let newData = data.map((item) => ({ ...item }));

      // --------------------------------------------------------------------
      // A) TOGGLE THE CLICKED ITEM (IMMUTABLY)
      // --------------------------------------------------------------------
      const clickedIndex = newData.findIndex((d) => d.id === clickedId);
      if (clickedIndex !== -1) {
        // Make a new object with isActive toggled
        newData[clickedIndex] = {
          ...newData[clickedIndex],
          isActive: newIsActive,
        };
        addToChangedCheckboxState(clickedId, newIsActive);
      }

      // --------------------------------------------------------------------
      // B) TOGGLE ALL DESCENDANTS (CHILDREN) BREADTH-FIRST
      //    If you check a node => check all children
      //    If you uncheck a node => uncheck all children
      // --------------------------------------------------------------------
      const queue = [clickedId]; // We'll BFS downward from clickedId
      while (queue.length) {
        const parent = queue.shift();

        // Find all items whose `parentId === parent`
        newData = newData.map((child) => {
          if (child.parentId === parent) {
            if (child.isActive !== newIsActive) {
              // toggle child immutably
              const toggledChild = { ...child, isActive: newIsActive };
              addToChangedCheckboxState(toggledChild.id, newIsActive);
              queue.push(toggledChild.id); // also toggle that child's children
              return toggledChild;
            } else {
              // Child is already the correct state (checked or unchecked)
              queue.push(child.id);
              return child;
            }
          }
          return child;
        });
      }

      // --------------------------------------------------------------------
      // C) TOGGLE PARENTS UPWARD
      //    If you check a node => its parent must become active
      //    If you uncheck a node => parent might become inactive if no active children remain
      // --------------------------------------------------------------------
      function toggleParents(childId, isActive) {
        const child = newData.find((x) => x.id === childId);
        if (!child || child.parentId == null) return; // no parent => stop

        const parent = newData.find((x) => x.id === child.parentId);
        if (!parent) return;

        if (isActive) {
          // Checking a child => ensure parent is also active
          if (!parent.isActive) {
            // immutably toggle
            const pIndex = newData.findIndex((x) => x.id === parent.id);
            newData[pIndex] = { ...parent, isActive: true };
            addToChangedCheckboxState(parent.id, true);
            // Then keep going up
            toggleParents(parent.id, true);
          }
        } else {
          // Unchecking a child => see if *any sibling* is still active
          const siblingsActive = newData.some(
            (sib) => sib.parentId === parent.id && sib.isActive
          );
          if (!siblingsActive && parent.isActive) {
            // no child active => uncheck the parent
            const pIndex = newData.findIndex((x) => x.id === parent.id);
            newData[pIndex] = { ...parent, isActive: false };
            addToChangedCheckboxState(parent.id, false);
            toggleParents(parent.id, false);
          }
        }
      }

      toggleParents(clickedId, newIsActive);

      // 5) Return the updated array in tabData. This is a brand-new array
      return {
        ...prevTabData,
        sustainablilitymatters: newData,
      };
    });

    // Small highlight effect
    setToggledItems((prev) => ({ ...prev, [clickedId]: true }));
    setTimeout(() => {
      setToggledItems((prev) => {
        const { [clickedId]: _, ...rest } = prev;
        return rest;
      });
    }, 300);
  };

  const renderTableCell = (colName, value, row, index) => {
    const currentTab = selectedTab.toLowerCase().replace(/\s+/g, "");
    const idProperty = idPropertyMapping[currentTab];
    const rowId = row[idProperty];

    if (colName === "status") {
      return (
        <TableCell
          key={index}
          style={{ textAlign: "center", verticalAlign: "middle" }}
        >
          <MotifCheckbox
            id={`checkbox-${rowId}`}
            name={`checkbox-${rowId}`}
            value="Active"
            className="iro-checkbox"
            checked={checkboxState[rowId] || false}
            onChange={() => handleCheckboxChange(rowId)}
            disabled={row.isMappedToAssessment}
          >
            Active
          </MotifCheckbox>
        </TableCell>
      );
    }
    return <TableCell key={index}>{value}</TableCell>;
  };

  const generateRowKey = (row) =>
    row[idPropertyMapping[selectedTab.toLowerCase().replace(/\s+/g, "")]];

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  /**
   * Filters a flat array (each item has {id, parentId, label, isActive, ...}) by query & status,
   * returning a hierarchical structure of matching nodes.
   *
   * @param {Array} flatData - The raw flat array from your API
   * @param {string} query - Text to match in item.label
   * @param {string} status - "None", "Active", or "Inactive"
   * @returns {Array} An array of root nodes, each with children that matched or contained a match
   */
  const filterTreeData = (flatData, query, status) => {
    // 1) Build a true tree from the flat array
    const lookup = new Map();
    flatData.forEach((item) => {
      // Copy item so we don't mutate original
      lookup.set(item.id, { ...item, children: [] });
    });

    // Link children to their parents
    const rootNodes = [];
    lookup.forEach((node) => {
      if (node.parentId && lookup.has(node.parentId)) {
        lookup.get(node.parentId).children.push(node);
      } else {
        // no parent => top-level
        rootNodes.push(node);
      }
    });

    // 2) Normalize the query and define a recursive filter
    const lowerQuery = query?.toLowerCase() || "";

    const traverse = (items) => {
      return items
        .map((item) => {
          // Does the label match the search text?
          const matchesQuery =
            !lowerQuery || item.label?.toLowerCase().includes(lowerQuery);

          // Does the status match?
          const matchesStatus =
            status === "None" ||
            (status === "Active" && item.isActive) ||
            (status === "Inactive" && !item.isActive);

          // If this item itself matches, keep it, but still recursively filter its children
          if (matchesQuery && matchesStatus) {
            return {
              ...item,
              children: traverse(item.children || []),
            };
          }

          // Otherwise, see if any of its children match
          const filteredChildren = traverse(item.children || []);
          if (filteredChildren.length > 0) {
            return {
              ...item,
              children: filteredChildren,
            };
          }

          // Neither this node nor its children matched => filter it out
          return null;
        })
        .filter(Boolean);
    };

    // 3) Run the filter on your rootNodes
    return traverse(rootNodes);
  };

  const filterData = (data) => {
    return data.filter((item) => {
      const matchesSearchQuery =
        item.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.typeName?.toLowerCase().includes(searchQuery.toLowerCase());

      if (filterValue === "Active") {
        return (
          matchesSearchQuery &&
          (item.isActive ||
            toggledItems[
              item[
                idPropertyMapping[selectedTab.toLowerCase().replace(/\s+/g, "")]
              ]
            ])
        );
      } else if (filterValue === "Inactive") {
        return (
          matchesSearchQuery &&
          (!item.isActive ||
            toggledItems[
              item[
                idPropertyMapping[selectedTab.toLowerCase().replace(/\s+/g, "")]
              ]
            ])
        );
      } else {
        return matchesSearchQuery;
      }
    });
  };

  const handlePillarChange = (newValue, actionMeta) => {
    if (actionMeta.action === "create-option") {
      const newOption = { value: newValue.value, label: newValue.label };
      pillarOptions.push(newOption);
    }
    setPillar(newValue.value);
  };

  const handleAreaChange = (newValue, actionMeta) => {
    if (actionMeta.action === "create-option") {
      const newOption = { value: newValue.value, label: newValue.label };
      areaOptions.push(newOption);
    }
    setArea(newValue.value);
  };

  const colourStyles = {
    option: (styles, { isFocused }) => ({
      ...styles,
      backgroundColor: isFocused ? "#E6E6E9" : null,
      color: "#4E4E5D",
      fontWeight: 300,
    }),
    control: (styles) => ({
      ...styles,
      backgroundColor: "#FFF",
    }),
    menu: (styles) => ({
      ...styles,
      backgroundColor: "#FFF",
    }),
  };

  const getTabData = () => {
    switch (selectedTab) {
      case "Source":
        return tabData.source.map((item) => ({
          ...item,
          typeName: item.sourceType,
        }));
      case "APS":
        return tabData.aps.map((item) => ({
          ...item,
          typeName: item.actProServiceType,
        }));
      case "Value chain":
        return tabData.valuechain.map((item) => ({
          ...item,
          typeName: item.valueChainType,
        }));
      case "Stakeholders":
        return tabData.stakeholders.map((item) => ({
          ...item,
          typeName: item.stakeHolderType,
        }));
      case "Sustainablility matters":
        return tabData.sustainablilitymatters || [];
      default:
        return tabData[selectedTab.toLowerCase().replace(/\s+/g, "")] || [];
    }
  };

  const changeToggle = () => {
    setIsHumanRightsRelated((prevState) => !prevState);
  };

  const renderTabContent = () => {
    if (selectedTab === "Basic") {
      return (
        <>
          <div className="form-row">
            <div className="form-group">
              <MotifMessage>
                <CustomTooltip
                  title="Option for individual classification/grouping of IROs - define custom class/group"
                  placement="top"
                >
                  {pillarLabel}*
                </CustomTooltip>
              </MotifMessage>

              <MotifFormField>
                <CreatableSelect
                  value={pillarOptions.find(
                    (option) => option.value === pillar
                  )}
                  onChange={handlePillarChange}
                  options={pillarOptions}
                  placeholder={SelectPillar}
                  styles={colourStyles}
                  className="iro-select pillar-area-select"
                />
              </MotifFormField>
              {pillarValidation && (
                <MotifErrorMessage>{pillarValidation}</MotifErrorMessage>
              )}
            </div>
            <div className="form-group">
              <MotifMessage>
                <CustomTooltip
                  title="Second layer of individual IRO grouping"
                  placement="top"
                >
                  {areaLabel}*
                </CustomTooltip>
              </MotifMessage>

              <MotifFormField>
                <CreatableSelect
                  value={areaOptions.find((option) => option.value === area)}
                  onChange={handleAreaChange}
                  options={areaOptions}
                  placeholder={selectAreaLabel}
                  styles={colourStyles}
                  className="iro-select pillar-area-select"
                />
                {areaValidation && (
                  <MotifErrorMessage>{areaValidation}</MotifErrorMessage>
                )}
              </MotifFormField>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group thirty-percent">
              <MotifMessage data-testid="type">{typeLabel}*</MotifMessage>
              <MotifFormField className="filter-by-select">
                <MotifSelect
                  className="iro-textField iro-type-select"
                  ariaLabelledBy="select-dma-select-label"
                  placeholder="Select type"
                  visibleOptions={3}
                  value={type}
                  onChange={(e) => setType(e)}
                  disabled={isEditMode}
                >
                  {iroTypesOptions.map((option) => (
                    <MotifOption key={option.id} value={option.id.toString()}>
                      {option.name}
                    </MotifOption>
                  ))}
                </MotifSelect>
                {typeValidation && (
                  <MotifErrorMessage>{typeValidation}</MotifErrorMessage>
                )}
              </MotifFormField>
            </div>
            <div className="form-group thirty-percent">
              <MotifMessage data-testid="type">{TimeframeLabel}</MotifMessage>
              <MotifFormField className="filter-by-select">
                <MotifSelect
                  ariaLabelledBy="select-dma-select-label"
                  placeholder="Select timeframe"
                  visibleOptions={3}
                  value={timeframe}
                  onChange={(e) => setTimeframe(e)}
                  className="iro-textField iro-type-select"
                >
                  {timeframeOptions.map((option) => (
                    <MotifOption key={option.id} value={option.id.toString()}>
                      {option.name}
                    </MotifOption>
                  ))}
                </MotifSelect>
              </MotifFormField>
            </div>
            <div className="form-group thirty-percent">
              <MotifMessage data-testid="human-rights">
                {isHumanRightsRelatedLabel}
              </MotifMessage>

              <MotifFormField className="human-rights-toggle">
                <MotifToggleSwitch
                  onChange={changeToggle}
                  id="exampleToggle"
                  hideLabels={true}
                  checked={isHumanRightsRelated}
                />
              </MotifFormField>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group full-width">
              <MotifMessage data-testid="name">{nameLabel}*</MotifMessage>
              <MotifFormField>
                <MotifInput
                  className="iro-text-input"
                  placeholder="Input name"
                  aria-describedby="select-dma-name-input-label"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                {nameValidation && (
                  <MotifErrorMessage>{nameValidation}</MotifErrorMessage>
                )}
              </MotifFormField>
            </div>
          </div>
        </>
      );
    }

    if (selectedTab === "Description") {
      return (
        <div className="form-row sustain-description-label">
          <MotifLabel id="select-label" data-testid="description-label">
            {DescriptionLabel}
          </MotifLabel>
          <TextField
            placeholder=""
            multiline
            fullWidth
            rows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          {descriptionError && (
            <MotifErrorMessage>{descriptionError}</MotifErrorMessage>
          )}
        </div>
      );
    }

    if (selectedTab === "Sustainablility matters") {
      const filteredData = filterTreeData(
        tabData.sustainablilitymatters || [],
        searchQuery,
        filterValue
      );
      return (
        <>
          <div className="search-filter-container">
            <Paper component="form" className="search-container">
              <IconButton
                type="button"
                className="search-icon-iro"
                aria-label="search"
              >
                <SearchIcon />
              </IconButton>
              <InputBase
                className="search-box-input-iro"
                placeholder="Search"
                onChange={handleSearch}
                inputProps={{ "aria-label": "search For" }}
              />
            </Paper>
            <div className="filter-container">
              <MotifFormField className="filter-by-select">
                <MotifLabel id="select-dma-select-label" position="in">
                  {filterByLabel}
                </MotifLabel>
                <MotifSelect
                  ariaLabelledBy="select-dma-select-label"
                  labelPosition="in"
                  visibleOptions={3}
                  className="iro-textField"
                  value={filterValue}
                  onChange={(e) => setFilterValue(e)}
                >
                  <MotifOption value="None">None</MotifOption>
                  <MotifOption value="Active">Active</MotifOption>
                  <MotifOption value="Inactive">Inactive</MotifOption>
                </MotifSelect>
              </MotifFormField>
            </div>
          </div>
          <div className="iro-sm-container">
            <TreeView
              items={filteredData}
              defaultExpandedItems={[]}
              defaultSelectedItems=""
              pageName="IROModal"
              checkboxState={sustainabilityCheckboxState}
              handleCheckboxChange={handleSustainabilityCheckboxChange}
            />
          </div>
        </>
      );
    }

    const tableData = getTabData();

    const commonColumns = [
      { colName: "name", label: "Name", showSorting: true },
      { colName: "status", label: "Status", showSorting: false },
    ];

    const columnsWithType = [
      { colName: "name", label: "Name", showSorting: true },
      { colName: "typeName", label: "Type", showSorting: true },
      { colName: "status", label: "Status", showSorting: false },
    ];

    return (
      <>
        <div className="search-filter-container">
          <Paper component="form" className="search-container">
            <IconButton
              type="button"
              className="search-icon-iro"
              aria-label="search"
            >
              <SearchIcon />
            </IconButton>
            <InputBase
              className="search-box-input-iro"
              placeholder="Search"
              onChange={handleSearch}
              inputProps={{ "aria-label": "search For" }}
            />
          </Paper>
          <div className="filter-container">
            <MotifFormField className="filter-by-select">
              <MotifSelect
                ariaLabelledBy="select-dma-select-label"
                placeholder="Select filter"
                visibleOptions={3}
                className="iro-textField"
                value={filterValue}
                onChange={(e) => setFilterValue(e)}
              >
                <MotifOption value="None">None</MotifOption>
                <MotifOption value="Active">Active</MotifOption>
                <MotifOption value="Inactive">Inactive</MotifOption>
              </MotifSelect>
            </MotifFormField>
          </div>
        </div>
        <div className="iro-table-container">
          <ClientTable
            columns={
              selectedTab === "Geographies" ? commonColumns : columnsWithType
            }
            data={filterData(tableData)}
            itemsPerPage={500}
            renderTableCell={renderTableCell}
            generateRowKey={generateRowKey}
            showPagination={false}
          />
        </div>
      </>
    );
  };

  return (
    <div className="modal-overlay">
      <div className="sustain-modal modal">
        <div className="modal-header">
          <h2>{modalTitle}</h2>
        </div>
        <div className="modal-body">
          <div className="modal-sidebar">
            <ul>
              <li>
                <button
                  type="button"
                  className={`sidebar-item ${
                    selectedTab === "Basic" ? "active" : ""
                  }`}
                  onClick={() => setSelectedTab("Basic")}
                >
                  {BasicLabel}
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className={`sidebar-item ${
                    selectedTab === "Description" ? "active" : ""
                  }`}
                  onClick={() => setSelectedTab("Description")}
                >
                  {TabDescriptionLabel}
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className={`sidebar-item ${
                    selectedTab === "Source" ? "active" : ""
                  }
                  } ${isAddMode ? "disabled" : ""}`}
                  onClick={() => setSelectedTab("Source")}
                >
                  {SourceLabel}
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className={`sidebar-item ${
                    selectedTab === "Value chain" ? "active" : ""
                  }
                  } ${isAddMode ? "disabled" : ""}`}
                  onClick={() => setSelectedTab("Value chain")}
                >
                  {ValueChainLabel}
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className={`sidebar-item ${
                    selectedTab === "APS" ? "active" : ""
                  }
                  } ${isAddMode ? "disabled" : ""}`}
                  onClick={() => setSelectedTab("APS")}
                >
                  {APSLabel}
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className={`sidebar-item ${
                    selectedTab === "Geographies" ? "active" : ""
                  }
                  } ${isAddMode ? "disabled" : ""}`}
                  onClick={() => setSelectedTab("Geographies")}
                >
                  {GeographiesLabel}
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className={`sidebar-item ${
                    selectedTab === "Stakeholders" ? "active" : ""
                  }
                  } ${isAddMode ? "disabled" : ""}`}
                  onClick={() => setSelectedTab("Stakeholders")}
                >
                  {StakeholdersLabel}
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className={`sidebar-item ${
                    selectedTab === "Sustainablility matters" ? "active" : ""
                  }
                  } ${isAddMode ? "disabled" : ""}`}
                  onClick={() => setSelectedTab("Sustainablility matters")}
                >
                  {SustainablilityMattersLabel}
                </button>
              </li>
            </ul>
          </div>
          <div className="modal-content">{renderTabContent()}</div>
        </div>
        <div className="dma-modal-footer">
          <div className="left-buttons">
            {isEditMode && (
              <MotifButton
                className="modal-delete-button"
                onClick={onDeleteClick}
              >
                {DeleteLabel}
              </MotifButton>
            )}
          </div>
          <div className="right-buttons">
            <MotifButton onClick={onClose} className="modal-cancel-button">
              {cancelButtonLabel}
            </MotifButton>
            <MotifButton
              onClick={handleSubmit}
              disabled={disable}
              className="modal-done-button"
            >
              {isEditMode ? updateButtonLabel : saveButtonLabel}
            </MotifButton>
          </div>
        </div>
      </div>
    </div>
  );
};

IroModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  modalMode: PropTypes.string.isRequired,
  modalTitle: PropTypes.string.isRequired,
  nameLabel: PropTypes.string.isRequired,
  typeLabel: PropTypes.string.isRequired,
  areaLabel: PropTypes.string.isRequired,
  pillarLabel: PropTypes.string.isRequired,
  selectAreaLabel: PropTypes.string.isRequired,
  SelectPillar: PropTypes.string.isRequired,
  BasicLabel: PropTypes.string.isRequired,
  DescriptionLabel: PropTypes.string.isRequired,
  TabDescriptionLabel: PropTypes.string.isRequired,
  SourceLabel: PropTypes.string.isRequired,
  ValueChainLabel: PropTypes.string.isRequired,
  APSLabel: PropTypes.string.isRequired,
  SustainablilityMattersLabel: PropTypes.string.isRequired,
  StakeholdersLabel: PropTypes.string.isRequired,
  GeographiesLabel: PropTypes.string.isRequired,
  saveButtonLabel: PropTypes.string.isRequired,
  updateButtonLabel: PropTypes.string.isRequired,
  cancelButtonLabel: PropTypes.string.isRequired,
  filterByLabel: PropTypes.string.isRequired,
  pillarOptions: PropTypes.array,
  areaOptions: PropTypes.array,
  handleDoneClick: PropTypes.func.isRequired,
  filteredUpdateData: PropTypes.object,
  fetchTabData: PropTypes.func.isRequired,
  tabData: PropTypes.object.isRequired,
  setTabData: PropTypes.func.isRequired,
  updateTabData: PropTypes.func.isRequired,
  TimeframeLabel: PropTypes.string.isRequired,
  timeframeOptions: PropTypes.array,
  iroTypesOptions: PropTypes.array,
  onDeleteClick: PropTypes.func.isRequired,
  DeleteLabel: PropTypes.string.isRequired,
  SelectType: PropTypes.string.isRequired,
  SelectTimeframe: PropTypes.string.isRequired,
  InputName: PropTypes.string.isRequired,
  disable: PropTypes.bool,
  isHumanRightsRelatedLabel: PropTypes.string,
};

export default IroModal;