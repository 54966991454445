import { logoutUser } from "../../src/features/slices/HomeSlice";
import config from "../config";

export const handleError = async (dispatch, navigate) => {
  try {
    dispatch(logoutUser());
    localStorage.removeItem("user");
    localStorage.removeItem("language");
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("language");
    sessionStorage.clear();
    const redirectUri = config.baseDomainUrl || "/";
    if (window.msalInstance) {
      await window.msalInstance.logoutRedirect({
        postLogoutRedirectUri: redirectUri,
      });
    } else {
      window.location.href = redirectUri;
    }
  } catch (error) {
    console.error("Error during sign-out", error);
  }
};
