import React from "react";
import PropTypes from "prop-types";
import "./ProjectDetails.scss";

function ProjectDetailTable({
  data,
  projectName,
  clientName,
  country,
  engagementcode,
  projectSizeUSD,
  clientSector,
  projectAdministrator,
  isAdmin,
}) {
  return (
    <div>
      <table>
        &nbsp; &nbsp;
        <tr>
          <th className="project-details-header">{projectName}</th>
          <th className="project-details-header">{clientName}</th>
          <th className="project-details-header">{country}</th>
          <th className="project-details-header">{engagementcode}</th>
        </tr>
        <tr>
          <td className="project-details-data">{data.name}</td>
          <td className="project-details-data">{data.clientName}</td>
          <td className="project-details-data">{data.countryName}</td>
          <td className="project-details-data">{data.engagementCode}</td>
        </tr>
        &nbsp; &nbsp;
        {isAdmin && (
          <>
            <tr>
              <th className="project-details-header">{projectSizeUSD}</th>
              <th className="project-details-header">{clientSector}</th>
              <th className="project-details-header">{projectAdministrator}</th>
            </tr>
            <tr>
              <td className="align-top">{data.size}</td>
              <td className="align-top">{`${data.sectorName} - ${data.subName}`}</td>
              <td>
                {(data.users ?? []).map((user, index) => (
                  <div className="project-user" key={index}>
                    {user.user.name}
                  </div>
                ))}
              </td>
            </tr>
          </>
        )}
        &nbsp; &nbsp;
      </table>
    </div>
  );
}

ProjectDetailTable.propTypes = {
  data: PropTypes.object,
  projectName: PropTypes.string,
  isAdmin: PropTypes.bool,
  clientName: PropTypes.string,
  country: PropTypes.string,
  engagementcode: PropTypes.string,
  projectSizeUSD: PropTypes.string,
  clientSector: PropTypes.string,
  projectAdministrator: PropTypes.string,
};

export default ProjectDetailTable;