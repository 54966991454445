import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
  Suspense,
} from "react";
import { MotifProgressLoader } from "@ey-xd/motif-react";
import "./UserManagement.scss";
import Header from "../../components/headers/Header";
import Subheader from "../../components/subheader/Subheader";
import AddUser from "../../components/modals/addUser/AddUser";
import { TableCell, Box, IconButton } from "@mui/material";
import { Edit, Delete } from "../../constants/icons/Icons";
import language from "../../constants/languages/en/translations.json";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUsers,
  createNewUser,
  updateUser,
  deleteUser,
  fetchProjectUserRoles,
  clearError,
} from "../../features/slices/ProjectScreens";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { handleError } from "../../utils/handleError";
import ErrorModal from "../../components/modals/error/errorModal";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
const ClientTable = React.lazy(() =>
  import("../../components/clientTable/ClientTable")
);

const getCookie = (name) => {
  const regex = new RegExp(`(^| )${name}=([^;]+)`);
  const match = regex.exec(document.cookie);
  return match ? match[2] : null;
};

const UserManagement = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [updateTrigger, setUpdateTrigger] = useState(0);
  const [userName, setUserName] = useState("");
  const [role, setRole] = useState("");
  const [email, setEmail] = useState("");
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isApprover, setIsApprover] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [transformedData, setTransformedData] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [userData, setUserData] = useState([]);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessageTemp, setErrorMessageTemp] = useState(null);
  const isAdmin = true;
  const location = useLocation();
  const navigate = useNavigate();
  const token =
    useSelector((state) => state.user.jwtToken) ||
    getCookie("authToken") ||
    sessionStorage.getItem("authToken");
  const currentLoggedInUser = useSelector((state) => state.user);
  const currentLoggedInUserId = currentLoggedInUser?.user?.localAccountId;
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const userIDsRef = useRef([]);
  const projectName = location.state?.projectName;
  const ProjectAdmin = "Project admin";
  const { t } = useTranslation();
  const { isLoading, isError, errorMessage, isGenericError } = useSelector(
    (state) => state.projectDetails
  );

  const fetchUserRoles = useCallback(async () => {
    try {
      const response = await dispatch(
        fetchProjectUserRoles({ token })
      ).unwrap();
      if (response.success) {
        setUserRoles(response.data);
      }
    } catch (error) {
      console.error("fetching of roles failed:", error);
    }
  }, [dispatch, token]);

  const fetchUserData = useCallback(async () => {
    try {
      const response = await dispatch(
        fetchUsers({ projectId, token })
      ).unwrap();
      if (response.success) {
        setUserData(response.data);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  }, [token, projectId, dispatch]);
  useEffect(() => {
    fetchUserData();
    fetchUserRoles();
  }, [fetchUserData, fetchUserRoles, updateTrigger]);

  useEffect(() => {
    if (Array.isArray(userData)) {
      userIDsRef.current = userData.map((item) => item.id);
      const data = userData.map((item) => ({
        userId: item.user?.id,
        Name: item.user?.name,
        Email: item.user?.email,
        Role: item.role?.name,
      }));
      setTransformedData(data);
    } else {
      console.error("Invalid user data format:", userData);
    }
  }, [userData]);

  useEffect(() => {
    if (isError && errorMessage) {
      setErrorMessageTemp(errorMessage);
      setShowErrorModal(true);
    }
  }, [isError, errorMessage]);

  const handleCancelErrorModal = () => {
    setShowErrorModal(false);
    dispatch(clearError());
  };

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true);
    setModalType(language.UserManagementPage);
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const handleSearch = useCallback((event) => {
    setSearchQuery(event.target.value);
  }, []);

  const handleUpdateOpenModal = useCallback(
    async (userId, role, name, email) => {
      if (!Array.isArray(userData)) {
        console.error("Invalid user data format.");
        return;
      }
      const currentUser = userData.find((user) => user.user.id === userId);
      if (!currentUser) {
        console.error("User not found or insufficient permissions.");
        return;
      }

      const isProjectAdmin =
        currentUser.role.name.toLowerCase() === ProjectAdmin.toLowerCase();
      const admins = userData.filter(
        (user) => user.role.name.toLowerCase() === ProjectAdmin.toLowerCase()
      );

      if (isProjectAdmin && admins.length <= 1) {
        console.error("Cannot edit the only remaining Project Admin.");
        return;
      }

      // if (
      //   !isProjectAdmin
      // ) {
      //   console.error("Invalid email domain.");
      //   return;
      // }
      setIsUpdateModalOpen(true);
      setModalType(language.UpdateUserManagementPage);
      setSelectedUserId(userId);
      setIsApprover(currentUser.isApprover);
      setSelectedId(currentUser.id);
      setRole(currentUser.role.id);
      setEmail(email);
      setUserName(name);
    },
    [userData, ProjectAdmin]
  );

  const handleUpdateCloseModal = useCallback(() => {
    setIsUpdateModalOpen(false);
  }, []);

  const handleDeleteOpenModal = useCallback(
    (userId, role) => {
      const admins = userData?.filter(
        (user) => user.role.name.toLowerCase() === ProjectAdmin.toLowerCase()
      );

      if (
        role.toLocaleLowerCase() === ProjectAdmin.toLocaleLowerCase() &&
        admins?.length <= 1
      ) {
        setErrorMessageTemp("There must be at least one Project Admin.");
        setShowErrorModal(true);
      }
      setIsDeleteModalOpen(true);
      setSelectedUserId(userId);
      setModalType(language.ConfirmationPopup);
    },
    [userData, ProjectAdmin]
  );

  const handleDeleteCloseModal = useCallback(() => {
    setIsDeleteModalOpen(false);
  }, []);

  const handleDeleteConfirm = useCallback(async () => {
    const admins = userData?.filter(
      (user) => user.role.name.toLowerCase() === ProjectAdmin.toLowerCase()
    );

    const currentUser = userData?.find(
      (user) => user.user.id === selectedUserId
    );

    if (
      currentUser &&
      currentUser.role.name.toLowerCase() === ProjectAdmin.toLowerCase() &&
      admins?.length <= 1
    ) {
      return;
    }

    try {
      await dispatch(
        deleteUser({ projectId, userId: selectedUserId, token })
      ).unwrap();
      if (currentLoggedInUserId === currentUser.user.aadId) {
        navigate("/");
        return;
      }

      setUpdateTrigger((prev) => prev + 1);
      handleDeleteCloseModal();
      fetchUserData();
    } catch (error) {
      console.error("Deletion failed:", error);
    }
  }, [
    dispatch,
    projectId,
    selectedUserId,
    token,
    handleDeleteCloseModal,
    userData,
    ProjectAdmin,
    fetchUserData,
    navigate,
    currentLoggedInUserId,
  ]);

  const handleButtonClick = useCallback(
    (row, buttonName) => {
      switch (buttonName) {
        case "Edit":
          handleUpdateOpenModal(row.userId, row.Role, row.Name, row.Email);
          break;
        case "Delete":
          handleDeleteOpenModal(row.userId, row.Role);
          break;
        default:
      }
    },
    [handleUpdateOpenModal, handleDeleteOpenModal]
  );

  const columnsToDisplay = useMemo(
    () => [
      { colName: "Name", label: "Name", showSorting: true },
      { colName: "Role", label: "Role", showSorting: true },
      { colName: "Email", label: "Email", showSorting: true },
      { colName: "Action", label: "Action", showSorting: false },
    ],
    []
  );

  const createNewUserInAdminUser = useCallback(
    async (userData) => {
      if (
        userData.aadId &&
        userData.name &&
        userData.email &&
        userData.projectRoleId
      ) {
        try {
          const response = await dispatch(
            createNewUser({ projectId, userData, token })
          ).unwrap();
          if (response.success) {
            setUpdateTrigger((prev) => prev + 1);
            handleCloseModal();
            fetchUserData();
          } else {
            console.error("Creation failed:", response.error);
          }
        } catch (error) {
          console.error("Creation failed:", error);
        }
      } else {
        alert("Please fill all the required fields");
      }
    },
    [dispatch, projectId, token, handleCloseModal, fetchUserData]
  );

  const updateUserInAdminUser = useCallback(
    async (updateData) => {
      if (updateData.projectRoleId) {
        updateData.userId = selectedUserId;
        updateData.id = selectedId;
        updateData.projectId = projectId;

        try {
          const response = await dispatch(
            updateUser({ updateData, token })
          ).unwrap();
          if (response.success) {
            setUpdateTrigger((prev) => prev + 1);
            handleUpdateCloseModal();

            const updatedUser = response.data;
            console.log("Updated user from response:", updatedUser);

            if (
              updatedUser?.role?.name.toLowerCase() === "project member" &&
              updatedUser?.user?.aadId === currentLoggedInUserId
            ) {
              navigate(`/project-home/${projectId}`);
            } else {
              fetchUserData();
            }
          }
        } catch (error) {
          console.error("Update failed:", error);
        }
      } else {
        alert("Please fill all the fields");
      }
    },
    [
      dispatch,
      projectId,
      token,
      selectedUserId,
      selectedId,
      fetchUserData,
      navigate,
      currentLoggedInUserId,
      handleUpdateCloseModal,
    ]
  );

  const generateRowKey = useCallback((row) => row.userId, []);

  const renderTableCell = useCallback(
    (column, value, row) => {
      if (column === "Action") {
        return (
          <TableCell key={column} sx={{ textAlign: "right", width: "150px" }}>
            <Box display="flex" justifyContent="flex-end">
              <IconButton
                onClick={() => handleButtonClick(row, "Edit")}
                aria-label={`Edit user ${row.Name}`}
              >
                <Edit />
              </IconButton>
              <IconButton
                color="secondary"
                onClick={() => handleButtonClick(row, "Delete")}
                aria-label={`Delete user ${row.Name}`}
              >
                <Delete />
              </IconButton>
            </Box>
          </TableCell>
        );
      }
      return <TableCell key={column}>{value}</TableCell>;
    },
    [handleButtonClick]
  );

  const filteredData = useMemo(() => {
    const searchQueryLower = searchQuery.toLowerCase();
    return transformedData.filter((row) => {
      const nameValue = String(row.Name).toLowerCase();
      const emailValue = String(row.Email).toLowerCase();
      const roleValue = String(row.Role).toLowerCase();
      return (
        nameValue.includes(searchQueryLower) ||
        emailValue.includes(searchQueryLower) ||
        roleValue.includes(searchQueryLower)
      );
    });
  }, [transformedData, searchQuery]);

  if (showErrorModal && errorMessageTemp) {
    return (
      <div>
        <ErrorModal
          setName={t("Error")}
          labelText={errorMessageTemp}
          handleButtonClick={
            isGenericError ? handleError(dispatch) : handleCancelErrorModal
          }
          deleteButtonLabel={isGenericError ? t("Reload") : t("Cancel")}
        />
      </div>
    );
  }
  return (
    <div className="all-user-management-container">
      {isLoading && (
        <MotifProgressLoader
          show
          className="loader"
          variant="default"
          data-testid="loading-spinner"
        />
      )}
      <Header />
      <div className="user-management-org-container">
        <Breadcrumbs
          items={[
            { label: "All projects", link: "/" },
            {
              label: projectName,
              link: `/project-home/${projectId}`,
              token: token,
            },
            {
              label: "User management",
              link: `/user-management/${projectId}`,
              token: token,
            },
          ]}
        />
        <div className="user-management-org-subheader">
          <Subheader
            isAdmin={isAdmin}
            page={language.ClientOrgPage}
            title={t("UserManagementTitle")}
            onChangeSearchInput={handleSearch}
            handleOpenModal={handleOpenModal}
            AddNewEN={language.CreateNewEN}
            ClientOrgAccessTitleEN={t("ClientOrgPage")}
            CreateNewEN={t("CreateNew")}
            disableButton={isLoading}
          />
          {modalType === language.UserManagementPage && (
            <AddUser
              isOpen={isModalOpen}
              onClose={handleCloseModal}
              page={language.UserManagementPage}
              TitleUserManagementEN={t("TitleUserManagement")}
              UserNameEN={t("UserName")}
              RoleEN={t("Role")}
              SelectRoleEN={t("SelectRole")}
              UserEmailEN={t("Email")}
              EmailContentEN={t("EmailContent")}
              CancelButtonEN={t("Cancel")}
              DoneButtonEN={t("DoneButton")}
              UserManagementPageEN={t("UserManagementPage")}
              callApi={createNewUserInAdminUser}
              userRoles={userRoles}
              PreparerLabel={t("Preparer")}
              ApproverLabel={t("Approver")}
            />
          )}
          {modalType === language.UpdateUserManagementPage && (
            <AddUser
              isOpen={isUpdateModalOpen}
              onClose={handleUpdateCloseModal}
              page={language.UpdateUserManagementPage}
              TitleUserManagementEN={t("TitleUpdateUserManagement")}
              UserNameEN={t("UserName")}
              UserNameContentEN={t("UserNameContent")}
              RoleEN={t("Role")}
              SelectRoleEN={t("SelectRole")}
              UserEmailEN={t("Email")}
              EmailContentEN={t("EmailContent")}
              CancelButtonEN={t("Cancel")}
              UpdateButtonEN={t("Update")}
              TitleUpdateUserManagementEN={t("TitleUpdateUserManagement")}
              SelectRole1EN={t("SelectRole1")}
              SelectRole2EN={t("SelectRole2")}
              UpdateUserManagementPageEN={t("UpdateUserManagementPage")}
              selectedUsername={userName}
              selectedUserId={selectedUserId ? selectedUserId.toString() : ""}
              selectedRole={role}
              selectedEmail={email}
              approverValue={isApprover}
              callApi={updateUserInAdminUser}
              userRoles={userRoles}
              PreparerLabel={t("Preparer")}
              ApproverLabel={t("Approver")}
            />
          )}
          {modalType === language.ConfirmationPopup && (
            <AddUser
              isOpen={isDeleteModalOpen}
              onClose={handleDeleteCloseModal}
              page={language.ConfirmationPopup}
              ConfirmationPopup={t("ConfirmationPopup")}
              ConfirmationTitle={t("DeleteLabel")}
              ConfirmationEN={t("DeleteLabel")}
              ConfirmationDeleteMsgEN={t("ConfirmationDeleteMsg")}
              CancelButtonEN={t("Cancel")}
              callApi={handleDeleteConfirm}
            />
          )}
        </div>
        <div>
          <Suspense
            fallback={
              <MotifProgressLoader
                data-testid="loading-spinner"
                show
                variant="default"
              />
            }
          >
            <ClientTable
              columns={columnsToDisplay}
              data={filteredData}
              itemsPerPage={5}
              section={t("InstanceHomeProjects")}
              generateRowKey={generateRowKey}
              renderTableCell={renderTableCell}
              handleButtonClick={handleButtonClick}
              page={language.UpdateUserManagementPage}
            />
          </Suspense>
        </div>
      </div>
    </div>
  );
};

export default UserManagement;
