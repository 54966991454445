const config = {
  baseUrl: window.env?.REACT_APP_BASE_URL || process.env.REACT_APP_BASE_URL,
  apiBaseUrl:
    window.env?.REACT_APP_API_BASE_URL || process.env.REACT_APP_API_BASE_URL,
  instanceLocation:
    window.env?.REACT_APP_INSTANCE_LOCATION ||
    process.env.REACT_APP_INSTANCE_LOCATION,
  baseDomainUrl:
    window.env?.REACT_APP_BASE_DOMAIN_URL ||
    process.env.REACT_APP_BASE_DOMAIN_URL,
  azureClientId:
    window.env?.REACT_APP_AZURE_CLIENT_ID ||
    process.env.REACT_APP_AZURE_CLIENT_ID,
  azureAppId:
    window.env?.REACT_APP_AZURE_APPLICATION_ID ||
    process.env.REACT_APP_AZURE_APPLICATION_ID,
  apiPortalBaseUrl:
    window.env?.REACT_APP_API_PORTAL_BASE_URL ||
    process.env.REACT_APP_API_PORTAL_BASE_URL,
};

export default config;
