import React, { useState, useEffect } from "react";
import {
  MotifButton,
  MotifSelect,
  MotifOption,
  MotifFormField,
  MotifMessage,
  MotifCard,
  MotifCardHeader,
  MotifCardBody,
  MotifLabel,
} from "@ey-xd/motif-react";
import PropTypes from "prop-types";
import "./MappingForm.scss";

const FormGroup = ({
  label,
  selectLabel,
  value,
  onChange,
  options,
  pagenName,
}) => (
  <div className="form-group full-width">
    <MotifMessage
      className={pagenName === "IROAssessment" ? "" : "dga-racl-message"}
    >
      {label}
    </MotifMessage>
    <MotifFormField>
      <MotifLabel id="select-dma-select-label" position="in">
        {selectLabel}
      </MotifLabel>
      <MotifSelect
        className={
          pagenName === "IROAssessment"
            ? "session-modal-input"
            : "session-modal-input-dga"
        }
        ariaLabelledBy="select-dma-select-label"
        labelPosition="in"
        visibleOptions={3}
        value={value}
        onChange={onChange}
      >
        {options.map((item) => (
          <MotifOption key={item.id} value={item.id.toString()}>
            {item.name}
          </MotifOption>
        ))}
      </MotifSelect>
    </MotifFormField>
  </div>
);

FormGroup.propTypes = {
  label: PropTypes.string.isRequired,
  selectLabel: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  pagenName: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
};

const MappingForm = ({
  responsibleLabel,
  accountableLabel,
  consultedLabel,
  informedLabel,
  othersLabel,
  selectResponsilbleLabel,
  selectAccountableLabel,
  selectConsultedLabel,
  selectInformedLabel,
  selectOthersLabel,
  RACIMapping,
  saveButtonLabel,
  cancelButtonLabel,
  onCancel,
  onSave,
  raciDropdownData,
  initialData,
  pagenName,
  onChange,
}) => {
  const [formData, setFormData] = useState({
    responsible: "",
    accountable: "",
    consulted: "",
    informed: "",
    others: "",
  });

  useEffect(() => {
    if (initialData) {
      setFormData({
        responsible: initialData.responsible.toString() || "",
        accountable: initialData.accountable.toString() || "",
        consulted: initialData.consulted.toString() || "",
        informed: initialData.informed.toString() || "",
        others: initialData.others.toString() || "",
      });
    }
  }, [initialData]);

  const handleDropdownChange = (field) => (e) => {
    const value = e;
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));

    if (onChange) {
      onChange({
        ...formData,
        [field]: value,
      });
    }
  };

  const handleSave = () => {
    const parsedData = {
      responsible: parseInt(formData.responsible, 10),
      accountable: parseInt(formData.accountable, 10),
      consulted: parseInt(formData.consulted, 10),
      informed: parseInt(formData.informed, 10),
      others: parseInt(formData.others, 10),
    };
    onSave(parsedData);
  };

  return (
    <>
      {pagenName === "IROAssessment" ? (
        <MotifCard className="mapping-card">
          <MotifCardHeader className="mapping-header" variant="alt">
            {RACIMapping}
          </MotifCardHeader>
          <MotifCardBody>
            <div className="form-row">
              <FormGroup
                label={responsibleLabel}
                selectLabel={selectResponsilbleLabel}
                value={formData.responsible}
                onChange={handleDropdownChange("responsible")}
                options={raciDropdownData}
              />
              <FormGroup
                label={accountableLabel}
                selectLabel={selectAccountableLabel}
                value={formData.accountable}
                onChange={handleDropdownChange("accountable")}
                options={raciDropdownData}
              />
            </div>
            <div className="form-row">
              <FormGroup
                label={consultedLabel}
                selectLabel={selectConsultedLabel}
                value={formData.consulted}
                onChange={handleDropdownChange("consulted")}
                options={raciDropdownData}
              />
              <FormGroup
                label={informedLabel}
                selectLabel={selectInformedLabel}
                value={formData.informed}
                onChange={handleDropdownChange("informed")}
                options={raciDropdownData}
              />
            </div>
            <div className="form-row">
              <FormGroup
                label={othersLabel}
                selectLabel={selectOthersLabel}
                value={formData.others}
                onChange={handleDropdownChange("others")}
                options={raciDropdownData}
              />
            </div>
            <div className="form-footer">
              <MotifButton onClick={onCancel} className="modal-cancel-button">
                {cancelButtonLabel}
              </MotifButton>
              <MotifButton onClick={handleSave} className="modal-done-button">
                {saveButtonLabel}
              </MotifButton>
            </div>
          </MotifCardBody>
        </MotifCard>
      ) : (
        <MotifCard className="mapping-card-dga">
          <MotifCardBody className="mapping-card-dga-body">
            <div className="form-row">
              <FormGroup
                label={responsibleLabel}
                selectLabel={selectResponsilbleLabel}
                value={formData.responsible}
                onChange={handleDropdownChange("responsible")}
                options={raciDropdownData}
              />
              <FormGroup
                label={accountableLabel}
                selectLabel={selectAccountableLabel}
                value={formData.accountable}
                onChange={handleDropdownChange("accountable")}
                options={raciDropdownData}
              />
            </div>
            <div className="form-row">
              <FormGroup
                label={consultedLabel}
                selectLabel={selectConsultedLabel}
                value={formData.consulted}
                onChange={handleDropdownChange("consulted")}
                options={raciDropdownData}
              />
              <FormGroup
                label={informedLabel}
                selectLabel={selectInformedLabel}
                value={formData.informed}
                onChange={handleDropdownChange("informed")}
                options={raciDropdownData}
              />
            </div>
            <div className="form-row">
              <FormGroup
                label={othersLabel}
                selectLabel={selectOthersLabel}
                value={formData.others}
                onChange={handleDropdownChange("others")}
                options={raciDropdownData}
              />
            </div>
          </MotifCardBody>
        </MotifCard>
      )}
    </>
  );
};

MappingForm.propTypes = {
  responsibleLabel: PropTypes.string.isRequired,
  accountableLabel: PropTypes.string.isRequired,
  consultedLabel: PropTypes.string.isRequired,
  informedLabel: PropTypes.string.isRequired,
  othersLabel: PropTypes.string.isRequired,
  selectResponsilbleLabel: PropTypes.string.isRequired,
  selectAccountableLabel: PropTypes.string.isRequired,
  selectConsultedLabel: PropTypes.string.isRequired,
  selectInformedLabel: PropTypes.string.isRequired,
  selectOthersLabel: PropTypes.string.isRequired,
  RACIMapping: PropTypes.string.isRequired,
  saveButtonLabel: PropTypes.string.isRequired,
  cancelButtonLabel: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  raciDropdownData: PropTypes.array.isRequired,
  initialData: PropTypes.object,
  onChange: PropTypes.func,
  pagenName: PropTypes.string,
};

export default MappingForm;
