import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";
import {
  MotifFormField,
  MotifErrorMessage,
  MotifProgressLoader,
} from "@ey-xd/motif-react";
import { checkAdminUsers } from "../../features/slices/UsersData";
import { useDispatch, useSelector } from "react-redux";
import ErrorModal from "../modals/error/errorModal";

const getCookie = (name) => {
  const regex = new RegExp(`(^| )${name}=([^;]+)`);
  const match = regex.exec(document.cookie);
  return match ? match[2] : null;
};

const MultiPeoplePicker = ({
  getADUsers,
  onChange,
  options,
  disabledPicker = false,
  value = [],
  isErrorVisible = false,
  errorMessage = "",
  isErrorVisible2 = false,
  errorMessage2 = "",
  pageName,
}) => {
  const token =
    useSelector((state) => state.user.jwtToken) ||
    getCookie("authToken") ||
    sessionStorage.getItem("authToken");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorUser, setErrorUser] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (value) {
      setSelectedOptions(value);
    }
  }, [value]);

  const loadOptions = async (inputValue, callback) => {
    if (!inputValue) return callback([]);

    try {
      const data = await getADUsers(inputValue);
      if (Array.isArray(data)) {
        const formattedOptions = data.map((item) => ({
          id: item.id,
          label: item.emailId,
          value: item.displayName,
        }));
        callback(formattedOptions);
      } else {
        console.error("Expected an array but received:", data);
        callback([]);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
      callback([]);
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleNewlyAddedUser = async (newlyAddedUser) => {
    const email = newlyAddedUser.label;

    if (!email || !token) {
      console.error("Email or token is missing.");
      return;
    }

    if (!validateEmail(email)) {
      console.error(`Invalid email format: ${email}`);
      return;
    }

    try {
      setLoading(true);
      const payload = { emailId: email, token };
      const response = await dispatch(checkAdminUsers(payload)).unwrap();

      if (response.success) {
        if (response.message === "User cannot be admin") {
          setErrorUser(newlyAddedUser);
          setIsModalOpen(true);
        } else if (response.message === "User can be admin") {
          setSelectedOptions((prev) => [...prev, newlyAddedUser]);
        }
      } else {
        console.error(`Unexpected response: ${response}`);
      }
    } catch (error) {
      console.error(`Error validating email ${email}:`, error);
    } finally {
      setLoading(false);
    }
  };
  const handleOnChange = async (selectedList) => {
    const selectedArray = Array.isArray(selectedList)
      ? selectedList
      : [selectedList].filter(Boolean);

    const newlyAddedUser = selectedArray.find(
      (user) => !selectedOptions.some((prevUser) => prevUser.id === user.id)
    );

    if (newlyAddedUser) {
      if (pageName === "New Project") {
        await handleNewlyAddedUser(newlyAddedUser);
      } else {
        setSelectedOptions((prev) => [...prev, newlyAddedUser]);
      }
    }
    setSelectedOptions(selectedArray);
    onChange(selectedArray);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    if (errorUser) {
      setSelectedOptions((prev) =>
        prev.filter((user) => user.id !== errorUser.id)
      );
      onChange(selectedOptions.filter((user) => user.id !== errorUser.id));
      setErrorUser(null);
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      background: "#FFFFFF",
      border: "1px solid #C3C3CB",
      borderRadius: "0px",
      color: "#2e2e38",
    }),
    menu: (provided) => ({
      ...provided,
      color: "black",
    }),
    multiValue: (provided) => ({
      ...provided,
      background: "#C3C3CB",
      color: "#656579",
    }),
    input: (provided) => ({
      ...provided,
      color: "#656579",
      fontWeight: 300,
      fontSize: "16px",
      lineHeight: "24px",
    }),
  };

  return (
    <div>
      {loading && (
        <MotifProgressLoader
          data-testid="loading-spinner"
          className="loader"
          show
          variant="default"
        />
      )}
      <div className="MultiselectDiv">
        <MotifFormField disabled={disabledPicker}>
          <AsyncSelect
            cacheOptions
            loadOptions={loadOptions}
            isDisabled={disabledPicker}
            placeholder={
              pageName === "New Project"
                ? "Select project administrator"
                : "Select Email"
            }
            value={selectedOptions}
            isClearable={false}
            onChange={handleOnChange}
            isMulti={pageName === "New Project"}
            isSearchable
            closeMenuOnSelect={false}
            options={options}
            styles={customStyles}
          />
          {isErrorVisible && (
            <MotifErrorMessage show={isErrorVisible}>
              {errorMessage}
            </MotifErrorMessage>
          )}
          {isErrorVisible2 && (
            <p
              style={{
                fontSize: "0.857rem",
                color: "red",
                whiteSpace: "pre-wrap",
                overflowWrap: "break-word",
                marginTop: "0px",
                marginBottom: "0px",
                paddingLeft: "5%",
              }}
            >
              {errorMessage2}
            </p>
          )}
        </MotifFormField>
      </div>
      {isModalOpen && (
        <ErrorModal
          setName="Alert!"
          labelText="The selected user cannot be assigned as an admin."
          deleteButtonLabel="Close"
          handleButtonClick={handleModalClose}
        />
      )}
    </div>
  );
};

MultiPeoplePicker.propTypes = {
  getADUsers: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  disabledPicker: PropTypes.bool,
  value: PropTypes.array,
  isErrorVisible: PropTypes.bool,
  errorMessage: PropTypes.string,
  isErrorVisible2: PropTypes.bool,
  errorMessage2: PropTypes.string,
  pageName: PropTypes.string.isRequired,
};

export default MultiPeoplePicker;
