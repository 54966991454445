import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  GET_ALL_IROs,
  CREATE_IRO,
  UPDATE_IRO,
  DELETE_IRO,
  GET_APS_IRO,
  GET_VALUE_CHAIN_IRO,
  GET_SOURCES_IRO,
  GET_GEOGRAPHIES_IRO,
  GET_STAKEHOLDERS_IRO,
  UPDATE_SOURCES_IRO,
  UPDATE_VALUE_CHAIN_IRO,
  UPDATE_APS_IRO,
  UPDATE_STAKEHOLDERS_IRO,
  UPDATE_GEOGRAPHIES_IRO,
  GET_ALL_SUSTAINABILITY_MATTERS_IRO,
  UPDATE_SUSTAINABILITY_MATTERS_IRO,
  GET_ALL_TIMEFRAME_TYPES,
  GET_ALL_IRO_TYPES,
  GET_ALL_IRO_LIBRARY_SECTORS,
  GET_LIBRARY_IRO_TYPES,
  GET_LIBRARY_IRO_AR16,
  GET_LIBRARY_ITEMS,
  CREATE_SUSTAINABILITY_MATTERS_FROM_LIBRARY,
  GET_LIBRARY_ITEMS_PAGED
} from "../../services/Api";

// Reusable function for making API requests
const apiRequest = async (url, method, data, token, rejectWithValue) => {
  try {
    const response = await axios({
      url,
      method,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(`Error ${method} request:`, error);
    const errorMessage = error.response?.data?.message;

    if (!errorMessage) {
      // No specific error message found
      return rejectWithValue({
        errorMessage:
          error.response?.data?.title ||
          error.message ||
          "Unknown error occurred",
        isGenericError: true,
      });
    } else {
      return rejectWithValue({ errorMessage, isGenericError: false });
    }
  }
};

export const getAllIro = createAsyncThunk(
  "getAllIro",
  async ({ moduleId, token }, { rejectWithValue }) => {
    const url = `${GET_ALL_IROs}?moduleId=${moduleId}`;
    return apiRequest(url, "get", null, token, rejectWithValue);
  }
);

export const createIro = createAsyncThunk(
  "createIro",
  async ({ iroData, token }, { rejectWithValue }) => {
    const url = CREATE_IRO;
    return apiRequest(url, "post", iroData, token, rejectWithValue);
  }
);

export const updateIro = createAsyncThunk(
  "updateIro",
  async ({ iroId, iroData, token }, { rejectWithValue }) => {
    const url = UPDATE_IRO;
    return apiRequest(url, "put", iroData, token, rejectWithValue);
  }
);

export const deleteIro = createAsyncThunk(
  "deleteIro",
  async ({ iroId, token, moduleId }, { rejectWithValue }) => {
    const url = `${DELETE_IRO}?moduleIROId=${iroId}&moduleId=${moduleId}`;
    return apiRequest(url, "delete", null, token, rejectWithValue);
  }
);

export const getSourcesIro = createAsyncThunk(
  "getSourcesIro",
  async ({ iroId, token, moduleId }, { rejectWithValue }) => {
    const url = `${GET_SOURCES_IRO}?moduleIROId=${iroId}&moduleId=${moduleId}`;
    return apiRequest(url, "get", null, token, rejectWithValue);
  }
);

export const getValueChainIro = createAsyncThunk(
  "getValueChainIro",
  async ({ iroId, token, moduleId }, { rejectWithValue }) => {
    const url = `${GET_VALUE_CHAIN_IRO}/${iroId}?moduleId=${moduleId}`;
    return apiRequest(url, "get", null, token, rejectWithValue);
  }
);

export const getApsIro = createAsyncThunk(
  "getApsIro",
  async ({ iroId, token, moduleId }, { rejectWithValue }) => {
    const url = `${GET_APS_IRO}/${iroId}?moduleId=${moduleId}`;
    return apiRequest(url, "get", null, token, rejectWithValue);
  }
);

export const getGeographiesIro = createAsyncThunk(
  "getGeographiesIro",
  async ({ iroId, token, moduleId }, { rejectWithValue }) => {
    const url = `${GET_GEOGRAPHIES_IRO}/${iroId}?moduleId=${moduleId}`;
    return apiRequest(url, "get", null, token, rejectWithValue);
  }
);

export const getStakeholdersIro = createAsyncThunk(
  "getStakeholdersIro",
  async ({ iroId, token, moduleId }, { rejectWithValue }) => {
    const url = `${GET_STAKEHOLDERS_IRO}/${iroId}?moduleId=${moduleId}`;
    return apiRequest(url, "get", null, token, rejectWithValue);
  }
);

export const updateSourcesIro = createAsyncThunk(
  "updateSourcesIro",
  async ({ iroId, iroData, token, moduleId }, { rejectWithValue }) => {
    const url = `${UPDATE_SOURCES_IRO}/${iroId}?moduleId=${moduleId}`;
    return apiRequest(url, "put", iroData, token, rejectWithValue);
  }
);

export const updateValueChainIro = createAsyncThunk(
  "updateValueChainIro",
  async ({ iroId, iroData, token, moduleId }, { rejectWithValue }) => {
    const url = `${UPDATE_VALUE_CHAIN_IRO}/${iroId}?moduleId=${moduleId}`;
    return apiRequest(url, "put", iroData, token, rejectWithValue);
  }
);

export const updateApsIro = createAsyncThunk(
  "updateApsIro",
  async ({ iroId, iroData, token, moduleId }, { rejectWithValue }) => {
    const url = `${UPDATE_APS_IRO}/${iroId}?moduleId=${moduleId}`;
    return apiRequest(url, "put", iroData, token, rejectWithValue);
  }
);

export const updateGeographiesIro = createAsyncThunk(
  "updateGeographiesIro",
  async ({ iroId, iroData, token, moduleId }, { rejectWithValue }) => {
    const url = `${UPDATE_GEOGRAPHIES_IRO}/${iroId}?moduleId=${moduleId}`;
    return apiRequest(url, "put", iroData, token, rejectWithValue);
  }
);

export const updateStakeholdersIro = createAsyncThunk(
  "updateStakeholdersIro",
  async ({ iroId, iroData, token, moduleId }, { rejectWithValue }) => {
    const url = `${UPDATE_STAKEHOLDERS_IRO}/${iroId}?moduleId=${moduleId}`;
    return apiRequest(url, "put", iroData, token, rejectWithValue);
  }
);

export const getAllSustainabilityMattersIro = createAsyncThunk(
  "getAllSustainabilityMattersIro",
  async ({ moduleId, iroId, token }, { rejectWithValue }) => {
    const url = `${GET_ALL_SUSTAINABILITY_MATTERS_IRO}/${moduleId}/${iroId}`;
    return apiRequest(url, "get", null, token, rejectWithValue);
  }
);

export const updateSustainabilityMattersIro = createAsyncThunk(
  "updateSustainabilityMattersIro",
  async ({ iroId, iroData, token, moduleId }, { rejectWithValue }) => {
    const url = `${UPDATE_SUSTAINABILITY_MATTERS_IRO}/${iroId}?moduleId=${moduleId}`;
    return apiRequest(url, "put", iroData, token, rejectWithValue);
  }
);

export const getAllTimeframeTypes = createAsyncThunk(
  "getAllTimeframeTypes",
  async ({ token }, { rejectWithValue }) => {
    const url = GET_ALL_TIMEFRAME_TYPES;
    return apiRequest(url, "get", null, token, rejectWithValue);
  }
);

export const getAllIroTypes = createAsyncThunk(
  "getAllIroTypes",
  async ({ token }, { rejectWithValue }) => {
    const url = GET_ALL_IRO_TYPES;
    return apiRequest(url, "get", null, token, rejectWithValue);
  }
);

export const getIROLibrarySectors = createAsyncThunk(
  "getIROLibrarySectors",
  async ({ token }, { rejectWithValue }) => {
    const url = GET_ALL_IRO_LIBRARY_SECTORS;
    return apiRequest(url, "get", null, token, rejectWithValue);
  }
);

export const getIROLibraryTypes = createAsyncThunk(
  "getIROLibraryTypes",
  async ({ token }, { rejectWithValue }) => {
    const url = GET_LIBRARY_IRO_TYPES;
    return apiRequest(url, "get", null, token, rejectWithValue);
  }
);

export const getIROLibraryAr16 = createAsyncThunk(
  "getIROLibraryAr16",
  async ({ token }, { rejectWithValue }) => {
    const url = GET_LIBRARY_IRO_AR16;
    return apiRequest(url, "get", null, token, rejectWithValue);
  }
);

export const getLibraryItems = createAsyncThunk(
  "getLibraryItems",
  async ({ token, sectorId, iroTypeId, esrsAr16Ids }, { rejectWithValue }) => {
    let url = `${GET_LIBRARY_ITEMS}?sectorId=${sectorId}&iroTypeId=${iroTypeId}`;
    if (Array.isArray(esrsAr16Ids) && esrsAr16Ids.length > 0) {
      esrsAr16Ids.forEach((id) => {
        url += `&esrsAr16Ids=${id}`;
      });
    } else {
      url += `&esrsAr16Ids=0`;
    }

    return apiRequest(url, "get", null, token, rejectWithValue);
  }
);

export const fetchLibraryItemsPaged = createAsyncThunk(
  "fetchLibraryItemsPaged",
  async (
    {
      token,
      sectorId,
      iroTypeId,
      esrsAr16Ids,
      pageIndex,
      pageSize,
      filterOperator = "Contains",
      filterValue,
      sortField = "iroTypeName",
      sortDirection = 0,
    },
    { rejectWithValue }
  ) => {
    let url = `${GET_LIBRARY_ITEMS_PAGED}?sectorId=${sectorId}&iroTypeId=${iroTypeId}`;

    if (Array.isArray(esrsAr16Ids) && esrsAr16Ids.length > 0) {
      esrsAr16Ids.forEach((id) => {
        url += `&esrsAr16Ids=${id}`;
      });
    } else {
      url += `&esrsAr16Ids=0`;
    }

    url += `&pageIndex=${pageIndex}&pageSize=${pageSize}&sortField=${sortField}&sortDirection=${sortDirection}`;

    if (filterValue) {
      url += `&filterOperator=${filterOperator}&filterValue=${filterValue}`;
    }

    return apiRequest(url, "get", null, token, rejectWithValue);
  }
);


export const createSustainabilityMatterFromLibrary = createAsyncThunk(
  "createSustainabilityMatterFromLibrary",
  async ({ token, moduleId, data }, { rejectWithValue }) => {
    const url = `${CREATE_SUSTAINABILITY_MATTERS_FROM_LIBRARY}?moduleId=${moduleId}`;
    return apiRequest(url, "post", data, token, rejectWithValue);
  }
);

const initialState = {
  isLoading: false,
  data: null,
  sourcesData: null,
  apsData: null,
  valueChainData: null,
  geographiesData: null,
  stakeholdersData: null,
  iroSustainabilityMattersData: null,
  timeframeTypesData: null,
  iroTypesData: null,
  iroLibraryTypesData: null,
  iroLibrarySectorsData: null,
  iroLibraryAR16Data: null,
  iroLibraryItemsData: null,
  isError: false,
  errorMessage: "",
  updateSuccess: false,
  types: [],
  isGenericError: false,
};

const pendingReducer = (state) => {
  state.isLoading = true;
  state.updateSuccess = false;
  state.isError = false;
  state.errorMessage = "";
  state.isGenericError = false;
};

const fulfilledReducer = (state, action) => {
  state.isLoading = false;
  state.updateSuccess = true;
  state.data = action.payload;
  state.isError = false;
};

const rejectedReducer = (state, action) => {
  state.isLoading = false;
  state.updateSuccess = false;
  state.isError = true;
  state.errorMessage = action.payload.errorMessage;
  state.isGenericError = action.payload.isGenericError || false;
};

const iroSlice = createSlice({
  name: "Iro",
  initialState,
  reducers: {
    clearError(state) {
      state.isError = false;
      state.errorMessage = "";
      state.isGenericError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllIro.pending, pendingReducer)
      .addCase(getAllIro.fulfilled, fulfilledReducer)
      .addCase(getAllIro.rejected, rejectedReducer)
      .addCase(createIro.pending, pendingReducer)
      .addCase(createIro.fulfilled, fulfilledReducer)
      .addCase(createIro.rejected, rejectedReducer)
      .addCase(updateIro.pending, pendingReducer)
      .addCase(updateIro.fulfilled, fulfilledReducer)
      .addCase(updateIro.rejected, rejectedReducer)
      .addCase(deleteIro.pending, pendingReducer)
      .addCase(deleteIro.fulfilled, fulfilledReducer)
      .addCase(deleteIro.rejected, rejectedReducer)
      .addCase(getSourcesIro.pending, pendingReducer)
      .addCase(getSourcesIro.fulfilled, (state, action) => {
        state.isLoading = false;
        state.sourcesData = action.payload.data;
        state.isError = false;
      })
      .addCase(getSourcesIro.rejected, rejectedReducer)
      .addCase(getApsIro.pending, pendingReducer)
      .addCase(getApsIro.fulfilled, (state, action) => {
        state.isLoading = false;
        state.apsData = action.payload.data;
        state.isError = false;
      })
      .addCase(getApsIro.rejected, rejectedReducer)
      .addCase(getValueChainIro.pending, pendingReducer)
      .addCase(getValueChainIro.fulfilled, (state, action) => {
        state.isLoading = false;
        state.valueChainData = action.payload.data;
        state.isError = false;
      })
      .addCase(getValueChainIro.rejected, rejectedReducer)
      .addCase(getGeographiesIro.pending, pendingReducer)
      .addCase(getGeographiesIro.fulfilled, (state, action) => {
        state.isLoading = false;
        state.geographiesData = action.payload.data;
        state.isError = false;
      })
      .addCase(getGeographiesIro.rejected, rejectedReducer)
      .addCase(getStakeholdersIro.pending, pendingReducer)
      .addCase(getStakeholdersIro.fulfilled, (state, action) => {
        state.isLoading = false;
        state.stakeholdersData = action.payload.data;
        state.isError = false;
      })
      .addCase(getStakeholdersIro.rejected, rejectedReducer)
      .addCase(updateSourcesIro.pending, pendingReducer)
      .addCase(updateSourcesIro.fulfilled, (state, action) => {
        state.isLoading = false;
        state.stakeholdersData = action.payload.data;
        state.isError = false;
      })
      .addCase(updateSourcesIro.rejected, rejectedReducer)
      .addCase(updateValueChainIro.pending, pendingReducer)
      .addCase(updateValueChainIro.fulfilled, (state, action) => {
        state.isLoading = false;
        state.stakeholdersData = action.payload.data;
        state.isError = false;
      })
      .addCase(updateValueChainIro.rejected, rejectedReducer)
      .addCase(updateApsIro.pending, pendingReducer)
      .addCase(updateApsIro.fulfilled, (state, action) => {
        state.isLoading = false;
        state.stakeholdersData = action.payload.data;
        state.isError = false;
      })
      .addCase(updateApsIro.rejected, rejectedReducer)
      .addCase(updateGeographiesIro.pending, pendingReducer)
      .addCase(updateGeographiesIro.fulfilled, (state, action) => {
        state.isLoading = false;
        state.stakeholdersData = action.payload.data;
        state.isError = false;
      })
      .addCase(updateGeographiesIro.rejected, rejectedReducer)
      .addCase(updateStakeholdersIro.pending, pendingReducer)
      .addCase(updateStakeholdersIro.fulfilled, (state, action) => {
        state.isLoading = false;
        state.stakeholdersData = action.payload.data;
        state.isError = false;
      })
      .addCase(updateStakeholdersIro.rejected, rejectedReducer)
      .addCase(getAllSustainabilityMattersIro.pending, pendingReducer)
      .addCase(getAllSustainabilityMattersIro.fulfilled, (state, action) => {
        state.isLoading = false;
        state.iroSustainabilityMattersData = action.payload.data;
        state.isError = false;
      })
      .addCase(getAllSustainabilityMattersIro.rejected, rejectedReducer)
      .addCase(updateSustainabilityMattersIro.pending, pendingReducer)
      .addCase(updateSustainabilityMattersIro.fulfilled, (state, action) => {
        state.isLoading = false;
        state.iroSustainabilityMattersData = action.payload.data;
        state.isError = false;
      })
      .addCase(updateSustainabilityMattersIro.rejected, rejectedReducer)
      .addCase(getAllTimeframeTypes.pending, pendingReducer)
      .addCase(getAllTimeframeTypes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.timeframeTypesData = action.payload.data;
        state.isError = false;
      })
      .addCase(getAllTimeframeTypes.rejected, rejectedReducer)
      .addCase(getAllIroTypes.pending, pendingReducer)
      .addCase(getAllIroTypes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.iroTypesData = action.payload.data;
        state.isError = false;
      })
      .addCase(getAllIroTypes.rejected, rejectedReducer)
      .addCase(getIROLibrarySectors.pending, pendingReducer)
      .addCase(getIROLibrarySectors.fulfilled, (state, action) => {
        state.isLoading = false;
        state.iroLibrarySectorsData = action.payload.data;
        state.isError = false;
      })
      .addCase(getIROLibrarySectors.rejected, rejectedReducer)
      .addCase(getIROLibraryTypes.pending, pendingReducer)
      .addCase(getIROLibraryTypes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.iroLibraryTypesData = action.payload.data;
        state.isError = false;
      })
      .addCase(getIROLibraryTypes.rejected, rejectedReducer)
      .addCase(getIROLibraryAr16.pending, pendingReducer)
      .addCase(getIROLibraryAr16.fulfilled, (state, action) => {
        state.isLoading = false;
        state.iroLibraryAR16Data = action.payload.data;
        state.isError = false;
      })
      .addCase(getIROLibraryAr16.rejected, rejectedReducer)
      .addCase(getLibraryItems.pending, pendingReducer)
      .addCase(getLibraryItems.fulfilled, (state, action) => {
        state.isLoading = false;
        state.iroLibraryItemsData = action.payload.data;
        state.isError = false;
      })
      .addCase(getLibraryItems.rejected, rejectedReducer)
      .addCase(fetchLibraryItemsPaged.pending, pendingReducer)
      .addCase(fetchLibraryItemsPaged.fulfilled, (state, action) => {
        state.isLoading = false;
        state.iroLibraryItemsData = action.payload.data;
        state.isError = false;
      })
      .addCase(fetchLibraryItemsPaged.rejected, rejectedReducer)
      .addCase(createSustainabilityMatterFromLibrary.pending, pendingReducer)
      .addCase(
        createSustainabilityMatterFromLibrary.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.iroLibraryItemsData = action.payload.data;
          state.isError = false;
        }
      )
      .addCase(createSustainabilityMatterFromLibrary.rejected, rejectedReducer);
  },
});

export const { clearError } = iroSlice.actions;
export default iroSlice.reducer;