import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  GET_ALL_DGA_SM,
  UPSERT_DGA_SM,
  GET_ALL_DR,
  GET_ALL_DATAPOINT_QUERY,
  UPSERT_DR,
  GET_ALL_MATURITY_LEVEL_DGA,
  UPSERT_DATAPOINT,
  GET_DATA_POINTS_BY_DR_ID,
  GET_DATA_POINT_DETAILS,
  GET_GAUGE_CHART1_IN_SCOPE,
  GET_GAUGE_CHART2_ASSESED,
  GET_GAUGE_CHART3_MATURITY,
} from "../../services/Api";

// Utility function to handle API requests
const apiRequest = async (method, url, data, token, rejectWithValue) => {
  const config = {
    method,
    url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.error("API request error:", error);
    const errorMessage = error.response?.data?.message;

    if (!errorMessage) {
      // No specific error message found
      return rejectWithValue({
        errorMessage:
          error.response?.data?.title ||
          error.message ||
          "Unknown error occurred",
        isGenericError: true,
      });
    } else {
      return rejectWithValue({ errorMessage, isGenericError: false });
    }
  }
};

// Async thunks
export const getDGASustainabilityMatters = createAsyncThunk(
  "dgaSustainabilityMatters/getAll",
  async ({ moduleId, token }, { rejectWithValue }) => {
    return await apiRequest(
      "get",
      `${GET_ALL_DGA_SM}/${moduleId}`,
      null,
      token,
      rejectWithValue
    );
  }
);

export const getDGADisclosureRequirements = createAsyncThunk(
  "getDGADisclosureRequirements/getAll",
  async ({ moduleId, token }, { rejectWithValue }) => {
    return await apiRequest(
      "get",
      `${GET_ALL_DR}/${moduleId}`,
      null,
      token,
      rejectWithValue
    );
  }
);

export const getDGADataPointQuery = createAsyncThunk(
  "dgaDataPointQuery/getAll",
  async ({ moduleId, token }, { rejectWithValue }) => {
    return await apiRequest(
      "get",
      `${GET_ALL_DATAPOINT_QUERY}/${moduleId}`,
      null,
      token,
      rejectWithValue
    );
  }
);

export const getDataPointsByDRId = createAsyncThunk(
  "dataPointsByDRId",
  async ({ moduleId, token, drId }, { rejectWithValue }) => {
    return await apiRequest(
      "get",
      `${GET_DATA_POINTS_BY_DR_ID}/${moduleId}/${drId}`,
      null,
      token,
      rejectWithValue
    );
  }
);

export const getDataPointsDetails = createAsyncThunk(
  "dataPointsDetails",
  async ({ moduleId, token, dpId }, { rejectWithValue }) => {
    return await apiRequest(
      "get",
      `${GET_DATA_POINT_DETAILS}/${moduleId}/${dpId}`,
      null,
      token,
      rejectWithValue
    );
  }
);

export const upsertDGASustainabilityMatters = createAsyncThunk(
  "dgaSustainabilityMatters/upsert",
  async ({ sustainabilityMattersData, token }, { rejectWithValue }) => {
    return await apiRequest(
      "post",
      UPSERT_DGA_SM,
      sustainabilityMattersData,
      token,
      rejectWithValue
    );
  }
);

export const upsertDisclosureData = createAsyncThunk(
  "dgaDisclosure/upsert",
  async ({ disclosureData, token }, { rejectWithValue }) => {
    return await apiRequest(
      "post",
      UPSERT_DR,
      disclosureData,
      token,
      rejectWithValue
    );
  }
);
// =================================Assessment======================================================

export const getMaturityLevels = createAsyncThunk(
  "dgaMaturityLevels/getAll",
  async ({ token }, { rejectWithValue }) => {
    return await apiRequest(
      "get",
      GET_ALL_MATURITY_LEVEL_DGA,
      null,
      token,
      rejectWithValue
    );
  }
);

export const upsertDataPoint = createAsyncThunk(
  "dgaDatapoint/upsert",
  async ({ datapointData, token }, { rejectWithValue }) => {
    return await apiRequest(
      "post",
      UPSERT_DATAPOINT,
      datapointData,
      token,
      rejectWithValue
    );
  }
);

// ==================================Dashboard=================================================

export const getChart1InScope = createAsyncThunk(
  "chart1InScope/getAll",
  async ({ moduleId, token }, { rejectWithValue }) => {
    return await apiRequest(
      "get",
      `${GET_GAUGE_CHART1_IN_SCOPE}/${moduleId}`,
      null,
      token,
      rejectWithValue
    );
  }
);

export const getChart2Assessed = createAsyncThunk(
  "chart2Assessed/getAll",
  async ({ moduleId, token }, { rejectWithValue }) => {
    return await apiRequest(
      "get",
      `${GET_GAUGE_CHART2_ASSESED}/${moduleId}`,
      null,
      token,
      rejectWithValue
    );
  }
);
export const getChart3Maturity = createAsyncThunk(
  "chart3Maturity/getAll",
  async ({ moduleId, token }, { rejectWithValue }) => {
    return await apiRequest(
      "get",
      `${GET_GAUGE_CHART3_MATURITY}/${moduleId}`,
      null,
      token,
      rejectWithValue
    );
  }
);

// Initial state
const initialState = {
  isLoading: false,
  data: null,
  isError: false,
  errorMessage: "",
  updateSuccess: false,
  postSuccess: false,
  isGenericError: false,
  types: [],
};

// Common reducer functions
const handlePending = (state) => {
  state.isLoading = true;
  state.updateSuccess = false;
  state.postSuccess = false;
  state.isError = false;
  state.errorMessage = "";
};

const handleFulfilled = (state, action) => {
  state.isLoading = false;
  state.updateSuccess = true;
  state.postSuccess = true;
  state.data = action.payload;
  state.isError = false;
};

const handleRejected = (state, action) => {
  state.isLoading = false;
  state.updateSuccess = false;
  state.postSuccess = false;
  state.isError = true;
  state.errorMessage = action.payload.errorMessage;
  state.isGenericError = action.payload.isGenericError || false;
};

// Slice definition
const dgaSustainabilityMattersSlice = createSlice({
  name: "dgaSustainabilityMatters",
  initialState,
  reducers: {
    clearError(state) {
      state.isError = false;
      state.errorMessage = "";
      state.isGenericError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDGASustainabilityMatters.pending, handlePending)
      .addCase(getDGASustainabilityMatters.fulfilled, handleFulfilled)
      .addCase(getDGASustainabilityMatters.rejected, handleRejected)
      .addCase(getDGADisclosureRequirements.pending, handlePending)
      .addCase(getDGADisclosureRequirements.fulfilled, handleFulfilled)
      .addCase(getDGADisclosureRequirements.rejected, handleRejected)
      .addCase(getDGADataPointQuery.pending, handlePending)
      .addCase(getDGADataPointQuery.fulfilled, handleFulfilled)
      .addCase(getDGADataPointQuery.rejected, handleRejected)
      .addCase(getDataPointsByDRId.pending, handlePending)
      .addCase(getDataPointsByDRId.fulfilled, handleFulfilled)
      .addCase(getDataPointsByDRId.rejected, handleRejected)
      .addCase(getDataPointsDetails.pending, handlePending)
      .addCase(getDataPointsDetails.fulfilled, handleFulfilled)
      .addCase(getDataPointsDetails.rejected, handleRejected)
      .addCase(upsertDGASustainabilityMatters.pending, handlePending)
      .addCase(upsertDGASustainabilityMatters.fulfilled, handleFulfilled)
      .addCase(upsertDGASustainabilityMatters.rejected, handleRejected)
      .addCase(upsertDisclosureData.pending, handlePending)
      .addCase(upsertDisclosureData.fulfilled, handleFulfilled)
      .addCase(upsertDisclosureData.rejected, handleRejected)
      .addCase(getMaturityLevels.pending, handlePending)
      .addCase(getMaturityLevels.fulfilled, handleFulfilled)
      .addCase(getMaturityLevels.rejected, handleRejected)
      .addCase(upsertDataPoint.pending, handlePending)
      .addCase(upsertDataPoint.fulfilled, handleFulfilled)
      .addCase(upsertDataPoint.rejected, handleRejected)
      .addCase(getChart1InScope.pending, handlePending)
      .addCase(getChart1InScope.fulfilled, handleFulfilled)
      .addCase(getChart1InScope.rejected, handleRejected)
      .addCase(getChart2Assessed.pending, handlePending)
      .addCase(getChart2Assessed.fulfilled, handleFulfilled)
      .addCase(getChart2Assessed.rejected, handleRejected)
      .addCase(getChart3Maturity.pending, handlePending)
      .addCase(getChart3Maturity.fulfilled, handleFulfilled)
      .addCase(getChart3Maturity.rejected, handleRejected);
  },
});

export const { clearError } = dgaSustainabilityMattersSlice.actions;
export default dgaSustainabilityMattersSlice.reducer;
