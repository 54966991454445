import React, { useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";

const SunburstChart = ({
  data,
  width = 700,
  height = 700,
  colorScheme = d3.interpolateRainbow,
}) => {
  const chartRef = useRef();
  const tooltipRef = useRef();

  const drawChart = useCallback(() => {
    d3.select(chartRef.current).selectAll("*").remove();
    const radius = width / 6;
    const color = d3.scaleOrdinal(
      d3.quantize(colorScheme, (data.children ? data.children.length : 0) + 1)
    );

    const tooltip = d3
      .select(tooltipRef.current)
      .style("position", "absolute")
      .style("pointer-events", "none")
      .style("opacity", 0)
      .style("background", "white")
      .style("padding", "8px")
      .style("border-radius", "4px")
      .style("box-shadow", "0 2px 4px rgba(0,0,0,0.2)")
      .style("font-family", "sans-serif")
      .style("font-size", "12px");

    const hierarchy = d3
      .hierarchy(data)
      .sum((d) => d.value || 0)
      .sort((a, b) => b.value - a.value);

    const root = d3.partition().size([2 * Math.PI, hierarchy.height + 1])(
      hierarchy
    );
    root.each((d) => (d.current = d));

    const getNodeValue = (node) => {
      if (node.children) {
        let sum = 0;
        node.each((child) => {
          if (!child.children) {
            sum += child.data.originalValue || child.data.value;
          }
        });
        return sum;
      }
      return node.data.originalValue || node.data.value;
    };

    const arc = d3
      .arc()
      .startAngle((d) => d.x0)
      .endAngle((d) => d.x1)
      .padAngle((d) => Math.min((d.x1 - d.x0) / 2, 0.005))
      .padRadius(radius * 1.5)
      .innerRadius((d) => d.y0 * radius)
      .outerRadius((d) => Math.max(d.y0 * radius, d.y1 * radius - 1));

    const svg = d3
      .select(chartRef.current)
      .attr("viewBox", [-width / 2, -height / 2, width, width])
      .style("font", "10px sans-serif");

    const path = svg
      .append("g")
      .selectAll("path")
      .data(root.descendants().slice(1))
      .join("path")
      .attr("fill", (d) => {
        if (d.data.totalIROAssessments === 0) {
          return "#AFAEBA";
        }
        while (d.depth > 1) d = d.parent;
        return color(d.data.name);
      })
      .attr("fill-opacity", (d) =>
        arcVisible(d.current) ? (d.children ? 0.6 : 0.4) : 0
      )
      .attr("pointer-events", (d) => (arcVisible(d.current) ? "auto" : "none"))
      .attr("d", (d) => arc(d.current))
      .on("mouseover", (event, d) => {
        tooltip.style("opacity", 1);
        const value = getNodeValue(d);
        tooltip
          .html(
            `
            <div>
              <strong>Hierarchy:</strong> ${getHierarchyPath(d)}<br/>
              <strong>Total IRO Assessments:</strong> ${
                d.data.totalIROAssessments || "0"
              }<br/>
                <strong>Total ESRS IRO Count</strong> ${
                  d.data.totalESRSIROCount || "0"
                }<br/>
              <strong>Max Assessment Score:</strong> ${
                d.data.maxAssessmentScore || "N/A"
              }<br/>
              <strong>Has Material Assessment:</strong> ${
                d.data.hasMaterialAssessment ? "Yes" : "No"
              }
            </div>
            `
          )
          .style("left", `${event.pageX + 15}px`)
          .style("top", `${event.pageY - 28}px`);
      })
      .on("mousemove", (event) => {
        tooltip
          .style("left", `${event.pageX + 15}px`)
          .style("top", `${event.pageY - 28}px`);
      })
      .on("mouseout", () => tooltip.style("opacity", 0));

    path
      .filter((d) => d.children)
      .style("cursor", "pointer")
      .on("click", clicked);

    const label = svg
      .append("g")
      .attr("pointer-events", "none")
      .attr("text-anchor", "middle")
      .style("user-select", "none")
      .selectAll("text")
      .data(root.descendants().slice(1))
      .join("text")
      .attr("dy", "0.35em")
      .attr("fill-opacity", (d) => +labelVisible(d.current))
      .attr("transform", (d) => labelTransform(d.current))
      .text((d) => truncateText(d.data.name, 10))
      .style("font-size", (d) => `${Math.min(14, d.y1 - d.y0) * 8}px`);

    const parent = svg
      .append("circle")
      .datum(root)
      .attr("r", radius)
      .attr("fill", "none")
      .attr("pointer-events", "all")
      .on("click", clicked);

    function clicked(event, p) {
      parent.datum(p.parent || root);
      root.each(
        (d) =>
          (d.target = {
            x0:
              Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) *
              2 *
              Math.PI,
            x1:
              Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) *
              2 *
              Math.PI,
            y0: Math.max(0, d.y0 - p.depth),
            y1: Math.max(0, d.y1 - p.depth),
          })
      );

      const t = svg.transition().duration(750);
      path
        .transition(t)
        .tween("data", (d) => {
          const i = d3.interpolate(d.current, d.target);
          return (t) => (d.current = i(t));
        })
        .attr("fill-opacity", (d) =>
          arcVisible(d.target) ? (d.children ? 0.6 : 0.4) : 0
        )
        .attr("pointer-events", (d) => (arcVisible(d.target) ? "auto" : "none"))
        .attrTween("d", (d) => () => arc(d.current));

      label
        .transition(t)
        .attr("fill-opacity", (d) => +labelVisible(d.target))
        .attrTween("transform", (d) => () => labelTransform(d.current));
    }

    function arcVisible(d) {
      return d.y1 <= 3 && d.y0 >= 0 && d.x1 > d.x0;
    }

    function labelVisible(d) {
      return d.y1 <= 3 && d.y0 >= 0 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.01;
    }

    function labelTransform(d) {
      const x = (((d.x0 + d.x1) / 2) * 180) / Math.PI;
      const y = ((d.y0 + d.y1) / 2) * radius;
      return `rotate(${x - 90}) translate(${y},0) rotate(${x < 180 ? 0 : 180})`;
    }

    function truncateText(text, maxChars) {
      return text.length > maxChars
        ? text.substring(0, maxChars) + "..."
        : text;
    }

    function getHierarchyPath(d) {
      return d
        .ancestors()
        .reverse()
        .slice(1)
        .map((ancestor) => ancestor.data.name)
        .join(" ➔ ");
    }
  }, [data, width, height, colorScheme]);

  useEffect(() => {
    if (data) drawChart();
  }, [data, drawChart]);

  return (
    <>
      <svg ref={chartRef} />
      <div ref={tooltipRef} className="sunburst-tooltip" />
    </>
  );
};

SunburstChart.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        value: PropTypes.number,
        originalValue: PropTypes.number,
        children: PropTypes.array,
      })
    ),
  }).isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  colorScheme: PropTypes.func,
};

export default SunburstChart;
