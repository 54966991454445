import React, { useState, useEffect } from "react";
import {
  MotifButton,
  MotifInput,
  MotifFormField,
  MotifMessage,
  MotifErrorMessage,
  MotifLabel,
} from "@ey-xd/motif-react";
import PropTypes from "prop-types";
import "./DmaModal.scss";
import { useTranslation } from "react-i18next";
import ErrorModal from "../error/errorModal";
import TabContent from "./TabContent";

const ScoreModal = ({
  onClose,
  modalMode,
  modalTitle,
  DefinitionPositiveLabel,
  DefinitionNegativeLabel,
  topicNameLabel,
  enterTopicNameLabel,
  saveButtonLabel,
  updateButtonLabel,
  cancelButtonLabel,
  ratingLabel,
  initialData,
  onSubmit,
  disable,
  InputRating,
  onDeleteClick,
  DeleteLabel,
}) => {
  const isEditMode = modalMode === "edit";
  const [selectedTab, setSelectedTab] = useState("Scale");
  const [ratingInput, setRatingInput] = useState("");
  const [formData, setFormData] = useState({
    rating: 0,
    scopeLabel: "",
    scopeDefinitionPositive: "",
    scopeDefinitionNegative: "",
    scaleLabel: "",
    scaleDefinitionPositive: "",
    scaleDefinitionNegative: "",
    remediabilityLabel: "",
    remediabilityDefinitionPositive: "",
    remediabilityDefinitionNegative: "",
    magnitudeLabel: "",
    magnitudeDefinitionPositive: "",
    magnitudeDefinitionNegative: "",
  });

  const [formErrors, setFormErrors] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    if (isEditMode && initialData) {
      setFormData(initialData);
      setRatingInput(initialData.rating);
    }
  }, [isEditMode, initialData]);

  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
    if (formErrors[`${field}Error`]) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [`${field}Error`]: "",
      }));
    }
  };

  const handleRatingChange = (e) => {
    const value = e.target.value;
    if (!/^\d*$/.test(value)) {
      return;
    }
    if (value === "") {
      setRatingInput("");
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        ratingError: "",
      }));
      return;
    }
    const ratingValue = parseInt(value, 10);
    if (ratingValue >= 0 && ratingValue <= 100) {
      setRatingInput(value);
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        ratingError: "",
      }));
    } else if (value !== "") {
      <ErrorModal
        setName="Rating Error"
        labelText="Rating should be between 0 and 100"
        deleteButtonLabel="OK"
        handleButtonClick={onClose}
      />;
    }
  };

  const renderTabContent = () => {
    const tabContentProps = {
      topicNameLabel,
      enterTopicNameLabel,
      handleInputChange,
      DefinitionPositiveLabel,
      DefinitionNegativeLabel,
      formErrors,
    };

    switch (selectedTab) {
      case "Scale":
        return (
          <TabContent
            {...tabContentProps}
            topicName={formData.scaleLabel}
            definitionPositive={formData.scaleDefinitionPositive}
            definitionNegative={formData.scaleDefinitionNegative}
            fieldPrefix="scale"
          />
        );
      case "Scope":
        return (
          <TabContent
            {...tabContentProps}
            topicName={formData.scopeLabel}
            definitionPositive={formData.scopeDefinitionPositive}
            definitionNegative={formData.scopeDefinitionNegative}
            fieldPrefix="scope"
          />
        );
      case "Remediability":
        return (
          <TabContent
            {...tabContentProps}
            topicName={formData.remediabilityLabel}
            definitionPositive={formData.remediabilityDefinitionPositive}
            definitionNegative={formData.remediabilityDefinitionNegative}
            fieldPrefix="remediability"
          />
        );
      case "Financial magnitude":
        return (
          <TabContent
            {...tabContentProps}
            topicName={formData.magnitudeLabel}
            definitionPositive={formData.magnitudeDefinitionPositive}
            definitionNegative={formData.magnitudeDefinitionNegative}
            fieldPrefix="magnitude"
          />
        );
      default:
        return null;
    }
  };

  const validateFields = () => {
    let isValid = true;
    let errors = {};
    let firstErrorTab = null;

    // Validate rating
    if (!ratingInput) {
      errors.ratingError = "Rating is required";
      isValid = false;
    }

    // Define all tab fields
    const allFields = {
      Scale: [
        "scaleLabel",
        "scaleDefinitionPositive",
        "scaleDefinitionNegative",
      ],
      Scope: [
        "scopeLabel",
        "scopeDefinitionPositive",
        "scopeDefinitionNegative",
      ],
      Remediability: [
        "remediabilityLabel",
        "remediabilityDefinitionPositive",
        "remediabilityDefinitionNegative",
      ],
      "Financial magnitude": [
        "magnitudeLabel",
        "magnitudeDefinitionPositive",
        "magnitudeDefinitionNegative",
      ],
    };

    // Validate all fields in all tabs
    for (const [tabName, fields] of Object.entries(allFields)) {
      for (const field of fields) {
        if (!formData[field]) {
          errors[`${field}Error`] = "This field is required";
          isValid = false;

          // Capture the first tab with an error
          if (!firstErrorTab) {
            firstErrorTab = tabName;
          }
        }
      }
    }

    setFormErrors(errors);

    // Switch to the first tab with an error
    if (firstErrorTab) {
      setSelectedTab(firstErrorTab);
    }

    return isValid;
  };

  const handleSubmit = () => {
    if (validateFields()) {
      const finalData = {
        ...formData,
        rating: parseInt(ratingInput, 10),
      };
      onSubmit(finalData);
    }
  };

  return (
    <div className="modal-overlay">
      <div className="sustain-modal modal">
        <div className="modal-header">
          <h2>{modalTitle}</h2>
        </div>
        <div className="rating-div">
          <div className="rating-input-div">
            <div className="form-row">
              <div className="form-group">
                <MotifMessage
                  data-testid="topic-name"
                  className="rating-div-topic"
                >
                  {ratingLabel}*
                </MotifMessage>
                <MotifFormField>
                  <MotifLabel
                    id="select-dma-name-input-label"
                    position="in"
                    htmlFor="name-input"
                  >
                    {InputRating}
                  </MotifLabel>
                  <MotifInput
                    className="rating-input"
                    labelPosition="in"
                    aria-describedby="select-dma-name-input-label"
                    hideClearButton={true}
                    value={ratingInput}
                    onChange={handleRatingChange}
                  />
                </MotifFormField>
                {formErrors.ratingError && (
                  <MotifErrorMessage>
                    {formErrors.ratingError}
                  </MotifErrorMessage>
                )}
              </div>
            </div>
          </div>
          <div className="description-div">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </p>
          </div>
        </div>
        <div className="modal-body">
          <div className="modal-sidebar-score">
            <ul>
              <li>
                <button
                  type="button"
                  className={`sidebar-item ${
                    selectedTab === "Scale" ? "active" : ""
                  }`}
                  onClick={() => setSelectedTab("Scale")}
                >
                  {t("Scale")}
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className={`sidebar-item ${
                    selectedTab === "Scope" ? "active" : ""
                  }`}
                  onClick={() => setSelectedTab("Scope")}
                >
                  {t("Scope")}
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className={`sidebar-item ${
                    selectedTab === "Remediability" ? "active" : ""
                  }`}
                  onClick={() => setSelectedTab("Remediability")}
                >
                  {t("Remediability")}
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className={`sidebar-item ${
                    selectedTab === "Financial magnitude" ? "active" : ""
                  }`}
                  onClick={() => setSelectedTab("Financial magnitude")}
                >
                  {t("Financial magnitude")}
                </button>
              </li>
            </ul>
          </div>
          <div className="modal-content">{renderTabContent()}</div>
        </div>
        <div className="dma-modal-footer">
          <div className="left-buttons">
            {isEditMode && (
              <MotifButton
                className="modal-delete-button"
                onClick={onDeleteClick}
              >
                {DeleteLabel}
              </MotifButton>
            )}
          </div>
          <div className="right-buttons">
            <MotifButton onClick={onClose} className="modal-cancel-button">
              {cancelButtonLabel}
            </MotifButton>
            <MotifButton
              onClick={handleSubmit}
              className="modal-done-button"
              disabled={disable}
            >
              {isEditMode ? updateButtonLabel : saveButtonLabel}
            </MotifButton>
          </div>
        </div>
      </div>
    </div>
  );
};

ScoreModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  modalMode: PropTypes.string.isRequired,
  modalTitle: PropTypes.string.isRequired,
  topicNameLabel: PropTypes.string.isRequired,
  DefinitionPositiveLabel: PropTypes.string.isRequired,
  DefinitionNegativeLabel: PropTypes.string.isRequired,
  enterTopicNameLabel: PropTypes.string.isRequired,
  saveButtonLabel: PropTypes.string.isRequired,
  updateButtonLabel: PropTypes.string.isRequired,
  cancelButtonLabel: PropTypes.string.isRequired,
  ratingLabel: PropTypes.string.isRequired,
  initialData: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  InputRating: PropTypes.string.isRequired,
  disable: PropTypes.bool,
  DeleteLabel: PropTypes.string.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
};

export default ScoreModal;
