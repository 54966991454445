import React, { useState, useEffect } from "react";
import {
  MotifLabel,
  MotifButton,
  MotifInput,
  MotifFormField,
  MotifSelect,
  MotifOption,
  MotifErrorMessage,
  MotifMessage,
} from "@ey-xd/motif-react";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";
import "./DmaModal.scss";

const SourceValue = ({
  onClose,
  setName,
  handleSourceValueClick,
  modalMode,
  NameLabel,
  TypeLabel,
  DescriptionLabel,
  cancelButtonLabel,
  saveButtonLabel,
  updateButtonLabel,
  types,
  selectedRow,
  pageName,
  onDeleteClick,
  DeleteLabel,
  isDisabled,
  InputName,
  SelectType,
}) => {
  const [nameInput, setNameInput] = useState("");
  const [typeId, setTypeId] = useState("");
  const [description, setDescription] = useState("");
  const [errors, setErrors] = useState({});
  const [descriptionError, setDescriptionError] = useState("");

  const MAX_CHAR_LIMIT = 3000;
// Use effect Changes
  useEffect(() => {
    if (modalMode === "edit" && selectedRow) {
      setNameInput(selectedRow.name);
      let typeIdValue = "";
      if (pageName === "Sources") {
        typeIdValue = selectedRow.sourceType.id.toString();
      } else if (pageName === "Value Chain") {
        typeIdValue = selectedRow.valueChainType.id.toString();
      } else if (pageName === "APS") {
        typeIdValue = selectedRow.actProServiceType.id.toString();
      }
      setTypeId(typeIdValue);
      setDescription(selectedRow.description);
    }
  }, [modalMode, selectedRow, pageName]);

  const isEditMode = modalMode === "edit";

  const validateFields = () => {
    const newErrors = {};
    if (!nameInput) newErrors.nameInput = "Name is required";
    if (!typeId) newErrors.typeId = "Type is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateDescription = () => {
    if ((description?.length ?? 0) > MAX_CHAR_LIMIT) {
      setDescriptionError(
        `Description should not exceed ${MAX_CHAR_LIMIT} characters`
      );
      return false;
    } else {
      setDescriptionError("");
      return true;
    }
  };

  const handleSaveClick = () => {
    if (!validateFields() || !validateDescription()) return;
    const sourcesData = {
      name: nameInput,
      description,
      typeId: parseInt(typeId, 10),
    };
    handleSourceValueClick(sourcesData);
  };

  const handleNameChange = (e) => {
    setNameInput(e.target.value);
    if (errors.nameInput) {
      setErrors((prevErrors) => ({ ...prevErrors, nameInput: undefined }));
    }
  };

  const handleTypeChange = (e) => {
    setTypeId(e);
    if (errors.typeId) {
      setErrors((prevErrors) => ({ ...prevErrors, typeId: undefined }));
    }
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
    validateDescription();
  };

  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-header">
          <h2>{setName}</h2>
        </div>
        <div className="modal-body">
          <div className="form-row">
            <div className="form-group">
              <MotifMessage id="form-title"> {NameLabel}*</MotifMessage>
              <MotifFormField>
                <MotifLabel
                  id="select-dma-name-input-label"
                  position="in"
                  htmlFor="name-input"
                >
                  {InputName}
                </MotifLabel>
                <MotifInput
                  data-testid="name-input"
                  className="session-modal-input session-name-input"
                  labelPosition="in"
                  aria-describedby="select-dma-name-input-label"
                  hideClearButton={true}
                  value={nameInput}
                  onChange={handleNameChange}
                />
                {errors.nameInput && (
                  <MotifErrorMessage>{errors.nameInput}</MotifErrorMessage>
                )}
              </MotifFormField>
            </div>
            <div className="form-group">
              <MotifMessage id="form-title"> {TypeLabel}*</MotifMessage>
              <MotifFormField>
                <MotifLabel id="select-dma-select-label" position="in">
                  {SelectType}
                </MotifLabel>
                <MotifSelect
                  className="session-modal-input"
                  ariaLabelledBy="select-dma-select-label"
                  labelPosition="in"
                  visibleOptions={3}
                  onChange={handleTypeChange}
                  value={typeId}
                >
                  {types?.map((type) => (
                    <MotifOption key={type.id} value={type.id.toString()}>
                      {type.name}
                    </MotifOption>
                  ))}
                </MotifSelect>
                {errors.typeId && (
                  <MotifErrorMessage>{errors.typeId}</MotifErrorMessage>
                )}
              </MotifFormField>
            </div>
          </div>
          <MotifMessage htmlFor="description-input">
            {DescriptionLabel}
          </MotifMessage>
          <TextField
            id="description-input"
            data-testid="description-input"
            placeholder=""
            multiline
            fullWidth
            rows={4}
            value={description}
            onChange={handleDescriptionChange}
            error={!!descriptionError}
          />
          {descriptionError && (
            <MotifErrorMessage>{descriptionError}</MotifErrorMessage>
          )}
        </div>
        <div className="dma-modal-footer">
          <div className="left-buttons">
            {isEditMode && (
              <MotifButton
                className="modal-delete-button"
                onClick={onDeleteClick}
              >
                {DeleteLabel}
              </MotifButton>
            )}
          </div>
          <div className="right-buttons">
            <MotifButton onClick={onClose} className="modal-cancel-button">
              {cancelButtonLabel}
            </MotifButton>
            <MotifButton
              onClick={handleSaveClick}
              className="modal-done-button"
              disabled={isDisabled}
            >
              {isEditMode ? updateButtonLabel : saveButtonLabel}
            </MotifButton>
          </div>
        </div>
      </div>
    </div>
  );
};

SourceValue.propTypes = {
  onClose: PropTypes.func.isRequired,
  setName: PropTypes.string.isRequired,
  handleSourceValueClick: PropTypes.func.isRequired,
  modalMode: PropTypes.string.isRequired,
  NameLabel: PropTypes.string.isRequired,
  TypeLabel: PropTypes.string.isRequired,
  DescriptionLabel: PropTypes.string.isRequired,
  cancelButtonLabel: PropTypes.string.isRequired,
  saveButtonLabel: PropTypes.string.isRequired,
  updateButtonLabel: PropTypes.string.isRequired,
  DeleteLabel: PropTypes.string.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  pageName: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  InputName: PropTypes.string.isRequired,
  SelectType: PropTypes.string.isRequired,
  types: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedRow: PropTypes.object,
};

export default SourceValue;
