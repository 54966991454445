import React from "react";
import ContextSubHeader from "../../../components/contextSubHeader/ContextSubHeader";
import ContextStep from "../../../components/contextStep/ContextStep";
import Stakeholders from "./stakeHolders/Stakeholders";
import ValueChain from "./valueChain/ValueChain";
import Sources from "./sources/Sources";
import GeographicalLocation from "./geographicalLocation/GeographicalLocation";
import Aps from "./aps/APS";
import SustainabillityMatters from "./sustainabillityMatters/SustainabillityMatters";
//import DMAIroOverview from "../dmaIROAssessment/DMAIroOverview";
import DMAIroOverviewPagination from "../dmaIROAssessment/DMAIroOverviewPagination";
import PropTypes from "prop-types";
import KbaInfoIcon from "../../../components/KbaInfoIcon/KbaInfoIcon";
import { KBA_IDS } from "../../../constants/KBAIds/KBAIds";
import { useSelector } from "react-redux";

const getCookie = (name) => {
  const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
  return match ? match[2] : null;
};

const DmaContext = ({
  activeTab,
  setActiveTab,
  setAssessmentIroId,
  navigateToAssessment,
}) => {
  const token =
    useSelector((state) => state.user.jwtToken) ||
    getCookie("authToken") ||
    sessionStorage.getItem("authToken");
  return (
    <div className="context-container">
      <ContextSubHeader activeTab={activeTab} setActiveTab={setActiveTab}>
        <ContextStep
          key="sources"
          text="Sources"
          icon={
            <KbaInfoIcon
              kbaId={KBA_IDS.SOURCES}
              language={sessionStorage.getItem("language") || "EN"}
              token={token}
            />
          }
          isActive={activeTab === "sources"}
        >
          <Sources />
        </ContextStep>
        <ContextStep
          key="value-chain"
          text="Value chain"
          icon={
            <KbaInfoIcon
              kbaId={KBA_IDS.VALUE_CHAIN}
              language={sessionStorage.getItem("language") || "EN"}
              token={token}
            />
          }
          isActive={activeTab === "Value chain"}
        >
          <ValueChain />
        </ContextStep>
        <ContextStep
          key="activities-product-and-services"
          text="Activities, products and services"
          isActive={activeTab === "Activities, products and services"}
        >
          <Aps />
        </ContextStep>
        <ContextStep
          key="geographical-location"
          text="Geographical location"
          isActive={activeTab === "Geographical location"}
        >
          <GeographicalLocation />
        </ContextStep>
        <ContextStep
          key="stakeholders"
          text="Stakeholders"
          icon={
            <KbaInfoIcon
              kbaId={KBA_IDS.STAKEHOLDER}
              language={sessionStorage.getItem("language") || "EN"}
              token={token}
            />
          }
          isActive={activeTab === "Stakeholders"}
        >
          <Stakeholders />
        </ContextStep>
        <ContextStep
          key="sustainability-matters"
          text="Sustainability matters"
          icon={
            <KbaInfoIcon
              kbaId={KBA_IDS.SUSTAINABILITY_MATTERS}
              language={sessionStorage.getItem("language") || "EN"}
              token={token}
            />
          }
          isActive={activeTab === "Sustainability matters"}
        >
          <SustainabillityMatters />
        </ContextStep>
        <ContextStep
          key="iro-identification"
          text="IRO identification"
          icon={
            <KbaInfoIcon
              kbaId={KBA_IDS.IROS}
              language={sessionStorage.getItem("language") || "EN"}
              token={token}
            />
          }
          isActive={activeTab === "IRO identification"}
        >
          <DMAIroOverviewPagination
            setAssessmentIroId={setAssessmentIroId}
            navigateToAssessment={navigateToAssessment}
          />
        </ContextStep>
      </ContextSubHeader>
    </div>
  );
};

DmaContext.propTypes = {
  activeTab: PropTypes.string,
  setActiveTab: PropTypes.func,
  setAssessmentIroId: PropTypes.func,
  navigateToAssessment: PropTypes.func,
};
export default DmaContext;