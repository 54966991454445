import React, { useState, useEffect, useCallback } from "react";
import {
  MotifLabel,
  MotifSelect,
  MotifOption,
  MotifFormField,
  MotifButton,
  MotifCheckbox,
  MotifMessage,
} from "@ey-xd/motif-react";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { TableCell } from "@mui/material";
import ClientTablePagination from "../../clientTable/ClientTablePagination";
import "./IroModal.scss";

const IroLibraryPaginationModal = ({
  isOpen,
  onClose,
  modalTitle,
  handleSubmit,
  generateRowKey,
  saveButtonLabel,
  cancelButtonLabel,
  disable,
  sectorLabel,
  sectorOptions,
  selectedSector,
  setSelectedSector,
  typeLabel,
  typeOptions,
  selectedType,
  setSelectedType,
  ar16Label,
  ar16Options,
  selectedAr16,
  setSelectedAr16,
  fetchData,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [currentPageRows, setCurrentPageRows] = useState([]);

  useEffect(() => {
    setSelectedRowIds([]);
  }, [selectedSector, selectedType, selectedAr16]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]);

  const stableFetchData = useCallback(
    ({ pageIndex, pageSize, sortField, sortDirection }) => {
      return fetchData({
        pageIndex,
        pageSize,
        sortField,
        sortDirection,
        searchQuery: debouncedSearchQuery,
      });
    },
    [fetchData, debouncedSearchQuery]
  );

  const handlePageDataChange = useCallback((rows) => {
    setCurrentPageRows(rows);
  }, []);

  const handleChangeSector = useCallback(
    (val) => {
      setSelectedSector(val);
    },
    [setSelectedSector]
  );

  const handleChangeType = useCallback(
    (val) => {
      setSelectedType(val);
    },
    [setSelectedType]
  );

  const handleChangeAr16 = useCallback(
    (val) => {
      setSelectedAr16(val);
    },
    [setSelectedAr16]
  );

  if (!isOpen) return null;

  const pageSelectedCount = currentPageRows.filter((row) =>
    selectedRowIds.includes(row.id)
  ).length;

  const allChecked =
    currentPageRows.length > 0 && pageSelectedCount === currentPageRows.length;
  const isIndeterminate =
    pageSelectedCount > 0 && pageSelectedCount < currentPageRows.length;

  // "Select All" toggles only the rows on the current page
  const handleSelectAll = () => {
    if (allChecked) {
      setSelectedRowIds((prev) =>
        prev.filter((id) => !currentPageRows.some((row) => row.id === id))
      );
    } else {
      const pageIds = currentPageRows.map((row) => row.id);
      setSelectedRowIds((prev) => {
        const merged = new Set([...prev, ...pageIds]);
        return Array.from(merged);
      });
    }
  };

  // Single-row selection
  const handleRowSelect = (id) => {
    const isChecked = selectedRowIds.includes(id);
    if (isChecked) {
      setSelectedRowIds((prev) => prev.filter((rowId) => rowId !== id));
    } else {
      setSelectedRowIds((prev) => [...prev, id]);
    }
  };

  // On submit, pass selected IDs up
  const onSubmit = () => {
    handleSubmit(selectedRowIds);
  };

  // Columns config: first is "select" column
  const columns = [
    {
      colName: "select",
      label: (
        <MotifCheckbox
          id="checkbox-parent"
          name="checkbox-parent"
          checked={allChecked}
          indeterminate={isIndeterminate}
          className="iro-library-checkbox"
          onChange={handleSelectAll}
        />
      ),
      showSorting: false,
      width: "7%",
    },
    { colName: "iroTypeName", label: "Type", showSorting: true, width: "8%" },
    { colName: "name", label: "Name", showSorting: true, width: "35%" },
    {
      colName: "description",
      label: "Description",
      showSorting: false,
      width: "50%",
      textAlign: "left",
    },
  ];

  // Render each cell
  const renderTableCell = (colName, value, row, colIndex) => {
    if (colName === "select") {
      const isChecked = selectedRowIds.includes(row.id);
      return (
        <TableCell key={colIndex}>
          <MotifCheckbox
            checked={isChecked}
            id={`checkbox-${row.id}`}
            name={`checkbox-${row.id}`}
            className="iro-library-checkbox"
            onChange={() => handleRowSelect(row.id)}
          />
        </TableCell>
      );
    }
    return <TableCell key={colIndex}>{value}</TableCell>;
  };

  return (
    <div className="modal-overlay">
      <div className="iro-library-modal modal">
        <div className="modal-header">
          <h2>{modalTitle}</h2>
        </div>

        <div className="iro-library-filter-row">
          <div className="iro-library-filter-container">
            {/* Sector filter */}
            <MotifFormField className="filter-by-select sector-filter">
              <MotifLabel id="sector-filter-label" position="in">
                {sectorLabel}
              </MotifLabel>
              <MotifSelect
                className="sector-select"
                ariaLabelledBy="sector-filter-label"
                labelPosition="in"
                value={selectedSector}
                onChange={handleChangeSector}
              >
                {sectorOptions?.map(({ id, name }) => (
                  <MotifOption key={id} value={id.toString()}>
                    {name}
                  </MotifOption>
                ))}
              </MotifSelect>
            </MotifFormField>

            {/* Type filter */}
            <MotifFormField className="filter-by-select type-filter">
              <MotifLabel id="type-filter-label" position="in">
                {typeLabel}
              </MotifLabel>
              <MotifSelect
                className="type-select"
                ariaLabelledBy="type-filter-label"
                labelPosition="in"
                value={selectedType}
                onChange={handleChangeType}
              >
                {typeOptions?.map(({ id, name }) => (
                  <MotifOption key={id} value={id.toString()}>
                    {name}
                  </MotifOption>
                ))}
              </MotifSelect>
            </MotifFormField>

            {/* AR16 filter (multiple) */}
            <MotifFormField className="filter-by-select ar16-filter">
              <MotifLabel id="ar16-filter-label" position="in">
                {ar16Label}
              </MotifLabel>
              <MotifSelect
                className="ar16-select"
                ariaLabelledBy="ar16-filter-label"
                labelPosition="in"
                multiple
                hideClearButton={false}
                value={selectedAr16}
                onChange={handleChangeAr16}
              >
                {ar16Options?.map(({ id, name }) => (
                  <MotifOption key={id} value={id.toString()}>
                    {name}
                  </MotifOption>
                ))}
              </MotifSelect>
            </MotifFormField>
          </div>
        </div>

        {/* SEARCH ROW */}
        <div
          className="search-filter-container"
          style={{ marginBottom: "20px" }}
        >
          <Paper
            component="form"
            className="search-container"
            onSubmit={(e) => e.preventDefault()}
          >
            <IconButton
              type="button"
              className="search-icon-iro"
              aria-label="search"
            >
              <SearchIcon />
            </IconButton>
            <InputBase
              className="search-box-input-iro"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              inputProps={{ "aria-label": "search For" }}
            />
          </Paper>
        </div>

        {/* TABLE SECTION */}
        <div className="iro-table-container">
          <MotifMessage>{selectedRowIds.length} row(s) selected</MotifMessage>

          <ClientTablePagination
            columns={columns}
            renderTableCell={renderTableCell}
            generateRowKey={generateRowKey}
            fetchData={stableFetchData}
            onPageDataChange={handlePageDataChange}
            defaultSortField="iroTypeName"
            defaultSortDirection={0} // e.g., 0 for ascending
          />
        </div>

        {/* FOOTER */}
        <div className="modal-footer">
          <div className="right-buttons">
            <MotifButton onClick={onClose} className="modal-cancel-button">
              {cancelButtonLabel}
            </MotifButton>
            <MotifButton
              onClick={onSubmit}
              disabled={selectedRowIds.length === 0 || disable}
              className="modal-done-button"
            >
              {saveButtonLabel}
            </MotifButton>
          </div>
        </div>
      </div>
    </div>
  );
};

IroLibraryPaginationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  modalTitle: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  generateRowKey: PropTypes.func.isRequired,
  saveButtonLabel: PropTypes.string.isRequired,
  cancelButtonLabel: PropTypes.string.isRequired,
  disable: PropTypes.bool,
  sectorLabel: PropTypes.string,
  sectorOptions: PropTypes.array.isRequired,
  selectedSector: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  setSelectedSector: PropTypes.func.isRequired,
  typeLabel: PropTypes.string,
  typeOptions: PropTypes.array.isRequired,
  selectedType: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  setSelectedType: PropTypes.func.isRequired,
  ar16Label: PropTypes.string,
  ar16Options: PropTypes.array.isRequired,
  selectedAr16: PropTypes.array.isRequired,
  setSelectedAr16: PropTypes.func.isRequired,
  fetchData: PropTypes.func.isRequired,
};

export default IroLibraryPaginationModal;