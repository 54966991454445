import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  TablePagination,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Sort } from "../../constants/icons/Icons";

import "./ClientTable.scss";

const SORT_ASC = 0;
const SORT_DESC = 1;

/**
 * A server-side paginated table that fetches from a parent `fetchData` function.
 *
 * PROPS:
 *  - columns: Array of column configs, each with { colName, label, showSorting?, ... }
 *  - fetchData({ pageIndex, pageSize, sortField, sortDirection, searchQuery? }): returns { itemsOut: [], totalCount: number }
 *  - renderTableCell(colName, value, row, colIndex): Renders each cell
 *  - generateRowKey(row): Unique key for each row
 *  - onPageDataChange(rows): (Optional) Notifies the parent of the current page's rows
 *  - defaultSortField (string): (Optional) Field to sort on initially
 *  - defaultSortDirection (number): (Optional) 0 (ascending) or 1 (descending)
 */
const ClientTablePagination = ({
  columns,
  fetchData,
  renderTableCell,
  generateRowKey,
  onPageDataChange,
  defaultSortField,
  defaultSortDirection,
}) => {
  // Basic pagination & data state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);

  // Single-column sorting
  const [sortField, setSortField] = useState(defaultSortField);
  const [sortDirection, setSortDirection] = useState(defaultSortDirection);

  const theme = createTheme({
    typography: {
      fontFamily: "EYInterstate, sans-serif",
    },
  });

  // The function that loads data from the server
  const loadPageData = useCallback(
    async (
      overridePage,
      overridePageSize,
      overrideSortField,
      overrideSortDirection
    ) => {
      setLoading(true);
      try {
        const result = await fetchData({
          pageIndex: overridePage + 1,
          pageSize: overridePageSize,
          sortField: overrideSortField,
          sortDirection: overrideSortDirection,
        });
        const newRows = result.itemsOut || [];
        setData(newRows);
        setTotalCount(result.totalCount || 0);

        // If parent wants to track current page's rows
        if (onPageDataChange) {
          onPageDataChange(newRows);
        }
      } catch (error) {
        console.error("Error fetching page data:", error);
      } finally {
        setLoading(false);
      }
    },
    [fetchData, onPageDataChange]
  );

  // Whenever page, rowsPerPage, or sort changes, re-fetch
  useEffect(() => {
    loadPageData(page, rowsPerPage, sortField, sortDirection);
  }, [page, rowsPerPage, sortField, sortDirection, loadPageData]);

  // Page change handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setRowsPerPage(newSize);
    setPage(0);
  };

  // Toggling single-column sort
  const handleSort = (colName) => {
    if (colName === sortField) {
      setSortDirection((prev) => (prev === SORT_ASC ? SORT_DESC : SORT_ASC));
    } else {
      setSortField(colName);
      setSortDirection(SORT_ASC);
    }
    setPage(0);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box width="100%">
        <Box border={1} borderColor="grey.300" className="table-container">
          {loading ? (
            <div className="loading-indicator">Loading...</div>
          ) : (
            <div className="table-wrapper">
              <Table>
                <TableHead>
                  <TableRow>
                    {columns.map((column, colIndex) => {
                      const isSortable = !!column.showSorting;
                      // Check if this column is the active sort column
                      const isActiveSort = sortField === column.colName;
                      const rotation =
                        isActiveSort && sortDirection === SORT_ASC
                          ? "rotate(180deg)"
                          : "rotate(0deg)";

                      const style = {
                        flex: 1,
                        textAlign:
                          column?.textAlign ??
                          (colIndex === columns.length - 1 ? "right" : "left"),
                        paddingRight: colIndex === columns.length - 1 ? "8.12%" : "0",
                        paddingLeft: "30px",
                        cursor: column.showSorting ? "pointer" : "default",
                        width: column.width || "auto",
                        "@media (maxWidth: 768px)": {
                          paddingRight: "4%",
                        },
                      };

                      return (
                        <TableCell
                          key={column.colName}
                          style={style}
                          className="table-header-cell"
                          onClick={isSortable ? () => handleSort(column.colName) : undefined}
                        >
                          {column.label}
                          {isSortable && (
                            <span
                              className="sort-icon"
                              data-testid="sort-icon"
                              style={{ marginLeft: 6, display: "inline-block" }}
                            >
                              <Sort style={{ transform: rotation }} />
                            </span>
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody className="table-content">
                  {data.map((row) => (
                    <TableRow key={generateRowKey(row)}>
                      {columns.map((column, colIndex) =>
                        renderTableCell(
                          column.colName,
                          row[column.colName],
                          row,
                          colIndex
                        )
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          )}
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

ClientTablePagination.propTypes = {
  columns: PropTypes.array.isRequired,
  fetchData: PropTypes.func.isRequired,
  renderTableCell: PropTypes.func.isRequired,
  generateRowKey: PropTypes.func.isRequired,
  onPageDataChange: PropTypes.func,
  defaultSortField: PropTypes.string,
  defaultSortDirection: PropTypes.oneOf([SORT_ASC, SORT_DESC]),
};

ClientTablePagination.defaultProps = {
  defaultSortField: "createdOn",
  defaultSortDirection: SORT_DESC,
};

export default ClientTablePagination;