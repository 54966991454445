import React, { useState, useEffect } from "react";
import {
  MotifLabel,
  MotifButton,
  MotifInput,
  MotifFormField,
  MotifErrorMessage,
  MotifMessage,
} from "@ey-xd/motif-react";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";
import "./DmaModal.scss";

const LikelihoodModal = ({
  onClose,
  setName,
  handleLikelihoodValueClick,
  modalMode,
  NameLabel,
  LikelihoodLabel,
  DefinitionLabel,
  cancelButtonLabel,
  saveButtonLabel,
  updateButtonLabel,
  selectedRow,
  InputName,
  InputLikelihood,
  onDeleteClick,
  DeleteLabel,
}) => {
  const isEditMode = modalMode === "edit";
  const [formData, setFormData] = useState({
    nameInput: "",
    likelihood: "",
    definition: "",
  });
  const [formErrors, setFormErrors] = useState({
    nameError: "",
    likelihoodError: "",
    definitionError: "",
  });

  useEffect(() => {
    if (isEditMode && selectedRow) {
      setFormData({
        nameInput: selectedRow.likelihoodLabel || "",
        likelihood: selectedRow.rating || "",
        definition: selectedRow.likelihoodDefinition || "",
      });
    }
  }, [isEditMode, selectedRow]);

  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
    if (formErrors[`${field}Error`]) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [`${field}Error`]: "",
      }));
    }
  };

  const validateFields = () => {
    let isValid = true;
    const errors = {};

    if (!formData.nameInput) {
      errors.nameError = "Name is required";
      isValid = false;
    }

    if (!formData.likelihood) {
      errors.likelihoodError = "Likelihood is required";
      isValid = false;
    } else if (isNaN(formData.likelihood)) {
      errors.likelihoodError = "Likelihood must be a numeric value";
      isValid = false;
    } else if (parseFloat(formData.likelihood) > 1) {
      errors.likelihoodError = "Likelihood must be less than or equal to 1";
      isValid = false;
    }

    if (!formData.definition) {
      errors.definitionError = "Definition is required";
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleSaveClick = () => {
    if (validateFields()) {
      handleLikelihoodValueClick(formData);
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-header">
          <h2>{setName}</h2>
        </div>
        <div className="modal-body">
          <div className="form-row">
            <div className="form-group">
              <MotifMessage id="form-title"> {NameLabel}*</MotifMessage>
              <MotifFormField>
                <MotifLabel
                  id="select-dma-name-input-label"
                  position="in"
                  htmlFor="name-input"
                >
                  {InputName}
                </MotifLabel>
                <MotifInput
                  data-testid="name-input"
                  className="session-modal-input session-name-input"
                  aria-describedby="select-dma-name-input-label"
                  labelPosition="in"
                  hideClearButton={true}
                  value={formData.nameInput}
                  onChange={(e) =>
                    handleInputChange("nameInput", e.target.value)
                  }
                />
              </MotifFormField>
              {formErrors.nameError && (
                <MotifErrorMessage>{formErrors.nameError}</MotifErrorMessage>
              )}
            </div>
            <div className="form-group">
              <MotifMessage id="form-title"> {LikelihoodLabel}*</MotifMessage>
              <MotifFormField>
                <MotifLabel
                  id="select-dma-name-input-label"
                  position="in"
                  htmlFor="name-input"
                >
                  {InputLikelihood}
                </MotifLabel>
                <MotifInput
                  id="likelihood-input"
                  data-testid="likelihood-input"
                  className="session-modal-input session-name-input"
                  aria-describedby="select-dma-name-input-label"
                  labelPosition="in"
                  hideClearButton={true}
                  value={formData.likelihood}
                  onChange={(e) =>
                    handleInputChange("likelihood", e.target.value)
                  }
                />
              </MotifFormField>
              {formErrors.likelihoodError && (
                <MotifErrorMessage>
                  {formErrors.likelihoodError}
                </MotifErrorMessage>
              )}
            </div>
          </div>
          <MotifMessage htmlFor="description-input">
            {DefinitionLabel}*
          </MotifMessage>
          <TextField
            id="description-input"
            data-testid="description-input"
            placeholder=""
            multiline
            fullWidth
            rows={4}
            value={formData.definition}
            onChange={(e) => handleInputChange("definition", e.target.value)}
          />
          {formErrors.definitionError && (
            <MotifErrorMessage>{formErrors.definitionError}</MotifErrorMessage>
          )}
        </div>

        <div className="dma-modal-footer">
          <div className="left-buttons">
            {isEditMode && (
              <MotifButton
                className="modal-delete-button"
                onClick={onDeleteClick}
              >
                {DeleteLabel}
              </MotifButton>
            )}
          </div>
          <div className="right-buttons">
            <MotifButton onClick={onClose} className="modal-cancel-button">
              {cancelButtonLabel}
            </MotifButton>
            <MotifButton
              onClick={handleSaveClick}
              className="modal-done-button"
            >
              {isEditMode ? updateButtonLabel : saveButtonLabel}
            </MotifButton>
          </div>
        </div>
      </div>
    </div>
  );
};

LikelihoodModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  setName: PropTypes.string.isRequired,
  handleLikelihoodValueClick: PropTypes.func.isRequired,
  modalMode: PropTypes.string.isRequired,
  NameLabel: PropTypes.string.isRequired,
  LikelihoodLabel: PropTypes.string.isRequired,
  DefinitionLabel: PropTypes.string.isRequired,
  cancelButtonLabel: PropTypes.string.isRequired,
  saveButtonLabel: PropTypes.string.isRequired,
  updateButtonLabel: PropTypes.string.isRequired,
  selectedRow: PropTypes.object,
  InputName: PropTypes.string.isRequired,
  InputLikelihood: PropTypes.string.isRequired,
  DeleteLabel: PropTypes.string.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
};

export default LikelihoodModal;
