import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import StackedWaterfallChart from "../../../components/charts/StackedWaterfallChart";
import WaterfallChart from "../../../components/charts/WaterfallChart";
import ExcelJS from "exceljs";
import {
  getMaterialIdentifiedImpactRiskOpportunity,
  getMaterialityMatrixData,
  getShortlistReportingData,
  clearError,
} from "../../../features/slices/DMAReporting";
import { MotifProgressLoader } from "@ey-xd/motif-react";
import { fetchExcelData } from "../../../features/slices/LongListData";
import { useTranslation } from "react-i18next";
import { fetchIroReportingData } from "../../../features/slices/DMAOverview";
import { addSheet } from "../../../utils/excelUtils";
import { handleDownloadIroReport } from "../../../utils/iroExcel";
import { generateMaterialityMatrixReport } from "../../../utils/matrixExcel";
import { generateShortListIroReport } from "../../../utils/shortlistReporting";
import ErrorModal from "../../../components/modals/error/errorModal";
import { handleError as handleLogoutError } from "../../../utils/handleError";
import "./DMAReporting.scss";

const getCookie = (name) => {
  const regex = new RegExp(`(^| )${name}=([^;]+)`);
  const match = regex.exec(document.cookie);
  return match ? match[2] : null;
};

const formatExcelData = (data) => {
  const workbook = new ExcelJS.Workbook();
  addSheet(workbook, "Sustainability Matters", data);
  return workbook;
};

const downloadExcel = async (data) => {
  const workbook = formatExcelData(data);
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = "sustainability_matters.xlsx";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};

const DMAReporting = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { moduleId } = useParams();
  const dispatch = useDispatch();
  const token =
    useSelector((state) => state.user.jwtToken) ||
    getCookie("authToken") ||
    sessionStorage.getItem("authToken");
  const { t } = useTranslation();

  const [isExcelLoading, setIsExcelLoading] = useState(false);
  const [impactData, setImpactData] = useState([]);
  const [materialImpactData, setMaterialImpactData] = useState([]);
  const [riskOpportunityData, setRiskOpportunityData] = useState([]);
  const [materialRiskOpportunityData, setMaterialRiskOpportunityData] =
    useState([]);
  const [error, setError] = useState(null);

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [localErrorMessage, setLocalErrorMessage] = useState("");
  const { isError, isGenericError, errorMessage } = useSelector(
    (state) => state.dmaReporting
  );

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await dispatch(
        getMaterialIdentifiedImpactRiskOpportunity({ moduleId, token })
      ).unwrap();
      const data = response?.data;
      if (data) {
        setImpactData(data.identifiedImpacts || []);
        setMaterialImpactData(data.materialImpacts || []);
        setRiskOpportunityData(data.identifiedRiskAndOpportunities || []);
        setMaterialRiskOpportunityData(data.materialRiskAndOpportunities || []);
      } else {
        setError("No data found.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to fetch data. Please try again.");
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, moduleId, token]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (isError && errorMessage) {
      setLocalErrorMessage(errorMessage);
      setShowErrorModal(true);
    }
  }, [isError, errorMessage]);

  const handleCancelErrorModal = () => {
    setShowErrorModal(false);
    dispatch(clearError());
  };

  const handleDownload = async (fetchAction, generateReport) => {
    setIsExcelLoading(true);
    try {
      const response = await dispatch(
        fetchAction({ moduleId, token })
      ).unwrap();
      const data = response?.data.responseData || [];
      await generateReport(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsExcelLoading(false);
    }
  };

  const memoizedChartData = useMemo(
    () => ({
      impactData,
      materialImpactData,
      riskOpportunityData,
      materialRiskOpportunityData,
    }),
    [
      impactData,
      materialImpactData,
      riskOpportunityData,
      materialRiskOpportunityData,
    ]
  );

  const StackedChartRow = useCallback(
    ({ title1, title2, data1, data2 }) => (
      <div className="motif-row dma-reporting-row">
        <div className="motif-col-6 motif-col-xl-6 chart-container border-box">
          <h3 className="chart-title">{title1}</h3>
          <StackedWaterfallChart
            data={data1}
            xKey="label"
            yKey="value"
            typeKey="type"
          />
        </div>
        <div className="motif-col-6 motif-col-xl-6 chart-container border-box">
          <h3 className="chart-title">{title2}</h3>
          <StackedWaterfallChart
            data={data2}
            xKey="label"
            yKey="value"
            typeKey="type"
          />
        </div>
      </div>
    ),
    []
  );

  const ChartRow = useCallback(
    ({ title1, title2, data1, data2 }) => (
      <div className="motif-row dma-reporting-row">
        <div className="motif-col-6 motif-col-xl-6 chart-container border-box">
          <h3 className="chart-title">{title1}</h3>
          <WaterfallChart
            data={data1}
            xKey="label"
            yKey="value"
            typeKey="type"
          />
        </div>
        <div className="motif-col-6 motif-col-xl-6 chart-container border-box">
          <h3 className="chart-title">{title2}</h3>
          <WaterfallChart
            data={data2}
            xKey="label"
            yKey="value"
            typeKey="type"
          />
        </div>
      </div>
    ),
    []
  );

  if (showErrorModal && localErrorMessage) {
    return (
      <ErrorModal
        setName={t("Error")}
        labelText={localErrorMessage}
        handleButtonClick={
          isGenericError
            ? () => handleLogoutError(dispatch)
            : handleCancelErrorModal
        }
        deleteButtonLabel={isGenericError ? t("Reload") : t("Cancel")}
      />
    );
  }
  return (
    <>
      {(isLoading || isExcelLoading) && (
        <MotifProgressLoader
          data-testid="loading-spinner"
          className="loader"
          show
          variant="default"
        />
      )}
      {error && <div className="error-message">{error}</div>}
      {!isLoading && !error && (
        <div>
          <div className="reporting-button-container">
            <button
              onClick={() => handleDownload(fetchExcelData, downloadExcel)}
            >
              {t("Long list - Sustainable matters")}
            </button>
            <button
              onClick={() =>
                handleDownload(fetchIroReportingData, handleDownloadIroReport)
              }
            >
              {t("IRO Report")}
            </button>
            <button
              onClick={() =>
                handleDownload(
                  getMaterialityMatrixData,
                  generateMaterialityMatrixReport
                )
              }
            >
              {t("Materiality matrix")}
            </button>
            <button
              onClick={() =>
                handleDownload(
                  getShortlistReportingData,
                  generateShortListIroReport
                )
              }
            >
              {t("Short list")}
            </button>
          </div>
          <StackedChartRow
            title1={t("IdentifiedImpactsByTopic")}
            title2={t("MaterialImpactsByTopic")}
            data1={memoizedChartData.impactData}
            data2={memoizedChartData.materialImpactData}
          />
          <ChartRow
            title1={t("IdentifiedRiskandOpportunityByTopic")}
            title2={t("MaterialRiskandOpportunityByTopic")}
            data1={memoizedChartData.riskOpportunityData}
            data2={memoizedChartData.materialRiskOpportunityData}
          />
        </div>
      )}
    </>
  );
};

export default DMAReporting;
