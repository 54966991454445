import React, { useState, useEffect, useCallback } from "react";
import "./NewProject.scss";
import {
  MotifCard,
  MotifCardBody,
  MotifCardFooter,
  MotifCardHeader,
  MotifButton,
  MotifProgressLoader,
} from "@ey-xd/motif-react";
import Header from "../../components/headers/Header";
import NewProjectForm from "../../components/forms/NewProjectForm";
import { newProjectFormFields } from "./NewProjectData";
import { useDispatch, useSelector } from "react-redux";
import {
  createProject,
  fetchCountries,
  fetchSectors,
  clearError,
} from "../../features/slices/ProjectScreens";
import { Back } from "../../constants/icons/Icons";
import { useNavigate, useLocation } from "react-router-dom";
import ErrorModal from "../../components/modals/error/errorModal";
import { useTranslation } from "react-i18next";
import { useMsal } from "@azure/msal-react";
import { handleError as handleLogoutError } from "../../utils/handleError";

const getCookie = (name) => {
  const regex = new RegExp(`(^| )${name}=([^;]+)`);
  const match = regex.exec(document.cookie);
  return match ? match[2] : null;
};

const initialFormState = {
  projectName: "",
  clientName: "",
  engagementCode: "",
  gisId: null,
  opportunity: "",
  countryId: "",
  channel: "",
  projectSize: "",
  clientSector: "",
  projectAdmin: [],
};

const fetchData = async (dispatch, action, setState, token) => {
  try {
    const response = await dispatch(action({ token })).unwrap();
    if (response) {
      setState(response);
    } else {
      console.error(`${action.name} data is not available`);
    }
  } catch (error) {
    console.error(`Error fetching data: ${error}`);
  }
};

const updateFormFields = (list, fieldId, setFormFields) => {
  if (Array.isArray(list)) {
    setFormFields((prevFields) =>
      prevFields.map((field) =>
        field.id === fieldId
          ? {
              ...field,
              options: list.map((item) => ({
                id: item.id,
                name:
                  fieldId === "clientSector"
                    ? `${item.name} - ${item.subName}`
                    : item.name,
              })),
            }
          : field
      )
    );
  } else {
    console.error(`${fieldId} data is not available or not an array`);
  }
};

const createProjectData = (formState) => ({
  name: formState.projectName,
  clientName: formState.clientName,
  engagementCode: formState.engagementCode,
  countryId: parseInt(formState.countryId, 10),
  isChannel1: formState.channel === "yes",
  size: formState.projectSize,
  adminUsers: formState.projectAdmin.map((admin) => ({
    aadId: admin.id,
    name: admin.displayName,
    email: admin.emailId,
    projectRoleId: 2,
  })),
  statusId: 1,
  gisId: parseInt(formState.gisId),
  opportunity: formState.opportunity,
  sectorId: parseInt(formState.clientSector, 10),
});

const NewProject = () => {
  const [formState, setFormState] = useState(initialFormState);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [sectorList, setSectorList] = useState([]);
  const [formFields, setFormFields] = useState(newProjectFormFields);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [localErrorMessage, setLocalErrorMessage] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    isError: isProjectCreateError,
    errorMessage,
    isGenericError,
    isLoading,
  } = useSelector((state) => state.projectDetails);
  const { instance, inProgress } = useMsal();

  const email = location.state?.email || getCookie("email");
  const userId = location.state?.userId || getCookie("userId");
  const name = location.state?.name || getCookie("name");
  const token =
    useSelector((state) => state.user.jwtToken) ||
    getCookie("authToken") ||
    sessionStorage.getItem("authToken");

  useEffect(() => {
    if (token) {
      fetchData(dispatch, fetchCountries, setCountryList, token);
      fetchData(dispatch, fetchSectors, setSectorList, token);
    }
  }, [inProgress, dispatch, token, instance]);

  useEffect(() => {
    updateFormFields(countryList?.data, "countryId", setFormFields);
    updateFormFields(sectorList?.data, "clientSector", setFormFields);
  }, [countryList, sectorList]);

  useEffect(() => {
    if (name && email && userId) {
      setFormState((prevState) => ({
        ...prevState,
        projectAdmin: [{ id: userId, displayName: name, emailId: email }],
      }));
    }
  }, [name, email, userId]);

  const handleBack = useCallback(() => navigate("/"), [navigate]);

  const isFormValid = useCallback(() => {
    const {
      projectName,
      clientName,
      countryId,
      channel,
      projectSize,
      clientSector,
      projectAdmin,
    } = formState;
    return (
      projectName?.trim() &&
      clientName?.trim() &&
      countryId?.length > 0 &&
      channel?.trim() &&
      projectSize?.trim() &&
      clientSector?.length > 0 &&
      projectAdmin?.length > 0
    );
  }, [formState]);

  const handleSubmit = useCallback(() => {
    if (isFormValid()) {
      setIsSubmitting(true);
      const projectData = createProjectData(formState);
      dispatch(createProject({ projectData, token }))
        .unwrap()
        .then((response) => {
          if (response.success && response.data) {
            navigate(`/project-home/${response.data}`, { state: { token } });
          } else {
            alert("Project created, but no data received to navigate.");
          }
        })
        .catch((error) => console.error("Creation failed:", error))
        .finally(() => setIsSubmitting(false));
    } else {
      alert("Please fill in all required fields.");
    }
  }, [formState, isFormValid, dispatch, token, navigate]);

  useEffect(() => {
    if (isProjectCreateError && errorMessage) {
      setLocalErrorMessage(errorMessage);
      setShowErrorModal(true);
    }
  }, [isProjectCreateError, errorMessage]);

  const handleCancelErrorModal = () => {
    setShowErrorModal(false);
    dispatch(clearError());
  };

  if (showErrorModal && localErrorMessage) {
    return (
      <ErrorModal
        setName={t("Error")}
        labelText={localErrorMessage}
        handleButtonClick={
          isGenericError ? handleLogoutError(dispatch) : handleCancelErrorModal
        }
        deleteButtonLabel={isGenericError ? t("Reload") : t("Cancel")}
      />
    );
  }

  return (
    <>
      {isLoading && (
        <MotifProgressLoader
          data-testid="loading-spinner"
          className="loader"
          show
          variant="default"
        />
      )}
      <div>
        <Header />
        <div className="Container">
          <div id="inline">
            <MotifButton onClick={handleBack} className="modal-done-button">
              <Back />
              &nbsp;
              {t("backButton")}
            </MotifButton>
          </div>
          <MotifCard
            variant="card-border"
            className="new-project-card"
            orientation="horizontal"
          >
            {isSubmitting && (
              <MotifProgressLoader className="loader" show variant="default" />
            )}
            <MotifCardHeader>
              <span className="motif-h6-default-regular">
                {t("newProject")}
              </span>
            </MotifCardHeader>
            <MotifCardBody>
              <NewProjectForm
                formFields={formFields}
                formState={formState}
                setFormState={setFormState}
                setSelectedUsers={setSelectedUsers}
              />
            </MotifCardBody>
            <MotifCardFooter className="new-project-footer">
              <MotifButton onClick={handleBack} className="modal-cancel-button">
                {t("Cancel")}
              </MotifButton>
              <MotifButton
                className="modal-done-button"
                onClick={handleSubmit}
                disabled={!isFormValid() || isSubmitting}
              >
                {t("submitButton")}
              </MotifButton>
            </MotifCardFooter>
          </MotifCard>
        </div>
      </div>
    </>
  );
};

export default NewProject;
