import React, { useState, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Info } from "../../constants/icons/Icons";
import InfoModal from "../modals/infoModal/InfoModal";
import ErrorModal from "../modals/error/errorModal";
import { MotifProgressLoader } from "@ey-xd/motif-react";
import { fetchKba } from "../../features/slices/kbaSlice";
import "./KbaInfoIcon.scss";

const KbaInfoIcon = ({ kbaId, language = "EN", token, iconStyle }) => {
  const dispatch = useDispatch();
  const isKbaLoading = useSelector((state) => state.kba.isLoading);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState({
    kbaCode: "",
    title: "",
    languageCode: "",
    description: "",
  });

  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const fetchKbaData = async () => {
    try {
      const response = await dispatch(
        fetchKba({
          token,
          langCode: language.toUpperCase(),
          kbaID: kbaId,
        })
      ).unwrap();

      if (response.success) {
        const result = response.data;
        setModalData({
          kbaCode: `KBA #${kbaId}`,
          title: result.name,
          languageCode: result.language,
          description: result.context,
        });
        setIsModalOpen(true);
      } else {
        throw new Error("KBA fetch returned success=false");
      }
    } catch (error) {
      console.error("Error fetching KBA:", error);
      setErrorMessage(error.message || "Unable to fetch KBA data");
      setIsErrorModalOpen(true);
    }
  };

  const handleIconClick = useCallback(() => {
    if (!token) {
      console.warn("No token provided for KbaInfoIcon.");
      return;
    }
    fetchKbaData();
  }, [dispatch, token, language, kbaId]);

  const handleCloseModal = () => setIsModalOpen(false);
  const handleCloseErrorModal = () => {
    setIsErrorModalOpen(false);
    setErrorMessage("");
  };

  const memoizedModalData = useMemo(() => modalData, [modalData]);

  return (
    <>
      {isKbaLoading && (
        <MotifProgressLoader
          data-testid="kba-loader"
          className="loader"
          show
          variant="default"
        />
      )}

      <button
        onClick={handleIconClick}
        className="kba-info-icon-button"
        style={iconStyle}
        aria-label="Open KBA Info"
      >
        <Info />
      </button>

      <InfoModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        data={memoizedModalData}
        handleInfoClick={handleIconClick}
      />

      {isErrorModalOpen && (
        <ErrorModal
          setName="Error"
          labelText={errorMessage}
          deleteButtonLabel="Close"
          handleButtonClick={handleCloseErrorModal}
        />
      )}
    </>
  );
};

KbaInfoIcon.propTypes = {
  kbaId: PropTypes.string.isRequired,
  language: PropTypes.string,
  token: PropTypes.string,
  iconStyle: PropTypes.object,
};

export default KbaInfoIcon;
