import ExcelJS from "exceljs";

export const generateShortListIroReport = async (data) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Reporting shortlist");

  worksheet.columns = [
    { header: "IRO", key: "iroName", width: 20 },
    { header: "IRO Type", key: "iroType", width: 15 },
    { header: "IRO Description", key: "iroDescription", width: 40 },
    { header: "Is material", key: "isMaterial", width: 15 },
    { header: "Score", key: "score", width: 12 },
    { header: "SM name incl. ESRS", key: "smNameInclESRS", width: 25 },
  ];

  worksheet.getRow(1).eachCell((cell) => {
    cell.font = {
      bold: true,
      color: { argb: "FFFFFFFF" },
      size: 12,
    };
    cell.alignment = {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    };
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF000000" },
    };
    cell.border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
  });

  if (data === null) {
    data = [
      {
        iroName: "N/A",
        iroType: "N/A",
        iroDescription: "N/A",
        isMaterial: "N/A",
        score: "N/A",
        smNameInclESRS: "N/A",
      },
    ];
  }

  data.forEach((item) => {
    const row = worksheet.addRow({
      iroName: item.iroName ?? "N/A",
      iroType: item.iroType ?? "N/A",
      iroDescription: item.iroDescription ?? "N/A",
      isMaterial: item.isMaterial ?? "N/A",
      score: item.score ?? "N/A",
      smNameInclESRS: item.smNameInclESRS ?? "N/A",
    });
    let maxLineCount = 1;

    row.eachCell((cell, colNumber) => {
      const text = cell.value ? cell.value.toString() : "";
      const colWidth = worksheet.getColumn(colNumber).width;

      const approxLines = Math.ceil(text.length / (colWidth * 0.8));
      maxLineCount = Math.max(maxLineCount, approxLines);

      cell.alignment = {
        vertical: "middle",
        horizontal: colNumber === 4 || colNumber === 5 ? "center" : "left",
        wrapText: true,
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      cell.font = {
        size: 11,
        name: "Calibri",
      };
    });

    row.height = 20 + Math.max(maxLineCount - 1, 0) * 15;
  });

  worksheet.columns.forEach((column) => {
    let maxLength = 0;
    column.eachCell({ includeEmpty: true }, (cell) => {
      const text = cell.text || "";
      maxLength = Math.max(maxLength, text.length);
    });
    column.width = Math.min(maxLength + 2, 50);
  });

  worksheet.pageSetup = {
    orientation: "landscape",
    fitToWidth: 1,
    fitToHeight: false,
    margins: {
      left: 0.5,
      right: 0.5,
      top: 0.75,
      bottom: 0.75,
      header: 0.3,
      footer: 0.3,
    },
    repeatRows: "1:1",
    printOptions: {
      gridLines: true,
      horizontalCentered: true,
    },
    headerFooter: {
      oddHeader: "&C&16&K000000Reporting Shortlist",
      oddFooter:
        "&L&10&K666666Generated on: " + new Date().toLocaleDateString(),
    },
  };

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = "Reporting_shortlist.xlsx";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
