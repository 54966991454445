import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { TextField, Typography, Box } from "@mui/material";
import { IRODot, Dot } from "../../constants/icons/Icons";
import {
  MotifButton,
  MotifFormField,
  MotifToggleSwitch,
  MotifMessage,
} from "@ey-xd/motif-react";
import "./CommentBox.scss";

const CommentBox = ({
  label,
  onSave,
  saveButtonLabel,
  isMaterial,
  comment,
  setComment,
  isToggled,
  setIsToggled,
  showExtraField,
  dmaResults,
  setDmaResults,
  dotColorState,
}) => {
  const [commentError, setCommentError] = useState("");
  const [dmaError, setDmaError] = useState("");

  useEffect(() => {
    setCommentError("");
    setDmaError("");
  }, [label]);

  const dotColor = dotColorState ? dotColorState?.dotColor : null;

  const handleToggle = () => setIsToggled((prev) => !prev);

  const handleSave = () => {
    onSave?.(comment, isToggled, dmaResults);
  };

  return (
    <Box className="comment-toggle-container">
      <Box
        className="comment-toggle-header"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingLeft: "2%",
        }}
      >
        <Typography
          variant="body1"
          className="DGA-Label"
          sx={{ display: "flex", alignItems: "center" }}
        >
          {dotColor ? (
            <IRODot fill={dotColor} style={{ marginTop: "2%" }} />
          ) : (
            <Dot style={{ marginTop: "2%" }} />
          )}
          &nbsp;{label}
        </Typography>
      </Box>
      <Box style={{ display: "flex", justifyContent: "space-between" }}>
        <Box className="comment-toggle-right">
          <MotifFormField
            className="dga-toggle"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <MotifToggleSwitch
              onChange={handleToggle}
              id="exampleToggle"
              hideLabels
              checked={isToggled}
              data-testid="exampleToggle"
              aria-label="Toggle material status"
            />
            <MotifMessage
              className="material-text"
              data-testid="human-rights"
              style={{ marginLeft: "8px" }}
            >
              {isMaterial}
            </MotifMessage>
          </MotifFormField>
        </Box>
        <Box className="comment-toggle-actions">
          <MotifButton
            onClick={handleSave}
            className="modal-done-button"
            aria-label="Save comment"
          >
            {saveButtonLabel}
          </MotifButton>
        </Box>
      </Box>
      <Box style={{ paddingLeft: "1.7%" }}>
        {showExtraField && (
          <TextField
            label="DMA results"
            multiline
            variant="outlined"
            rows={10}
            value={dmaResults}
            onChange={(e) => {
              if (e.target.value.length <= 4000) {
                setDmaResults(e.target.value);
                setDmaError("");
              } else {
                setDmaError("DMA results must not exceed 4000 characters.");
              }
            }}
            fullWidth
            sx={{ marginTop: 2 }}
            error={!!dmaError}
            helperText={dmaError}
            aria-label="Enter DMA results"
          />
        )}
        <TextField
          label="Comment"
          multiline
          rows={showExtraField ? 12 : 25}
          variant="outlined"
          value={comment}
          onChange={(e) => {
            if (e.target.value.length <= 500) {
              setComment(e.target.value);
              setCommentError("");
            } else {
              setCommentError("Comment must not exceed 500 characters.");
            }
          }}
          fullWidth
          error={!!commentError}
          helperText={commentError}
          sx={{ marginTop: 3 }}
          aria-label="Enter comment"
        />
      </Box>
    </Box>
  );
};

CommentBox.propTypes = {
  label: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  saveButtonLabel: PropTypes.string,
  isMaterial: PropTypes.string,
  comment: PropTypes.string.isRequired,
  setComment: PropTypes.func.isRequired,
  isToggled: PropTypes.bool.isRequired,
  setIsToggled: PropTypes.func.isRequired,
  showExtraField: PropTypes.bool,
  dmaResults: PropTypes.string,
  setDmaResults: PropTypes.func,
  dotColorState: PropTypes.object,
};

export default CommentBox;
