import React, {
  useEffect,
  useReducer,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import TreeView from "../../../components/treeView/TreeView";
import ContextSelection from "../../../components/contextSelection/ContextSelection";
import {
  getAllIro,
  createIro,
  updateIro,
  deleteIro,
  getApsIro,
  getSourcesIro,
  getValueChainIro,
  getStakeholdersIro,
  getGeographiesIro,
  updateSourcesIro,
  updateValueChainIro,
  updateApsIro,
  updateGeographiesIro,
  updateStakeholdersIro,
  getAllSustainabilityMattersIro,
  updateSustainabilityMattersIro,
  getAllTimeframeTypes,
  getAllIroTypes,
  getIROLibraryTypes,
  getIROLibraryAr16,
  getIROLibrarySectors,
  fetchLibraryItemsPaged,
  createSustainabilityMatterFromLibrary,
  clearError,
} from "../../../features/slices/Iro";
import { MotifProgressLoader } from "@ey-xd/motif-react";
import { useTranslation } from "react-i18next";
import IroModal from "../../../components/modals/dmaModals/IroModal";
import IroLibraryPaginationModal from "../../../components/modals/dmaModals/IroLibraryPaginationModal";
import ErrorModal from "../../../components/modals/error/errorModal";
import DeleteModal from "../../../components/modals/deleteModal/DeleteModal";
import PropTypes from "prop-types";
import "./DMAIroOverview.scss";
import { handleError as handleLogoutError } from "../../../utils/handleError";

const getCookie = (name) => {
  const regex = new RegExp(`(^| )${name}=([^;]+)`);
  const match = regex.exec(document.cookie);
  return match ? match[2] : null;
};

const initialState = { searchInput: "" };

const reducer = (state, action) => {
  if (action.type === "SET_SEARCH_INPUT") {
    return { ...state, searchInput: action.payload };
  } else {
    return state;
  }
};

const DMAIroOverviewPagination = ({
  setAssessmentIroId,
  navigateToAssessment,
}) => {
  const [modal, setModal] = useState({ isOpen: false, title: "", mode: "add" });
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    itemId: null,
  });
  const [libraryModal, setLibraryModal] = useState(false);
  const [libraryTypes, setLibraryTypes] = useState([]);
  const [librarySectors, setLibrarySectors] = useState([]);
  const [libraryAr16, setLibraryAr16] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedSector, setSelectedSector] = useState(null);
  const [selectedAr16, setSelectedAr16] = useState([]);
  const { t } = useTranslation();
  const token =
    useSelector((state) => state.user.jwtToken) ||
    getCookie("authToken") ||
    sessionStorage.getItem("authToken");

  const dispatch = useDispatch();
  const iroData = useSelector((state) => state.iro.data);
  const { isError, isGenericError, errorMessage, isLoading } = useSelector(
    (state) => state.iro
  );

  const { moduleId: moduleIdString } = useParams();
  const moduleId = parseInt(moduleIdString);

  const [state, localDispatch] = useReducer(reducer, initialState);
  const [pillarOptions, setPillarOptions] = useState([]);
  const [areaOptions, setAreaOptions] = useState([]);
  const [timeframeOptions, setTimeframeOptions] = useState([]);
  const [iroTypesOptions, setIroTypesOptions] = useState([]);
  const [updateTrigger, setUpdateTrigger] = useState(0);
  const [updateItemID, setUpdateItemID] = useState(null);
  const [filteredUpdateData, setFilteredUpdateData] = useState(null);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [localErrorMessage, setLocalErrorMessage] = useState("");
  const [localLoader, setLocalLoader] = useState(false);
  const [isLibraryLoading, setIsLibraryLoading] = useState(false);

  // For each IRO, we load these tabs:
  const [tabData, setTabData] = useState({
    source: [],
    aps: [],
    valuechain: [],
    geographies: [],
    stakeholders: [],
    sustainablilitymatters: [],
    updatedsustainablilitymatters: [],
  });

  const [dataFetched, setDataFetched] = useState({
    source: false,
    aps: false,
    valuechain: false,
    geographies: false,
    stakeholders: false,
    sustainablilitymatters: false,
    updatedsustainablilitymatters: false,
  });

  // ----------------- UseEffects & Data Fetches ----------------- //
  useEffect(() => {
    if (isError && errorMessage) {
      setLocalErrorMessage(errorMessage);
      setShowErrorModal(true);
    }
  }, [isError, errorMessage]);

  const fetchData = useCallback(
    async (action, setState) => {
      try {
        const response = await dispatch(action({ token })).unwrap();
        if (response.success) {
          setState(response.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    [dispatch, token]
  );

  useEffect(() => {
    if (token) {
      dispatch(getAllIro({ moduleId, token }));
    }
  }, [token, dispatch, moduleId, updateTrigger]);

  useEffect(() => {
    if (token) {
      fetchData(getAllTimeframeTypes, setTimeframeOptions);
      fetchData(getAllIroTypes, setIroTypesOptions);
    }
  }, [token, dispatch, fetchData]);

  // ----------------- Search Handling ----------------- //
  const handleSearchInputChange = useCallback((event) => {
    localDispatch({ type: "SET_SEARCH_INPUT", payload: event });
  }, []);

  // ----------------- IRO Data Helpers ----------------- //
  const getDotColor = (isMaterial) => {
    if (isMaterial === true) return "green";
    if (isMaterial === false) return "red";
    return "grey";
  };

  const filterItems = useCallback((items, query) => {
    if (!query) return items;
    const queryLowerCase = query.toLowerCase();

    return items
      .map((item) => {
        const areaMatches = item.children.filter((area) => {
          const nameMatches = area.children.filter((name) =>
            name.label.toLowerCase().includes(queryLowerCase)
          );
          return (
            area.label.toLowerCase().includes(queryLowerCase) ||
            nameMatches.length > 0
          );
        });

        if (
          item.label.toLowerCase().includes(queryLowerCase) ||
          areaMatches.length > 0
        ) {
          return { ...item, children: areaMatches };
        }
        return null;
      })
      .filter(Boolean);
  }, []);

  const memoizedIroData = useMemo(() => iroData?.data || [], [iroData]);

  useEffect(() => {
    if (memoizedIroData.length) {
      setPillarOptions(getUniqueOptions(memoizedIroData, "pillar"));
      setAreaOptions(getUniqueOptions(memoizedIroData, "area"));
    }
  }, [memoizedIroData]);

  const getUniqueOptions = (data, key) => {
    return [...new Set(data.map((item) => item[key]))].map((value) => ({
      value,
      label: value,
    }));
  };

  const transformData = (data) => {
    if (!Array.isArray(data)) {
      console.error("Unexpected data format:", data);
      return [];
    }

    const pillarMap = {};
    data.forEach((item) => {
      if (!pillarMap[item.pillar]) {
        pillarMap[item.pillar] = {
          id: `pillar-${item.id}`,
          itemId: `pillar-${item.pillar}`,
          label: item.pillar,
          itemType: "pillar",
          children: [],
        };
      }
      let area = pillarMap[item.pillar].children.find(
        (child) => child.label === item.area
      );
      if (!area) {
        area = {
          id: `area-${item.id}`,
          itemId: `area-${item.pillar}-${item.area}`,
          label: item.area,
          itemType: "area",
          children: [],
        };
        pillarMap[item.pillar].children.push(area);
      }
      area.children.push({
        id: `name-${item.id}`,
        itemId: item.id,
        label: item.name,
        itemType: "name",
      });
    });
    return Object.values(pillarMap);
  };

  const transformedData = useMemo(
    () => transformData(memoizedIroData),
    [memoizedIroData]
  );

  const filteredData = useMemo(
    () => filterItems(transformedData, state.searchInput),
    [transformedData, state.searchInput, filterItems]
  );

  const dotColorState = useMemo(() => {
    if (!memoizedIroData.length) return {};

    return memoizedIroData.reduce((acc, item) => {
      const typeName = item?.iroTypeName;
      const maxScore = item.maxScore != null ? item.maxScore.toFixed(1) : "N/A";

      acc[`name-${item.id}`] = {
        dotColor: getDotColor(item.isMaterial),
        ...(maxScore !== undefined && { maxScore }),
        ...(typeName && { typeName }),
      };

      return acc;
    }, {});
  }, [memoizedIroData]);

  // ----------------- Action Buttons in TreeView (Edit/Delete/View) ----------------- //
  const findItemById = (data, id) => {
    for (let item of data) {
      if (item.id === id) return item;
      if (item.children) {
        const result = findItemById(item.children, id);
        if (result) return result;
      }
    }
    return null;
  };

  const handleEditClick = (id) => {
    try {
      const numericId =
        typeof id === "string" ? parseInt(id.replace(/^\D+/g, "")) : id;
      handleOpenModal("edit", numericId);
      const item = findItemById(memoizedIroData, numericId);
      setFilteredUpdateData(item);
      setUpdateItemID(numericId);
    } catch (error) {
      console.error("Error in handleEditClick:", error);
    }
  };

  const handleDeleteClick = (id) => {
    setDeleteModal({ isOpen: true, itemId: updateItemID });
  };

  const handleViewClick = (id) => {
    const assessmentIroId =
      typeof id === "string" ? parseInt(id.replace(/^\D+/g, "")) : id;
    setAssessmentIroId(assessmentIroId);
    navigateToAssessment();
  };

  const handleConfirmDelete = async () => {
    try {
      const { itemId } = deleteModal;
      await dispatch(deleteIro({ iroId: itemId, token, moduleId })).unwrap();
      setUpdateTrigger((prev) => prev + 1);
      setDeleteModal({ isOpen: false, itemId: null });
      setAssessmentIroId(null);
      handleCloseModal();
    } catch (error) {
      console.error("Deletion failed:", error);
    }
  };

  // ----------------- IRO Modal Add/Edit ----------------- //
  const handleOpenModal = (mode, itemId) => {
    setModal({
      isOpen: true,
      mode,
      title: mode === "add" ? t("NewIRO") : t("EditIRO"),
    });
  };

  const handleCloseModal = () => {
    setModal((prev) => ({ ...prev, isOpen: false }));
    resetDataFetchedAndTabData();
  };

  const resetDataFetchedAndTabData = () => {
    setDataFetched({
      source: false,
      aps: false,
      valuechain: false,
      geographies: false,
      stakeholders: false,
      sustainablilitymatters: false,
      updatedsustainablilitymatters: false,
    });

    setTabData({
      source: [],
      aps: [],
      valuechain: [],
      geographies: [],
      stakeholders: [],
      sustainablilitymatters: [],
      updatedsustainablilitymatters: [],
    });
  };

  const createNewIroApi = async (data) => {
    if (data) {
      data.moduleId = moduleId;
      try {
        const response = await dispatch(
          createIro({ iroData: data, token })
        ).unwrap();
        const newId = response.data.id;
        setUpdateTrigger((prev) => prev + 1);
        handleCloseModal();
        // Re-open in Edit mode immediately after creating
        handleEditClick(newId);
      } catch (error) {
        console.error("Creation failed:", error);
        setUpdateTrigger((prev) => prev + 1);
      }
    } else {
      alert("Please fill in all required fields");
    }
  };

  const updateIroApi = async (data) => {
    if (data) {
      data.moduleId = moduleId;
      data.moduleIROId = updateItemID;
      try {
        const response = await dispatch(
          updateIro({ iroId: updateItemID, iroData: data, token })
        ).unwrap();
        if (response.success) {
          setUpdateTrigger((prev) => prev + 1);
          handleCloseModal();
        }
      } catch (error) {
        console.error("Updation failed:", error);
        setUpdateTrigger((prev) => prev + 1);
      }
    } else {
      alert("Please fill in all required fields");
    }
  };

  // ----------------- Tab Data (APs, Sources, etc.) ----------------- //
  const fetchTabData = (tab) => {
    if (!dataFetched[tab]) {
      const tabActions = {
        source: getSourcesIro,
        aps: getApsIro,
        valuechain: getValueChainIro,
        geographies: getGeographiesIro,
        stakeholders: getStakeholdersIro,
        sustainablilitymatters: getAllSustainabilityMattersIro,
      };

      const action = tabActions[tab];
      if (action) {
        dispatch(action({ iroId: updateItemID, token, moduleId }))
          .unwrap()
          .then((data) => {
            setTabData((prev) => ({ ...prev, [tab]: data.data }));
            setDataFetched((prev) => ({ ...prev, [tab]: true }));
          })
          .catch((error) => console.error(error));
      }
    }
  };

  const updateTabData = async () => {
    const apiCalls = {
      source: updateSourcesIro,
      aps: updateApsIro,
      valuechain: updateValueChainIro,
      geographies: updateGeographiesIro,
      stakeholders: updateStakeholdersIro,
      sustainablilitymatters: updateSustainabilityMattersIro,
    };

    const tabDataMapping = {
      source: tabData.source,
      aps: tabData.aps,
      valuechain: tabData.valuechain,
      geographies: tabData.geographies,
      stakeholders: tabData.stakeholders,
      sustainablilitymatters: tabData.updatedsustainablilitymatters,
    };

    try {
      setLocalLoader(true);
      for (const tab of Object.keys(apiCalls)) {
        const apiAction = apiCalls[tab];
        const tabDataItems = tabDataMapping[tab];

        if (tabDataItems && tabDataItems.length > 0) {
          const response = await dispatch(
            apiAction({
              iroId: updateItemID,
              iroData: tabDataItems.map((item) => ({ ...item })),
              token,
              moduleId,
            })
          ).unwrap();

          if (!response.success) {
            throw new Error(`Update failed for ${tab}`);
          }
        }
      }
      setUpdateTrigger((prev) => prev + 1);
      handleCloseModal();
    } catch (error) {
      console.error("Update failed:", error);
    } finally {
      setLocalLoader(false);
    }
  };

  // ----------------- Library Modal Handling ----------------- //
  const handleOpenLibraryModal = useCallback(async () => {
    try {
      setIsLibraryLoading(true);
      const [typesResp, sectorResp, ar16Resp] = await Promise.all([
        dispatch(getIROLibraryTypes({ token })).unwrap(),
        dispatch(getIROLibrarySectors({ token })).unwrap(),
        dispatch(getIROLibraryAr16({ token })).unwrap(),
      ]);

      if (typesResp.success) {
        setLibraryTypes(typesResp.data);
        setSelectedType(typesResp.data[0]?.id?.toString());
      }
      if (sectorResp.success) {
        setLibrarySectors(sectorResp.data);
        setSelectedSector(sectorResp.data[0]?.id?.toString());
      }
      if (ar16Resp.success) {
        const ar16Options = [
          { id: 0, name: "All" }, // Adding All option manually
          ...ar16Resp.data,
        ];

        setLibraryAr16(ar16Options);
        setSelectedAr16(["0"]); // Default selection to "All"
      }
    } catch (error) {
      console.error("Error fetching library types/sectors:", error);
    } finally {
      setIsLibraryLoading(false);
      setLibraryModal(true);
    }
  }, [dispatch, token]);

  const handleCloseLibraryModal = useCallback(() => {
    setLibraryModal(false);
    setSelectedType(null);
    setSelectedSector(null);
    setSelectedAr16([]);
  }, []);

  const handleLibrarySubmit = useCallback(
    async (selectedIds) => {
      try {
        const response = await dispatch(
          createSustainabilityMatterFromLibrary({
            token,
            moduleId,
            data: selectedIds,
          })
        ).unwrap();

        if (response.success) {
          await dispatch(getAllIro({ moduleId, token }));
          handleCloseLibraryModal();
        } else {
          console.error(
            "Failed to create sustainability matters from library:",
            response.message
          );
        }
      } catch (error) {
        console.error(
          "Error creating sustainability matters from library:",
          error
        );
      }
    },
    [dispatch, token, moduleId, handleCloseLibraryModal]
  );

  const libraryModalFetchData = useCallback(
    async ({ pageIndex, pageSize, searchQuery, sortField, sortDirection }) => {
      const res = await dispatch(
        fetchLibraryItemsPaged({
          token,
          sectorId: selectedSector,
          iroTypeId: selectedType,
          esrsAr16Ids: selectedAr16,
          pageIndex,
          pageSize,
          filterOperator: "Contains",
          filterValue: searchQuery,
          sortField,
          sortDirection,
        })
      ).unwrap();

      return res.data;
    },
    [dispatch, token, selectedSector, selectedType, selectedAr16]
  );

  // ----------------- Error Handling ----------------- //
  const handleCancelErrorModal = () => {
    setShowErrorModal(false);
    dispatch(clearError());
  };

  // For fully expanding the TreeView on load
  const allExpandedIds = useMemo(() => {
    const getAllIds = (items) => {
      const ids = [];
      const traverse = (nodes) => {
        nodes.forEach((node) => {
          ids.push(node.id);
          if (node.children) {
            traverse(node.children);
          }
        });
      };
      traverse(items);
      return ids;
    };
    return getAllIds(filteredData);
  }, [filteredData]);

  return (
    <>
      {(isLoading || localLoader || isLibraryLoading) && (
        <MotifProgressLoader
          data-testid="loading-spinner"
          className="loader"
          show
          variant="default"
        />
      )}

      {/* Error Modal */}
      {showErrorModal && localErrorMessage && (
        <ErrorModal
          setName={t("Error")}
          labelText={localErrorMessage}
          handleButtonClick={
            isGenericError
              ? () => handleLogoutError(dispatch)
              : handleCancelErrorModal
          }
          deleteButtonLabel={isGenericError ? t("Reload") : t("Cancel")}
        />
      )}

      <div className="Iro-file-explorer">
        {/* Top Section with Create New and Library */}
        <div>
          <ContextSelection
            addButtonText={t("NewIRO")}
            pageName="IRO"
            libraryButtonText={t("libraryLabel")}
            onClickNewProject={() => handleOpenModal("add")}
            onClickLibrary={handleOpenLibraryModal}
            onChangeSearchInput={handleSearchInputChange}
          />
        </div>

        {/* Tree View */}
        <div className="Iro-table">
          <TreeView
            key={allExpandedIds.join("-")}
            items={filteredData}
            defaultExpandedItems={allExpandedIds}
            defaultSelectedItems=""
            onEditClick={handleEditClick}
            onViewClick={handleViewClick}
            onDeleteClick={handleDeleteClick}
            handleCheckboxChange={() => {}}
            dotColorState={dotColorState}
            pageName="IRO"
          />
        </div>

        {/* IRO Modal (Add/Edit) */}
        {modal.isOpen && (
          <IroModal
            onClose={handleCloseModal}
            modalTitle={modal.title}
            modalMode={modal.mode}
            nameLabel={t("Name")}
            typeLabel={t("IROType")}
            areaLabel={t("Area")}
            pillarLabel={t("Pillar")}
            selectAreaLabel={t("SelectArea")}
            SelectPillar={t("SelectPillar")}
            BasicLabel={t("Definition")}
            SelectType={t("SelectType")}
            InputName={t("EnterName")}
            SelectTimeframe={t("SelectTimeframe")}
            DescriptionLabel={t("Description")}
            TabDescriptionLabel={t("TabDescription")}
            SourceLabel={t("SourceLabel")}
            ValueChainLabel={t("ValueChain")}
            APSLabel={t("APSLabel")}
            SustainablilityMattersLabel={t("Sustainablilitymatters")}
            StakeholdersLabel={t("Stakeholders")}
            GeographiesLabel={t("Geographies")}
            saveButtonLabel={t("addIRO")}
            updateButtonLabel={t("updateIRO")}
            cancelButtonLabel={t("Cancel")}
            filterByLabel={t("FilterBy")}
            pillarOptions={pillarOptions}
            areaOptions={areaOptions}
            handleDoneClick={
              modal.mode === "add" ? createNewIroApi : updateIroApi
            }
            filteredUpdateData={filteredUpdateData}
            fetchTabData={fetchTabData}
            tabData={tabData}
            disable={isLoading}
            setTabData={setTabData}
            updateTabData={updateTabData}
            filterItems={filterItems}
            TimeframeLabel={t("TimeframeLabel")}
            timeframeOptions={timeframeOptions}
            iroTypesOptions={iroTypesOptions}
            onDeleteClick={handleDeleteClick}
            DeleteLabel={t("DeleteLabel")}
            isHumanRightsRelatedLabel={t("isHumanRightsRelatedLabel")}
          />
        )}

        {/* Delete Modal */}
        {deleteModal.isOpen && (
          <DeleteModal
            isOpen={deleteModal.isOpen}
            onClose={() => setDeleteModal({ isOpen: false, itemId: null })}
            setName={t("DeleteLabel")}
            labelText={t("DeletConfirmationSources")}
            onCancel={() => setDeleteModal({ isOpen: false, itemId: null })}
            handleDeleteClick={handleConfirmDelete}
            cancelButtonLabel={t("Cancel")}
            deleteButtonLabel={t("DeleteLabel")}
          />
        )}

        {/* Library Modal with server-side paging */}
        {libraryModal && (
          <IroLibraryPaginationModal
            isOpen={libraryModal}
            onClose={handleCloseLibraryModal}
            modalTitle={t("libraryLabel")}
            sectorLabel={t("sectorLabel")}
            saveButtonLabel={t("addIROS")}
            cancelButtonLabel={t("Cancel")}
            disable={isLoading}
            sectorOptions={librarySectors}
            selectedSector={selectedSector}
            setSelectedSector={setSelectedSector}
            typeOptions={libraryTypes}
            selectedType={selectedType}
            setSelectedType={setSelectedType}
            ar16Options={libraryAr16}
            selectedAr16={selectedAr16}
            setSelectedAr16={setSelectedAr16}
            typeLabel={t("Type")}
            ar16Label={t("ESRS_AR16")}
            tableData={[]}
            generateRowKey={(row) => row.id}
            handleSubmit={handleLibrarySubmit}
            fetchData={libraryModalFetchData}
          />
        )}
      </div>
    </>
  );
};

DMAIroOverviewPagination.propTypes = {
  setAssessmentIroId: PropTypes.func,
  navigateToAssessment: PropTypes.func,
};

export default DMAIroOverviewPagination;