import React from "react";
import PropTypes from "prop-types";
import "./InfoModal.scss";
import { Cancel, Globe } from "../../../constants/icons/Icons";
import { MotifButton } from "@ey-xd/motif-react";

const InfoModal = ({ isOpen, onClose, data, handleInfoClick }) => {
  if (!isOpen) return null;

  const handleGlobeClick = () => {
    handleInfoClick("EN");
  };

  return (
    <div className="info-modal-overlay">
      <div className="info-modal">
        <div className="info-modal-header">
          <div className="info-modal-top-row">
            <span className="kba-code">{data.kbaCode}</span>
            <div className="info-modal-lang">
              <span className="language-code">{data.languageCode}</span>
              <button
                data-testid="globe-icon"
                className="globe-icon"
              >
                <Globe />
              </button>
            </div>
          </div>
          <h1 className="info-modal-title">{data.title}</h1>
          <button
            className="info-cancel-button"
            onClick={onClose}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                onClose();
              }
            }}
            aria-label="Close"
          >
            <Cancel />
          </button>
        </div>
        <div className="info-modal-content">
          <div className="formatted-description">
            <div dangerouslySetInnerHTML={{ __html: data.description }} />
          </div>
        </div>
      </div>
    </div>
  );
};

InfoModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    kbaCode: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    languageCode: PropTypes.string,
  }).isRequired,
  handleInfoClick: PropTypes.func.isRequired,
};

export default InfoModal;