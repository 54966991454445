import React from "react";
import PropTypes from "prop-types";
import { MotifButton } from "@ey-xd/motif-react";
import SunburstChart from "../../../../components/charts/SunburstChart";
import "./DMACards.scss";

const DMACards = ({
  headerIconLeft,
  headerIconRight,
  rightHeaderType,
  lefttype,
  Longlist,
  Shortlist,
  IroReport,
  Dashboard,
  references,
  UTC,
  IroCoverage,
  iroData,
  ReportingImplication,
  onTabChange,
  onShortListClick,
  onLonglistClick,
  onIroReportClick,
  navigateToReporting,
}) => {
  const handleReferenceClick = (reference) => {
    let label = reference.label;

    if (label === "IROs") {
      label = "IRO identification";
    }

    onTabChange(label);
  };

  return (
    <>
      <div className="dma-overview-card">
        <div className="dma-understanding-div">
          <label className="dma-overview-header">
            {headerIconLeft}&nbsp;
            {lefttype === UTC && UTC}
          </label>

          <div className="dma-overview-content-div">
            {references?.map((reference) => (
              <div
                key={reference.id}
                onClick={() => handleReferenceClick(reference)}
                className="dma-overview-row"
              >
                <span className="dma-overview-number-of-reference">
                  {reference.count}
                </span>
                <label className="dma-overview-content">
                  {reference.label}
                </label>
              </div>
            ))}
          </div>
        </div>
        {rightHeaderType && (
          <div className="dma-stakeholder-div">
            <label className="dma-overview-header">
              {headerIconRight}&nbsp;
              {rightHeaderType === ReportingImplication && ReportingImplication}
            </label>
            {rightHeaderType === ReportingImplication && (
              <div className="dma-overview-content-div">
                <MotifButton
                  className="dma-overview-row-reporting"
                  onClick={onLonglistClick}
                >
                  {Longlist}
                </MotifButton>
                <MotifButton
                  className="dma-overview-row-reporting"
                  onClick={onShortListClick}
                >
                  {Shortlist}
                </MotifButton>
                <MotifButton
                  className="dma-overview-row-reporting"
                  onClick={onIroReportClick}
                >
                  {IroReport}
                </MotifButton>
                <MotifButton
                  className="dma-overview-row-reporting"
                  onClick={navigateToReporting}
                >
                  {Dashboard}
                </MotifButton>
              </div>
            )}
          </div>
        )}
      </div>
      <div className="dma-overview-card">
        <div className="dma-understanding-div">
          <label className="dma-overview-header">
            {headerIconLeft}&nbsp;
            {IroCoverage}
          </label>
          <SunburstChart data={iroData} />
        </div>
      </div>
    </>
  );
};

DMACards.propTypes = {
  headerIconLeft: PropTypes.node,
  headerIconRight: PropTypes.node,
  Longlist: PropTypes.string,
  Shortlist: PropTypes.string,
  Dashboard: PropTypes.string,
  references: PropTypes.arrayOf(
    PropTypes.shape({
      count: PropTypes.number,
      label: PropTypes.string,
    })
  ),
  rightHeaderType: PropTypes.string,
  lefttype: PropTypes.string,
  UTC: PropTypes.string,
  IroReport: PropTypes.string,
  IroCoverage: PropTypes.string,
  iroData: PropTypes.array,
  ReportingImplication: PropTypes.string,
  onTabChange: PropTypes.func,
  onLonglistClick: PropTypes.func,
  onShortListClick: PropTypes.func,
  onIroReportClick: PropTypes.func,
  navigateToReporting: PropTypes.func,
};

export default DMACards;