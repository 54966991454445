import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useReducer,
} from "react";
import "./ClientOrg.scss";
import Header from "../../components/headers/Header";
import {
  fetchClientsDetails,
  fetchClientTypes,
  createClient,
  updateClient,
  deleteClient,
  clearError,
} from "../../features/slices/ProjectScreens";
import { MotifProgressLoader } from "@ey-xd/motif-react";
import { TableCell, Box, IconButton } from "@mui/material";
import { Edit, Delete } from "../../constants/icons/Icons";
import Subheader from "../../components/subheader/Subheader";
import languageData from "../../constants/languages/en/translations.json";
import ClientTable from "../../components/clientTable/ClientTable";
import { useDispatch, useSelector } from "react-redux";
import ClientModal from "../../components/modals/clientModal/ClientModal";
import DeleteModal from "../../components/modals/deleteModal/DeleteModal";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import ErrorModal from "../../components/modals/error/errorModal";
import { handleError } from "../../utils/handleError";

const getCookie = (name) => {
  const regex = new RegExp(`(^| )${name}=([^;]+)`);
  const match = regex.exec(document.cookie);
  return match ? match[2] : null;
};

const initialState = {
  types: [],
  isClientModalOpen: false,
  clientMode: "add",
  clientModalTitle: "",
  selectedClientRow: null,
  clientToDelete: null,
  isDeleteModalOpen: false,
  updateClientList: 0,
  searchClientQuery: "",
  isLoading: false,
  clientData: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_STATE":
      return { ...state, ...action.payload };
    case "SET_LOADING":
      return { ...state, isLoading: action.payload };
    case "SET_CLIENT_DATA":
      return { ...state, clientData: action.payload };
    default:
      return state;
  }
};

const ClientOrg = () => {
  const isAdmin = true;
  const location = useLocation();
  const { projectId } = useParams();
  const [state, dispatchState] = useReducer(reducer, initialState);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const token =
    useSelector((state) => state.user.jwtToken) ||
    getCookie("authToken") ||
    sessionStorage.getItem("authToken");
  const projectName = location.state?.projectName;
  const navigate = useNavigate();

  const fetchData = useCallback(async () => {
    dispatchState({ type: "SET_LOADING", payload: true });
    try {
      const [clientResponseData, typesResponse] = await Promise.all([
        dispatch(fetchClientsDetails({ projectId, token })).unwrap(),
        dispatch(fetchClientTypes({ token })).unwrap(),
      ]);

      if (typesResponse.success) {
        dispatchState({
          type: "SET_STATE",
          payload: { types: typesResponse.data },
        });
      }

      if (Array.isArray(clientResponseData.data)) {
        const clientsData = clientResponseData.data.map((item) => ({
          ...item,
          typeName: item.clientOrganisationType?.name || "Unknown",
        }));
        dispatchState({ type: "SET_CLIENT_DATA", payload: clientsData });
      } else {
        dispatchState({ type: "SET_CLIENT_DATA", payload: [] });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      dispatchState({ type: "SET_LOADING", payload: false });
    }
  }, [dispatch, projectId, token]);

  useEffect(() => {
    fetchData();
  }, [fetchData, state.updateClientList]);

  const projectScreenState = useSelector((state) => state.projectDetails);
  const { isError, errorMessage, isGenericError } = projectScreenState;

  const handleEdit = useCallback(
    (row) => {
      dispatchState({
        type: "SET_STATE",
        payload: {
          selectedClientRow: row,
          clientMode: "edit",
          clientModalTitle: t("EditClient"),
          isClientModalOpen: true,
        },
      });
    },
    [t]
  );

  const handleOpenModal = useCallback(
    (mode) => {
      dispatchState({
        type: "SET_STATE",
        payload: {
          clientMode: mode,
          selectedClientRow: null,
          clientModalTitle: mode === "add" ? t("NewClient") : t("EditClient"),
          isClientModalOpen: true,
        },
      });
    },
    [t]
  );

  const handleCloseModal = useCallback(() => {
    dispatchState({ type: "SET_STATE", payload: { isClientModalOpen: false } });
  }, []);

  const handleSearch = useCallback((event) => {
    dispatchState({
      type: "SET_STATE",
      payload: { searchClientQuery: String(event.target.value) },
    });
  }, []);

  const openDeleteModal = useCallback((row) => {
    dispatchState({
      type: "SET_STATE",
      payload: { clientToDelete: row, isDeleteModalOpen: true },
    });
  }, []);

  const handleDelete = useCallback(async () => {
    if (!state.clientToDelete) return;
    dispatchState({ type: "SET_LOADING", payload: true });

    try {
      const response = await dispatch(
        deleteClient({ clientId: state.clientToDelete.id, projectId, token })
      ).unwrap();

      if (!response.error) {
        dispatchState({
          type: "SET_STATE",
          payload: {
            clientData: state.clientData.filter(
              (client) => client.id !== state.clientToDelete.id
            ),
            isDeleteModalOpen: false,
          },
        });
      } else {
        dispatchState({
          type: "SET_STATE",
          payload: { isDeleteModalOpen: false },
        });
        console.error("Error deleting client:", response.error);
      }
    } catch (error) {
      dispatchState({
        type: "SET_STATE",
        payload: { isDeleteModalOpen: false },
      });
      console.error("Error deleting client:", error);
    } finally {
      dispatchState({ type: "SET_LOADING", payload: false });
    }
  }, [dispatch, projectId, state.clientToDelete, token, state.clientData]);

  const handleClientEditUpdateClick = useCallback(
    async (clientData) => {
      dispatchState({ type: "SET_LOADING", payload: true });
      try {
        if (state.clientMode === "add") {
          const payload = {
            name: clientData.name,
            email: clientData.email,
            typeId: parseInt(clientData.typeId, 10),
            projectId: parseInt(projectId),
          };
          const response = await dispatch(
            createClient({ clientData: payload, token })
          ).unwrap();

          if (response) {
            dispatchState({
              type: "SET_STATE",
              payload: {
                clientData: [...state.clientData, response],
                updateClientList: state.updateClientList + 1,
              },
            });
            handleCloseModal();
          } else {
            console.error("Error creating client:", response.error);
          }
        } else if (state.clientMode === "edit" && state.selectedClientRow) {
          const payload = {
            name: clientData.name,
            email: clientData.email,
            typeId: parseInt(clientData.typeId, 10),
          };
          const response = await dispatch(
            updateClient({
              clientId: state.selectedClientRow.id,
              projectId: parseInt(projectId),
              clientData: payload,
              token,
            })
          ).unwrap();

          if (response) {
            dispatchState({
              type: "SET_STATE",
              payload: {
                clientData: state.clientData.map((client) =>
                  client.id === state.selectedClientRow.id ? response : client
                ),
                updateClientList: state.updateClientList + 1,
              },
            });
            handleCloseModal();
          } else {
            console.error("Error updating client:", response.error);
          }
        }
      } catch (error) {
        console.error("Error creating/updating client:", error);
      } finally {
        dispatchState({ type: "SET_LOADING", payload: false });
      }
    },
    [
      dispatch,
      handleCloseModal,
      projectId,
      state.clientMode,
      state.selectedClientRow,
      state.clientData,
      state.updateClientList,
      token,
    ]
  );

  const columnsToDisplay = useMemo(
    () => [
      { colName: "name", label: t("Name"), showSorting: true },
      { colName: "typeName", label: t("Type"), showSorting: true },
      { colName: "email", label: t("Email"), showSorting: true },
      { colName: "Action", label: t("Action"), showSorting: false },
    ],
    [t]
  );

  const generateRowKey = (row) => row.id;

  const renderClientsTableCell = useCallback(
    (colName, value, row, index) => {
      if (colName === "Action") {
        return (
          <TableCell key="actions" sx={{ textAlign: "right", width: "150px" }}>
            <Box display="flex" justifyContent="flex-end">
              <IconButton
                disabled={state.isLoading}
                onClick={() => handleEdit(row)}
              >
                <Edit />
              </IconButton>
              <IconButton
                disabled={state.isLoading}
                onClick={() => openDeleteModal(row)}
              >
                <Delete />
              </IconButton>
            </Box>
          </TableCell>
        );
      }
      if (colName === "typeName") {
        return (
          <TableCell key={colName}>
            {row.clientOrganisationType?.name}
          </TableCell>
        );
      }

      return <TableCell key={index}>{value}</TableCell>;
    },
    [handleEdit, openDeleteModal, state.isLoading]
  );

  const filteredClientsData = useMemo(() => {
    if (!state.clientData) return [];
    const searchQueryLower = state.searchClientQuery.trim().toLowerCase();
    if (searchQueryLower === "") {
      return state.clientData;
    }
    return state.clientData.filter((item) =>
      item.name?.toLowerCase().includes(searchQueryLower)
    );
  }, [state.clientData, state.searchClientQuery]);

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [localErrorMessage, setLocalErrorMessage] = useState(false);

  useEffect(() => {
    if (isError && errorMessage) {
      setLocalErrorMessage(errorMessage);
      setShowErrorModal(true);
    }
  }, [isError, errorMessage]);

  const handleCancelErrorModal = () => {
    setShowErrorModal(false);
    dispatch(clearError());
  };

  if (showErrorModal && localErrorMessage) {
    return (
      <div>
        <ErrorModal
          setName={t("Error")}
          labelText={localErrorMessage}
          handleButtonClick={
            isGenericError
              ? () => handleError(dispatch, navigate)
              : handleCancelErrorModal
          }
          deleteButtonLabel={isGenericError ? t("Reload") : t("Cancel")}
        />
      </div>
    );
  }

  return (
    <div className="all-client-container">
      {state.isLoading && (
        <MotifProgressLoader
          className="loader"
          show
          variant="default"
          data-testid="loading-spinner"
        />
      )}
      <Header />
      <div className="client-org-container">
        <Breadcrumbs
          items={[
            { label: t("All projects"), link: "/" },
            {
              label: projectName,
              link: `/project-home/${projectId}`,
              token: token,
            },
            {
              label: t("ClientOrganisation"),
              link: `/client-org/${projectId}`,
            },
          ]}
        />
        <div className="client-org-subheader">
          <Subheader
            isAdmin={isAdmin}
            page={languageData.ClientOrgPage}
            title={t("ClientOrganisation")}
            onChangeSearchInput={handleSearch}
            handleOpenModal={() => handleOpenModal("add")}
            disableButton={state.isLoading}
            CreateNewEN={t("CreateNew")}
            ClientOrgAccessTitleEN={t("ClientOrgPage")}
          />
          {state.isClientModalOpen && (
            <ClientModal
              onClose={handleCloseModal}
              setName={state.clientModalTitle}
              handleSourceValueClick={handleClientEditUpdateClick}
              modalMode={state.clientMode}
              nameLabel={t("Name")}
              typeLabel={t("Type")}
              EmailInput={t("EnterEmail")}
              InputName={t("EnterName")}
              SelectType={t("SelectType")}
              emailLabel={t("Email")}
              cancelButtonLabel={t("Cancel")}
              saveButtonLabel={t("DoneButton")}
              updateButtonLabel={t("Update")}
              types={state.types}
              isDisbaled={state.isLoading}
              selectedRow={state.selectedClientRow}
              pageName="Client"
            />
          )}
        </div>
        <ClientTable
          columns={columnsToDisplay}
          data={filteredClientsData}
          itemsPerPage={5}
          generateRowKey={generateRowKey}
          renderTableCell={(colName, value, row, index) => {
            const cellValue = colName
              .split(".")
              .reduce((acc, part) => acc?.[part], row);
            return renderClientsTableCell(colName, cellValue, row, index);
          }}
        />

        {state.isDeleteModalOpen && (
          <DeleteModal
            isOpen={state.isDeleteModalOpen}
            onClose={() =>
              dispatchState({
                type: "SET_STATE",
                payload: { isDeleteModalOpen: false },
              })
            }
            setName={t("DeleteLabel")}
            labelText={t("DeletConfirmationSources")}
            onCancel={() =>
              dispatchState({
                type: "SET_STATE",
                payload: { isDeleteModalOpen: false },
              })
            }
            handleDeleteClick={handleDelete}
            cancelButtonLabel={t("Cancel")}
            deleteButtonLabel={t("DeleteLabel")}
          />
        )}
      </div>
    </div>
  );
};

export default ClientOrg;
