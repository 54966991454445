import { PublicClientApplication } from "@azure/msal-browser";
import config from "../../config";

export const authConfig = {
  clientId: config.azureClientId,
  authority: `https://login.microsoftonline.com/${config.azureAppId}`,
  postLogoutRedirectUri: config.baseDomainUrl,
  navigateToLoginRequestUrl: true,
  applicationId: config.azureAppId,
};

const cache = {
  cacheLocation: "sessionStorage",
  storeAuthStateInCookie: true,
};
const getMsalConfig = () => {
  const msalConfig = {
    auth: authConfig,
    cache: cache,
  };

  return msalConfig;
};

const msalConfig = getMsalConfig();
export const msalInstance = new PublicClientApplication(msalConfig);
