import ExcelJS from "exceljs";

const downloadShortlistExcel = async (data) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Sheet1");

  worksheet.columns = [
    { header: "ESRS-AR16", key: "esrs", width: 30 },
    { header: "Topic Name", key: "dr", width: 30 },
    { header: "Parent", key: "relatedAR", width: 30 },
    { header: "ESG", key: "dataType", width: 30 },
    { header: "Human rights related", key: "voluntary", width: 30 },
    { header: "Explanation", key: "question", width: 50 },
    { header: "Description", key: "desc", width: 50 },
  ];

  worksheet.getRow(1).eachCell((cell) => {
    cell.font = { bold: true, color: { argb: "FFFFFFFF" }, size: 12 };
    cell.alignment = {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    };
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF000000" },
    };
    cell.border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
  });

  data.forEach((item) => {
    const row = worksheet.addRow({
      esrs: item?.esrsAr16,
      dr: item?.topicName,
      relatedAR: item?.parentName,
      dataType: item?.esg,
      voluntary: item?.isHumanRightsRelated,
      question: item?.definition,
      desc: item?.description,
    });
    let maxLineCount = 1;

    row.eachCell((cell, colNumber) => {
      const text = cell.value ? cell.value.toString() : "";
      const colWidth = worksheet.getColumn(colNumber).width;

      const approxLines = Math.ceil(text.length / (colWidth * 0.8));
      maxLineCount = Math.max(maxLineCount, approxLines);

      cell.alignment = { vertical: "middle", wrapText: true };
      if (colNumber === 4 || colNumber === 5) {
        cell.alignment.horizontal = "center";
      } else {
        cell.alignment.horizontal = "left";
      }
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      cell.font = { size: 11, name: "Calibri" };
    });
    row.height = 20 + Math.max(maxLineCount - 1, 0) * 15;
  });

  worksheet.columns.forEach((column) => {
    let maxLength = 0;
    column.eachCell({ includeEmpty: true }, (cell) => {
      const text = cell.text || "";
      maxLength = Math.max(maxLength, text.length);
    });
    column.width = Math.min(maxLength + 2, 50);
  });

  worksheet.pageSetup = {
    orientation: "landscape",
    fitToWidth: 1,
    fitToHeight: false,
    margins: {
      left: 0.5,
      right: 0.5,
      top: 0.75,
      bottom: 0.75,
      header: 0.3,
      footer: 0.3,
    },
    repeatRows: "1:1", // Repeat header row
    printOptions: {
      gridLines: true,
      horizontalCentered: true,
    },
    headerFooter: {
      oddHeader: "&C&16&K000000ESG Short List Data",
      oddFooter:
        "&L&10&K666666Generated on: " + new Date().toLocaleDateString(),
    },
  };

  // Generate and download the file
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = "ESG_Short_List_Data.xlsx";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export default downloadShortlistExcel;
