import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  GET_ALL_DMA_SM_IDENTIFIED_MATERIAL_IMPACT_RISK_OPPORTUNITY,
  GET_MATERIALITY_MATRIX_DATA,
  GET_SHORTLIST_REPORTING_DATA,
} from "../../services/Api";

const apiRequest = async (url, method, data, token) => {
  const config = {
    method,
    url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  };
  const response = await axios(config);
  return response.data;
};

const createThunk = (type, apiCall) => {
  return createAsyncThunk(type, async (params, { rejectWithValue }) => {
    try {
      return await apiCall(params);
    } catch (error) {
      console.error(`Error in ${type}:`, error);
      const errorMessage = error.response?.data?.message;

      if (!errorMessage) {
        return rejectWithValue({
          errorMessage:
            error.response?.data?.title ||
            error.message ||
            "Unknown error occurred",
          isGenericError: true,
        });
      } else {
        return rejectWithValue({ errorMessage, isGenericError: false });
      }
    }
  });
};

export const getMaterialIdentifiedImpactRiskOpportunity = createThunk(
  "dmaReporting/getMaterialIdentifiedImpactRiskOpportunity",
  ({ moduleId, token }) =>
    apiRequest(
      `${GET_ALL_DMA_SM_IDENTIFIED_MATERIAL_IMPACT_RISK_OPPORTUNITY}/${moduleId}`,
      "get",
      null,
      token
    )
);

export const getMaterialityMatrixData = createThunk(
  "dmaReporting/getMaterialityMatrixData",
  ({ moduleId, token }) =>
    apiRequest(`${GET_MATERIALITY_MATRIX_DATA}/${moduleId}`, "get", null, token)
);

export const getShortlistReportingData = createThunk(
  "dmaReporting/getShortlistReportingData",
  ({ moduleId, token }) =>
    apiRequest(
      `${GET_SHORTLIST_REPORTING_DATA}/${moduleId}`,
      "get",
      null,
      token
    )
);

const handleAsyncActions = (builder, action, successKey = "data") => {
  builder
    .addCase(action.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
      state.errorMessage = "";
    })
    .addCase(action.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state[successKey] = payload;
    })
    .addCase(action.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = payload.errorMessage;
      state.isGenericError = payload.isGenericError || false;
    });
};

const dmaReportingSlice = createSlice({
  name: "dmaReporting",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
    isGenericError: false,
    errorMessage: "",
    updateSuccess: false,
    postSuccess: false,
    types: [],
  },
  reducers: {
    clearError(state) {
      state.isError = false;
      state.errorMessage = "";
      state.isGenericError = false;
    },
  },
  extraReducers: (builder) => {
    handleAsyncActions(
      builder,
      getMaterialIdentifiedImpactRiskOpportunity,
      "postSuccess"
    );
    handleAsyncActions(builder, getMaterialityMatrixData, "postSuccess");
    handleAsyncActions(builder, getShortlistReportingData, "postSuccess");
  },
});

export const { clearError } = dmaReportingSlice.actions;
export default dmaReportingSlice.reducer;
