import React from "react";
import { Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  "& .MuiTooltip-tooltip": {
    backgroundColor: "#333", // Dark background
    color: "#fff", // White text
    fontSize: "0.8rem", // Smaller font
    padding: "8px 12px", // Padding for better appearance
    borderRadius: "4px", // Rounded edges
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)", // Shadow effect
  },
});

export default CustomTooltip;