import React, { useEffect, useState, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { Box, Grid, Button, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  MotifProgressLoader,
  MotifSelect,
  MotifOption,
  MotifFormField,
  MotifMessage,
  MotifErrorMessage,
} from "@ey-xd/motif-react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import IROAssessmentSubheader from "../../../components/iroAssessmentSubheader/IROAssessmentSubheader";
import {
  getDGADataPointQuery,
  getMaturityLevels,
  upsertDataPoint,
  getDataPointsByDRId,
  getDataPointsDetails,
  clearError,
} from "../../../features/slices/DGAContext";
import { fetchClientsDetails } from "../../../features/slices/ProjectScreens";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DgaAssessmentModal from "../../../components/modals/dmaModals/DgaAssessmentModals";
import MappingForm from "../../../components/forms/MappingForm";
import ErrorModal from "../../../components/modals/error/errorModal";
import AssessmentCard from "./AssessmentCard";
import ArModal from "../../../components/modals/dmaModals/ArModal";
import { handleError as handleLogoutError } from "../../../utils/handleError";

const getCookie = (name) => {
  const regex = new RegExp(`(^| )${name}=([^;]+)`);
  const match = regex.exec(document.cookie);
  return match ? match[2] : null;
};

const formatData = (data) => {
  try {
    const parsedData = JSON.parse(data);
    return parsedData
      .map(
        (item) => `
        <div>
          <h3>${item.AR}</h3>
          <p>${item.Content.replace(/\n/g, "<br/>\n")}</p>
        </div>
      `
      )
      .join("");
  } catch (error) {
    console.error("Error parsing application requirements:", error);
    return "";
  }
};

const AssessmentChecklist = ({ assessmentChecklistId }) => {
  const dispatch = useDispatch();
  const { moduleId: moduleIdParam } = useParams();
  const moduleId = parseInt(moduleIdParam, 10);
  const location = useLocation();
  const navigate = useNavigate();
  const projectId = location.state?.projectId;
  const [isLoading, setIsLoading] = useState(true);
  const [errorDesc, setErrorDesc] = useState("");
  const [data, setData] = useState([]);
  const [maturityLevels, setMaturityLevels] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedEsrs, setSelectedEsrs] = useState("");
  const [selectedDr, setSelectedDr] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [formData, setFormData] = useState({});
  const [raciDropdownData, setRaciDropdownData] = useState([]);
  const [initialData, setInitialData] = useState(null);
  const [isARModalOpen, setIsARModalOpen] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [localErrorMessage, setLocalErrorMessage] = useState("");
  const [dataPointsByDRId, setDataPointsByDRId] = useState([]);
  const [isDrModalOpen, setIsDrModalOpen] = useState(false);
  const [dr, setDr] = useState([]);
  const [esrs, setEsrs] = useState([]);
  const [question, setQuestion] = useState("");
  const [header, setHeader] = useState("");
  const [subHeader, setSubHeader] = useState("");

  const itemsPerPage = 5;
  const token =
    useSelector((state) => state.user.jwtToken) ||
    getCookie("authToken") ||
    sessionStorage.getItem("authToken");
  const { t } = useTranslation();
  const { isError, isGenericError, errorMessage } = useSelector(
    (state) => state.dgaSustainabilityMatters
  );

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const [dataResult, dataPointsByDRIdResult] = await Promise.all([
        dispatch(getDGADataPointQuery({ moduleId, token })).unwrap(),
        assessmentChecklistId !== null
          ? dispatch(
              getDataPointsByDRId({
                moduleId,
                drId: assessmentChecklistId,
                token,
              })
            ).unwrap()
          : Promise.resolve({ data: {} }),
      ]);
      setData(dataResult?.data);
      if (dataResult?.data.length > 0) {
        const dataPoints = dataResult.data.flatMap((item) => item.dataPoints);
        const drOptions = [...new Set(dataPoints.map((item) => item.dr))].map(
          (dr, index) => ({ dr, index })
        );
        const esrsOptions = [
          ...new Set(dataPoints.map((item) => item.esrs)),
        ].map((esrs, index) => ({ esrs, index }));
        setDr(drOptions);
        setEsrs(esrsOptions);
        setSelectedEsrs(
          dataPointsByDRIdResult?.data.esrs || esrsOptions[0]?.esrs
        );
        setSelectedDr(dataPointsByDRIdResult?.data.dr || drOptions[0]?.dr);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, moduleId, token, assessmentChecklistId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    const dataPoints = data.flatMap((item) => item.dataPoints);
    const filteredDrOptions = selectedEsrs
      ? dataPoints
          .filter((item) => item.esrs === selectedEsrs)
          .map((item) => item.dr)
      : dataPoints.map((item) => item.dr);
    const uniqueFilteredDrOptions = [...new Set(filteredDrOptions)].map(
      (dr, index) => ({ dr, index })
    );
    setDr(uniqueFilteredDrOptions);
    if (uniqueFilteredDrOptions.length > 0) {
      const currentDrExists = uniqueFilteredDrOptions.some(
        (option) => option.dr === selectedDr
      );
      if (!currentDrExists) {
        setSelectedDr(uniqueFilteredDrOptions[0].dr);
      }
    } else {
      setSelectedDr("");
    }
  }, [selectedEsrs, data, selectedDr]);

  useEffect(() => {
    if (selectedDr) {
      const selectedDataPoint = data.find((item) => item.dr === selectedDr);
      if (selectedDataPoint) {
        setQuestion(selectedDataPoint.question);
        setHeader(selectedDataPoint.dresrs);
        setSubHeader(selectedDataPoint.drName);
        setDataPointsByDRId(selectedDataPoint.drText);
      }
    }
  }, [selectedDr, data]);

  const uniqueEsrs = useMemo(() => esrs.map((option) => option.esrs), [esrs]);
  const uniqueDr = useMemo(() => dr.map((option) => option.dr), [dr]);

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedEsrs, selectedDr]);

  const filteredData = useMemo(
    () =>
      data
        .flatMap((item) => item.dataPoints)
        ?.filter(
          (item) =>
            (selectedEsrs ? item.esrs === selectedEsrs : true) &&
            (selectedDr ? item.dr === selectedDr : true)
        ),
    [data, selectedEsrs, selectedDr]
  );

  const paginatedData = useMemo(
    () =>
      filteredData?.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
      ),
    [filteredData, currentPage, itemsPerPage]
  );

  const handlePreviousPage = () =>
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));

  const handleNextPage = () => setCurrentPage((prevPage) => prevPage + 1);

  const handleEditClick = async (item) => {
    setSelectedItem(item);
    setIsLoading(true);
    setErrorDesc("");
    try {
      const [maturityLevelsResult, clientsDetailsResult, dataPointDetails] =
        await Promise.all([
          dispatch(getMaturityLevels({ token })).unwrap(),
          dispatch(fetchClientsDetails({ projectId, token })).unwrap(),
          dispatch(
            getDataPointsDetails({ moduleId, dpId: item.id, token })
          ).unwrap(),
        ]);
      setMaturityLevels(maturityLevelsResult?.data || []);
      setRaciDropdownData(
        clientsDetailsResult?.data.map((item) => ({
          ...item,
          typeName: item.clientOrganisationType?.name || "Unknown",
        })) || []
      );
      const selectedData = dataPointDetails?.data;
      const newFormData = {
        maturityLevelId: selectedData?.maturityLevelId?.toString() || "",
        descriptionOfGap: selectedData?.descriptionOfGap || "",
        documentation: selectedData?.documentation || "",
        sourceOfData: selectedData?.sourceOfData || "",
        completenessOfData: selectedData?.completenessOfData || "",
      };
      setFormData(newFormData);
      setInitialData({
        responsible: selectedData?.raciResponsibleId?.toString() || "",
        accountable: selectedData?.raciAccountableId?.toString() || "",
        consulted: selectedData?.raciConsultedId?.toString() || "",
        informed: selectedData?.raciInformedId?.toString() || "",
        others: selectedData?.raciOthersId?.toString() || "",
      });
      setIsModalOpen(true);
    } catch (error) {
      console.error("Error fetching data point:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleARClick = async (item) => {
    setIsLoading(true);
    try {
      const dataPointDetails = await dispatch(
        getDataPointsDetails({ moduleId, dpId: item.id, token })
      ).unwrap();
      const arData = dataPointDetails?.data.applicationRequirements;
      setSelectedItem({ ...item, arData });
      setIsARModalOpen(true);
    } catch (error) {
      console.error("Error fetching application requirements:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (field, value) => {
    if (value.length <= 4000) {
      setFormData((prevData) => ({ ...prevData, [field]: value }));
      setErrorDesc((prevErrorDesc) => {
        const newErrorDesc = { ...prevErrorDesc, [field]: "" };
        const hasErrors = Object.values(newErrorDesc).some(
          (desc) => desc !== ""
        );
        return hasErrors ? newErrorDesc : "";
      });
    } else {
      setErrorDesc((prevErrorDesc) => ({
        ...prevErrorDesc,
        [field]: "Character limit exceeded. Maximum allowed is 4000.",
      }));
    }
  };

  const handleSave = async () => {
    if (!selectedItem) return;

    const payload = {
      moduleId: moduleId,
      dPstaticId: selectedItem.id,
      maturityLevelId: parseInt(formData.maturityLevelId, 10) || 0,
      materiality: true,
      descriptionOfGap: formData.descriptionOfGap || "",
      documentation: formData.documentation || "",
      sourceOfData: formData.sourceOfData || "",
      completenessOfData: formData.completenessOfData || "",
      comment: "Comment",
      raciResponsibleId: parseInt(initialData.responsible, 10) || null,
      raciAccountableId: parseInt(initialData.accountable, 10) || null,
      raciConsultedId: parseInt(initialData.consulted, 10) || null,
      raciInformedId: parseInt(initialData.informed, 10) || null,
      raciOthersId: parseInt(initialData.others, 10) || null,
    };
    try {
      setIsLoading(true);
      await dispatch(
        upsertDataPoint({ datapointData: payload, token })
      ).unwrap();
      const updatedData = await dispatch(
        getDGADataPointQuery({ moduleId, token })
      ).unwrap();
      setData(updatedData?.data);
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error saving data point:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRaciChange = (updatedData) =>
    setInitialData((prevData) => ({ ...prevData, ...updatedData }));

  const handleDisclosureRequirementClick = () => setIsDrModalOpen(true);

  const tabs = useMemo(
    () => [
      {
        label: "GAP",
        content: (
          <div>
            <div style={{ marginBottom: "20px" }}>
              <MotifMessage id="form-title">{t("MaturityLevel")}</MotifMessage>
              <MotifFormField>
                <MotifSelect
                  className="iro-textField"
                  ariaLabelledBy="select-dma-select-label"
                  placeholder="Select maturity level"
                  visibleOptions={3}
                  onChange={(e) => handleChange("maturityLevelId", e)}
                  value={formData?.maturityLevelId || ""}
                >
                  {maturityLevels.map((item) => (
                    <MotifOption key={item.id} value={item.id.toString()}>
                      {item.name}
                    </MotifOption>
                  ))}
                </MotifSelect>
              </MotifFormField>
            </div>
            <div>
              <MotifMessage className="iro-label">
                Description of GAP
              </MotifMessage>
              <TextField
                placeholder="Enter text here"
                multiline
                fullWidth
                rows={4}
                value={formData.descriptionOfGap || ""}
                onChange={(e) =>
                  handleChange("descriptionOfGap", e.target.value)
                }
              />
              {errorDesc.descriptionOfGap && (
                <MotifErrorMessage>
                  {errorDesc.descriptionOfGap}
                </MotifErrorMessage>
              )}
            </div>
          </div>
        ),
      },
      {
        label: "Documentation",
        content: (
          <div>
            <MotifMessage className="iro-label">Description</MotifMessage>
            <TextField
              placeholder="Enter text here"
              multiline
              fullWidth
              rows={4}
              value={formData.documentation || ""}
              onChange={(e) => handleChange("documentation", e.target.value)}
            />
            {errorDesc.sourceOfData && (
              <MotifErrorMessage>{errorDesc.sourceOfData}</MotifErrorMessage>
            )}
          </div>
        ),
      },
      {
        label: "Source of data",
        content: (
          <div>
            <MotifMessage className="iro-label">Description</MotifMessage>
            <TextField
              placeholder="Enter text here"
              multiline
              fullWidth
              rows={4}
              value={formData.sourceOfData || ""}
              onChange={(e) => handleChange("sourceOfData", e.target.value)}
            />
            {errorDesc.completenessOfData && (
              <MotifErrorMessage>
                {errorDesc.completenessOfData}
              </MotifErrorMessage>
            )}
          </div>
        ),
      },
      {
        label: "Completeness",
        content: (
          <div>
            <MotifMessage className="iro-label">Description</MotifMessage>
            <TextField
              placeholder="Enter text here"
              multiline
              fullWidth
              rows={4}
              value={formData.completenessOfData || ""}
              onChange={(e) =>
                handleChange("completenessOfData", e.target.value)
              }
            />
            {errorDesc && <MotifErrorMessage>{errorDesc}</MotifErrorMessage>}
          </div>
        ),
      },
      {
        label: "RACI",
        content: (
          <MappingForm
            responsibleLabel={t("Responsible")}
            accountableLabel={t("Accountable")}
            consultedLabel={t("Consulted")}
            informedLabel={t("Informed")}
            othersLabel={t("Others")}
            selectResponsilbleLabel={t("SelectResponsible")}
            selectAccountableLabel={t("SelectAccountable")}
            selectConsultedLabel={t("SelectConsulted")}
            selectInformedLabel={t("SelectInformed")}
            selectOthersLabel={t("SelectOthers")}
            RACIMapping={t("RACIMapping")}
            raciDropdownData={raciDropdownData}
            initialData={initialData}
            onChange={handleRaciChange}
          />
        ),
      },
    ],
    [formData, maturityLevels, raciDropdownData, initialData, t, errorDesc]
  );

  useEffect(() => {
    if (isError && errorMessage) {
      setLocalErrorMessage(errorMessage);
      setShowErrorModal(true);
    }
  }, [isError, errorMessage]);

  const handleCancelErrorModal = () => {
    setShowErrorModal(false);
    dispatch(clearError());
  };

  if (showErrorModal && localErrorMessage) {
    return (
      <ErrorModal
        setName={t("Error")}
        labelText={localErrorMessage}
        handleButtonClick={
          isGenericError
            ? () => handleLogoutError(dispatch, navigate)
            : handleCancelErrorModal
        }
        deleteButtonLabel={isGenericError ? t("Reload") : t("Cancel")}
      />
    );
  }

  return (
    <>
      {isLoading && (
        <MotifProgressLoader
          data-testid="loading-spinner"
          className="loader"
          show
          variant="default"
        />
      )}
      <Box sx={{ margin: "auto", padding: "10px" }}>
        <IROAssessmentSubheader
          header={header}
          subHeader={subHeader}
          previousButtonLabel="Disclosure requirement"
          SelectESRS="ESRS"
          processText={question}
          SelectDisclosure="Disclosure requirement"
          EsrsArray={uniqueEsrs}
          GeneralDisclosureArray={uniqueDr}
          esrs={selectedEsrs}
          generalDisclosure={selectedDr}
          setEsrs={setSelectedEsrs}
          setGeneralDisclosure={setSelectedDr}
          handlePreviousClick={handleDisclosureRequirementClick}
        />
      </Box>
      <Box sx={{ maxWidth: "1376px", margin: "auto", padding: "10px" }}>
        <Grid container spacing={3}>
          {paginatedData?.map((item) => (
            <AssessmentCard
              key={item.id}
              item={item}
              onEdit={handleEditClick}
              onARClick={handleARClick}
              isDisabled={!item.isARAvailable}
            />
          ))}
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 4,
          }}
        >
          <Button
            variant="contained"
            startIcon={<ArrowBackIosIcon />}
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
            sx={{
              backgroundColor: "black",
              color: "white",
              textTransform: "none",
            }}
          >
            Previous
          </Button>
          <Button
            variant="contained"
            endIcon={<ArrowForwardIosIcon />}
            onClick={handleNextPage}
            disabled={paginatedData?.length < itemsPerPage}
            sx={{
              backgroundColor: "black",
              color: "white",
              textTransform: "none",
            }}
          >
            Next
          </Button>
        </Box>
      </Box>
      {isModalOpen && (
        <DgaAssessmentModal
          onClose={() => setIsModalOpen(false)}
          modalTitle="Assessment"
          tabs={tabs}
          saveButtonLabel="Save"
          cancelButtonLabel="Cancel"
          disable={!!errorDesc}
          onSave={handleSave}
        />
      )}
      {isDrModalOpen && (
        <ArModal
          onClose={() => setIsDrModalOpen(false)}
          title="Disclosure Requirement"
          description={dataPointsByDRId}
          cancelButtonLabel="Close"
        />
      )}
      {isARModalOpen && (
        <ArModal
          onClose={() => setIsARModalOpen(false)}
          title="Application requirements"
          description={formatData(selectedItem?.arData)}
          cancelButtonLabel="Close"
        />
      )}
    </>
  );
};

AssessmentChecklist.propTypes = { assessmentChecklistId: PropTypes.number };
export default AssessmentChecklist;