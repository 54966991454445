import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ExcelJS from "exceljs";
import { MotifProgressLoader } from "@ey-xd/motif-react";
import DMACards from "./dmaCards/DMACards";
import { Stake, Reporting } from "../../../constants/icons/Icons";
import {
  fetchUnderstandingTheContextCount,
  fetchIROCount,
  fetchIroReportingData,
  fetchShortlistExcelData,
  fetchIROCoverage,
} from "../../../features/slices/DMAOverview";
import { fetchExcelData } from "../../../features/slices/LongListData";
import ErrorModal from "../../../components/modals/error/errorModal";
import { useTranslation } from "react-i18next";
import { handleError as handleLogoutError } from "../../../utils/handleError";
import { addSheet } from "../../../utils/excelUtils";
import { handleDownloadIroReport } from "../../../utils/iroExcel";
import downloadShortlistExcel from "../../../utils/shortlistExcelUtil";

const getCookie = (name) => {
  const regex = new RegExp(`(^| )${name}=([^;]+)`);
  const match = regex.exec(document.cookie);
  return match ? match[2] : null;
};

const formatExcelData = (data) => {
  const workbook = new ExcelJS.Workbook();
  addSheet(workbook, "Sustainability Matters", data);
  return workbook;
};

const downloadExcel = async (data) => {
  const workbook = formatExcelData(data);
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = "sustainability_matters.xlsx";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};

const DMAOverview = ({ onTabChange, navigateToReporting }) => {
  const dispatch = useDispatch();
  const { moduleId } = useParams();
  const token =
    useSelector((state) => state.user.jwtToken) ||
    getCookie("authToken") ||
    sessionStorage.getItem("authToken");
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("sources");
  const [isExcelLoading, setIsExcelLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [iroData, setIroData] = useState(null);

  const { contextData, isError, errorMessage } = useSelector(
    (state) => state.dmaOverview
  );

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [contextCount, iroCount, iroData] = await Promise.all([
          dispatch(fetchUnderstandingTheContextCount({ moduleId, token })),
          dispatch(fetchIROCount({ moduleId, token })),
          dispatch(fetchIROCoverage({ moduleId, token })),
        ]);
        setIroData(iroData?.payload.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    return () => {
      setActiveTab("Overview");
      setIsExcelLoading(false);
      setIsLoading(true);
    };
  }, [dispatch, moduleId, token]);

  const transformDataToSunburst = (apiData) => {
    if (!apiData) return { name: "Root", children: [] };
    const root = { name: "Root", children: [] };
    const esrsMap = new Map();
    apiData.forEach((item) => {
      if (!esrsMap.has(item.esrs)) {
        esrsMap.set(item.esrs, {
          name: item.esrs,
          totalIROAssessments: item.totalIROAssessments,
          maxAssessmentScore: item.maxAssessmentScore,
          hasMaterialAssessment: item.hasMaterialAssessment,
          totalESRSIROCount: item.totalESRSIROCount,
          children: [],
        });
      }
      const esrsNode = esrsMap.get(item.esrs);
      const topicNode = {
        name: item.topic,
        totalIROAssessments: item.totalIROAssessments,
        maxAssessmentScore: item.maxAssessmentScore,
        hasMaterialAssessment: item.hasMaterialAssessment,
        totalESRSIROCount: item.totalESRSIROCount,
        children: item.esrS_AR16_Topics.map((topic) => ({
          name: topic.esrS_AR16_Topic,
          value: 1,
          totalIROAssessments: topic.totalIROAssessments,
          maxAssessmentScore: topic.maxAssessmentScore,
          hasMaterialAssessment: topic.hasMaterialAssessment,
          totalESRSIROCount: topic.totalESRSIROCount,
        })),
      };
      esrsNode.children.push(topicNode);
    });
    root.children = Array.from(esrsMap.values());
    return root;
  };
  const chartData = iroData
    ? transformDataToSunburst(iroData)
    : { name: "root", children: [] };

  const handleDownloadExcel = async () => {
    setIsExcelLoading(true);
    try {
      const excelResponse = await dispatch(
        fetchExcelData({ moduleId, token })
      ).unwrap();
      const exceResponseData = excelResponse?.data || [];
      await downloadExcel(exceResponseData);
    } catch (error) {
      console.error("Error fetching Excel data:", error);
    } finally {
      setIsExcelLoading(false);
    }
  };

  const handleDownloadIroReportWrapper = async () => {
    setIsExcelLoading(true);
    try {
      const iroResponseData = await dispatch(
        fetchIroReportingData({ moduleId, token })
      ).unwrap();
      const iroReportingData = iroResponseData?.data.responseData || [];
      await handleDownloadIroReport(iroReportingData);
    } catch (error) {
      console.error("Error fetching IRO data:", error);
    } finally {
      setIsExcelLoading(false);
    }
  };

  const handleShortlistExcelData = async () => {
    setIsExcelLoading(true);
    try {
      const shortlistResponseData = await dispatch(
        fetchShortlistExcelData({ moduleId, token })
      ).unwrap();
      const shortlistData = shortlistResponseData?.data || [];
      await downloadShortlistExcel(shortlistData);
    } catch (error) {
      console.error("Error fetching IRO data:", error);
    } finally {
      setIsExcelLoading(false);
    }
  };

  if (isError) {
    return (
      <div>
        <ErrorModal
          setName={t("Error")}
          labelText={errorMessage}
          handleButtonClick={handleLogoutError(dispatch)}
          deleteButtonLabel={t("Reload")}
        />
      </div>
    );
  }

  return (
    <div className="dma-overview-container">
      {isLoading || isExcelLoading ? (
        <MotifProgressLoader className="loader" show variant="default" />
      ) : (
        <DMACards
          headerIconLeft={<Stake />}
          lefttype={t("UTC")}
          UTC={t("UTC")}
          IroCoverage={t("IRO Coverage")}
          references={contextData?.data}
          headerIconRight={<Reporting />}
          Longlist={t("Long list - Sustainable Matters")}
          IroReport={t("IRO Report")}
          Shortlist={t("Short list - Sustainable Matters")}
          Dashboard={t("Dashboard")}
          ReportingImplication={t("ReportingImplication")}
          rightHeaderType={t("ReportingImplication")}
          onTabChange={onTabChange}
          iroData={chartData}
          onIroReportClick={handleDownloadIroReportWrapper}
          onLonglistClick={handleDownloadExcel}
          onShortListClick={handleShortlistExcelData}
          navigateToReporting={navigateToReporting}
        />
      )}
    </div>
  );
};

DMAOverview.propTypes = {
  onTabChange: PropTypes.func.isRequired,
  navigateToReporting: PropTypes.func.isRequired,
};

export default DMAOverview;
