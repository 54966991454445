import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { logOutToken } from "../../features/slices/UsersData";
import "./Header.scss";
import MenuIcon from "@mui/icons-material/Menu";
import {
  MotifButton,
  MotifAvatar,
  MotifDropdownItem,
  MotifDropdown,
} from "@ey-xd/motif-react";
import notice from "../../assets/files/PrivacyNoticeESGSuite3.0.pdf";
import {
  PrivacyNote,
  HelpDesk,
  Maintenance,
  Location,
} from "../../constants/icons/Icons";
import EYHeaderLogo from "../../assets/images/HeaderLogo.png";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { logoutUser, setUser, setPhoto } from "../../features/slices/HomeSlice";
import config from "../../config";
import Select from "react-select";

const getCookie = (name) => {
  const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
  return match ? match[2] : null;
};

const signOutClickHandler = async (instance) => {
  const logoutRequest = {
    postLogoutRedirectUri: config.baseDomainUrl || window.location.origin,
    navigateToLoginRequestUrl: false,
  };
  await instance.logoutRedirect(logoutRequest);
};

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const { instance } = useMsal();

  const { user, photo, jwtToken } = useSelector((state) => state.user);

  const token =
    jwtToken || getCookie("authToken") || sessionStorage.getItem("authToken");
  const name = user?.name || getCookie("name");

  const [state, setState] = useState({
    dropdownVisible: false,
    mobileMenuVisible: false,
  });

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setState((prevState) => ({ ...prevState, dropdownVisible: false }));
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const storedUserData = sessionStorage.getItem("user");
    if (!user && storedUserData) {
      dispatch(setUser(JSON.parse(storedUserData)));
    }

    const storedLanguage = sessionStorage.getItem("language");
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    } else {
      const preferredLanguage = "en";
      i18n.changeLanguage(preferredLanguage);
      sessionStorage.setItem("language", preferredLanguage);
    }

    const storedPhoto = sessionStorage.getItem("photo");
    if (!photo && storedPhoto) {
      dispatch(setPhoto(storedPhoto));
    }
  }, [dispatch, i18n, user, photo]);

  const handleSignOut = async () => {
    const controller = new AbortController();

    try {
      await new Promise((resolve) => {
        dispatch(logoutUser());
        resolve();
      });

      await new Promise((resolve, reject) => {
        dispatch(logOutToken(token))
          .then((response) => {
            if (response.error) {
              reject(response.error);
            } else {
              resolve(response);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });

      localStorage.clear();
      sessionStorage.clear();
      await signOutClickHandler(instance);
    } catch (error) {
      console.error("Error during sign-out", error);
    } finally {
      controller.abort();
    }
  };

  const handleLanguageChange = (selectedOption) => {
    const language = selectedOption.value;
    i18n.changeLanguage(language);
    sessionStorage.setItem("language", language);
  };

  const languageOptions = [
    { value: "en", label: "EN" },
    { value: "es", label: "ES" },
  ];

  useEffect(() => {
    document.title = `ESG Suite | ${config.instanceLocation}`;
    if (location.state?.projectName) {
      document.title = `ESG Suite | ${config.instanceLocation} | ${location.state.projectName}`;
    }
  }, [location.state?.projectName]);

  return (
    <header className="header-container">
      <div>
        <a href="/" aria-label="Home" className="logo-title">
          <img src={EYHeaderLogo} alt="Logo" width="40" height="40" />
          <h1 className="brand-title">{t("Title")}</h1>
        </a>
      </div>
      <nav className="nav-items">
        <ul>
          <li>
            <MotifButton
              className="header-motif-body"
              onClick={() => window.open(notice, "_blank")}
                aria-label="Open Privacy Notice"
              data-testid="privacy-note-button"
            >
              <PrivacyNote />
              {t("PrivacyNote")}
            </MotifButton>
          </li>
          <li>
            <a href="mailto:esgsuite@ey.com" className="no-underline">
              <MotifButton
                className="header-motif-body"
                data-testid="help-desk-button"
                aria-label="Contact Help Desk"
              >
                <HelpDesk />
                {t("HelpDesk")}
              </MotifButton>
            </a>
          </li>
          {user && (
            <li>
              <MotifButton
                className="header-motif-body"
                onClick={() =>
                  navigate("/maintenance", { state: { token: token } })
                }
                 aria-label="Navigate to Maintenance Page"
              >
                <Maintenance />
                {t("Maintenance")}
              </MotifButton>
            </li>
          )}
          <li>
            <MotifButton className="header-motif-body">
              <Location />
              {config.instanceLocation}
            </MotifButton>
          </li>
        </ul>
      </nav>
      <div className="right-menu">
        <button
          className="burger-menu"
          onClick={() =>
            setState((prevState) => ({
              ...prevState,
              mobileMenuVisible: !prevState.mobileMenuVisible,
            }))
          }
          data-testid="menu-icon"
          aria-label="Toggle mobile menu"
          tabIndex={0}
        >
          <MenuIcon size={30} />
        </button>
        <MotifDropdown
          ariaLabelledby="dropdown-trigger-1"
          closeOnOutsideClick={false}
          id="dropdown-1"
          placement="bottom-start"
          trigger={
            <div>
              <MotifAvatar
                className="avatar-portal"
                data-testid="user-avatar"
                description={name}
                size="medium"
                userName={name}
                src={
                  photo || getCookie("photo")
                    ? `data:image/png;base64,${photo || getCookie("photo")}`
                    : undefined
                }
                onError={(e) => (e.target.src = undefined)}
                onClick={() =>
                  setState((prevState) => ({
                    ...prevState,
                    dropdownVisible: !prevState.dropdownVisible,
                  }))
                }
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    e.preventDefault();
                    setState((prevState) => ({
                      ...prevState,
                      dropdownVisible: !prevState.dropdownVisible,
                    }));
                  }
                }}
              />
            </div>
          }
        >
          {(user || getCookie("name")) && (
            <div className="header-dropdown-menu">
              <ul aria-label="profile menu">
                <li>
                  <MotifDropdownItem>
                    <Select
                      aria-labelledby="select-label"
                      className="header-select"
                      onChange={handleLanguageChange}
                      value={languageOptions.find(
                        (option) => option.value === i18n.language
                      )}
                      options={languageOptions}
                    />
                  </MotifDropdownItem>
                </li>
                <span className="motif-dropdown-horizontal-divider" />
                <li>
                  <MotifButton
                    className="header-motif-body"
                    onClick={handleSignOut}
                    data-testid="sign-out-button"
                  >
                    {t("SignOut")}
                  </MotifButton>
                </li>
              </ul>
            </div>
          )}
        </MotifDropdown>
      </div>
      {state.mobileMenuVisible && (
        <div
          className={`mobile-menu ${state.mobileMenuVisible ? "show" : ""}`}
          data-testid="mobile-menu"
        >
          <ul>
            <li>
              <MotifButton
                className="header-motif-body"
                onClick={() => window.open(notice, "_blank")}
                data-testid="mobile-privacy-note-button"
              >
                <PrivacyNote />
                {t("PrivacyNote")}
              </MotifButton>
            </li>
            <li>
              <MotifButton
                className="header-motif-body"
                data-testid="mobile-help-desk-button"
              >
                <HelpDesk />
                {t("HelpDesk")}
              </MotifButton>
            </li>
            {user && (
              <li>
                <MotifButton
                  className="header-motif-body"
                  onClick={() =>
                    navigate("/maintenance", { state: { token: token } })
                  }
                >
                  <Maintenance />
                  {t("Maintenance")}
                </MotifButton>
              </li>
            )}
            <li>
              <MotifButton className="header-motif-body">
                <Location />
                {config.instanceLocation}
              </MotifButton>
            </li>
          </ul>
        </div>
      )}
    </header>
  );
};

Header.propTypes = {
  isAdmin: PropTypes.bool,
};

export default Header;
