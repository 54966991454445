import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import {
  getESRSChecklistSustainabilityMatters,
  getESRSChecklistDisclosureRequirements,
  upsertESRSChecklistSustainabilityMatters,
  upsertDisclosureData,
  clearError,
} from "../../../features/slices/ESRSChecklist";
import { Box } from "@mui/material";
import { MotifProgressLoader, MotifButton } from "@ey-xd/motif-react";
import TreeView from "../../../components/treeView/TreeView";
import ContextSelection from "../../../components/contextSelection/ContextSelection";
import { useTranslation } from "react-i18next";
import CommentBox from "../../../components/commentBox/CommentBox";
import "./UnderstandTheContext.scss";
import ErrorModal from "../../../components/modals/error/errorModal";
import { handleError as handleLogoutError } from "../../../utils/handleError";
import PropTypes from "prop-types";

const EsrsChecklistUnderstandTheContext = ({
  setAssessmentChecklistId,
  navigateToAssessment,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedDrItemId, setSelectedDrItemId] = useState(null);
  const { moduleId: moduleIdParam } = useParams();
  const moduleId = parseInt(moduleIdParam, 10);
  const dispatch = useDispatch();
  const getCookie = (name) => {
    const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
    return match ? match[2] : null;
  };

  const token =
    useSelector((state) => state.user.jwtToken) ||
    getCookie("authToken") ||
    sessionStorage.getItem("authToken");
  const { t } = useTranslation();
  const [smData, setSmData] = useState([]);
  const [drData, setDrData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [comment, setComment] = useState("");
  const [isToggled, setIsToggled] = useState(false);
  const [label, setLabel] = useState("");
  const [selectedView, setSelectedView] = useState("SMEsrs16");
  const [drComment, setDrComment] = useState("");
  const [isDrToggled, setIsDrToggled] = useState(false);
  const [drLabel, setDrLabel] = useState("");
  const navigate = useNavigate();
  const { isError, isGenericError, errorMessage } = useSelector(
    (state) => state.esrsChecklistSustainabilityMatters
  );

  const [dotColorState, setDotColorState] = useState({});
  const [materialityState, setMaterialityState] = useState({});
  const [dotSmColorState, setDotSmColorState] = useState({});
  const [materialitySmState, setMaterialitySmState] = useState({});

  const getDotColor = (isMaterial) => {
    if (isMaterial === true) return "green";
    if (isMaterial === false) return "red";
    return "grey";
  };

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const [smResponse, drResponse] = await Promise.all([
        dispatch(getESRSChecklistSustainabilityMatters({ moduleId, token })),
        dispatch(getESRSChecklistDisclosureRequirements({ moduleId, token })),
      ]);

      const smData = smResponse.payload.data || [];
      const drData = drResponse.payload.data || [];
      setSmData(smData);
      setDrData(drData);

      if (smData.length > 0) {
        const firstItem = smData[0];
        setSelectedItemId(firstItem.id);
        setComment(firstItem.comment || "");
        setIsToggled(firstItem.materiality || false);
        setLabel(
          <span className="ellipsis">
            {firstItem.label || firstItem.name || ""}
          </span>
        );
      }
      if (drData.length > 0) {
        const firstDrItem = drData[0];
        setSelectedDrItemId(firstDrItem.id);
        setDrComment(firstDrItem.comment || "");
        setIsDrToggled(firstDrItem.materiality || false);
        setDrLabel(
          <span className="ellipsis">
            {firstDrItem.label || firstDrItem.name || ""}
          </span>
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, moduleId, token]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (selectedView === "Disclosure") {
      fetchData();
    }
  }, [selectedView, fetchData]);

  const findItemById = useCallback((data, id) => {
    for (let item of data) {
      if (item.id === id) return item;
      if (item.children) {
        const result = findItemById(item.children, id);
        if (result) return result;
      }
    }
    return null;
  }, []);

  const handleSelectClick = useCallback(
    (id) => {
      const data = selectedView === "SMEsrs16" ? smData : drData;
      const selectedItem = findItemById(data, id);
      if (selectedItem) {
        if (selectedView === "SMEsrs16") {
          setSelectedItemId(id);
          setComment(selectedItem.comment || "");
          setIsToggled(selectedItem.materiality || false);
          setLabel(
            <span className="ellipsis">
              {selectedItem.label || selectedItem.name || ""}
            </span>
          );
        } else {
          setSelectedDrItemId(id);
          setDrComment(selectedItem.comment || "");
          setIsDrToggled(selectedItem.materiality || false);
          setDrLabel(
            <span className="ellipsis">
              {selectedItem.label || selectedItem.name || ""}
            </span>
          );
        }
      }
    },
    [selectedView, smData, drData, findItemById]
  );

  const handleEditClick = (id) => {
    const assessmentChecklistId =
      typeof id === "string" ? parseInt(id.replace(/^\D+/g, "")) : id;
    setAssessmentChecklistId(assessmentChecklistId);
    navigateToAssessment();
  };

  useEffect(() => {
    if (smData.length > 0) {
      const states = smData.reduce(
        (acc, item) => {
          const updateStates = (item) => {
            acc.dotColors[item.id] = {
              dotColor: getDotColor(item.materiality),
            };
            acc.materiality[item.id] = item.materiality;
            if (item.children) {
              item.children.forEach(updateStates);
            }
          };
          updateStates(item);
          return acc;
        },
        { dotColors: {}, materiality: {} }
      );

      setDotSmColorState(states.dotColors);
      setMaterialitySmState(states.materiality);
    }
  }, [smData]);

  useEffect(() => {
    if (drData.length > 0) {
      const states = drData.reduce(
        (acc, item) => {
          acc.dotColors[item.id] = { dotColor: getDotColor(item.materiality) };
          acc.materiality[item.id] = item.materiality;
          return acc;
        },
        { dotColors: {}, materiality: {} }
      );

      setDotColorState(states.dotColors);
      setMaterialityState(states.materiality);
    }
  }, [drData]);

  const handleSMSave = useCallback(
    async (comment, isToggled, selectedItemId, setData) => {
      setIsLoading(true);

    // Recursively find all all IDs
    const getAllIds = (parentId, items) => {
      let allIds = [];
      items.forEach((item) => {
        if (item.parentId === parentId) {
          allIds.push(item.id);
          allIds = allIds.concat(getAllIds(item.id, items)); // Recursively adding children
        }
      });
      return allIds;
    };
 
    const idsToUpsert = [selectedItemId, ...getAllIds(selectedItemId, smData)];

      try {
        const newData = {
          moduleSustainabilityMatterId: idsToUpsert,
          comment,
          materiality: isToggled,
          moduleId,
        };
        await dispatch(
          upsertESRSChecklistSustainabilityMatters({
            sustainabilityMattersData: newData,
            token,
          })
        ).unwrap();

        const response = await dispatch(
          getESRSChecklistSustainabilityMatters({ moduleId, token })
        );
        const data = response.payload.data || [];
        setData(data);
      } catch (error) {
        console.error("Creation failed");
      }
      setIsLoading(false);
    },
    [dispatch, moduleId, token, smData]
  );

  const handleDRSave = useCallback(
    async (comment, isToggled, selectedItemId, setData) => {
      setIsLoading(true);
      try {
        const newData = {
          moduleId,
          drStaticId: selectedItemId,
          comment,
          materiality: isToggled,
        };
        await dispatch(
          upsertDisclosureData({
            disclosureData: newData,
            token,
          })
        ).unwrap();

        const response = await dispatch(
          getESRSChecklistDisclosureRequirements({ moduleId, token })
        );
        const data = response.payload.data || [];
        setData(data);
      } catch (error) {
        console.error("Creation failed");
      }
      setIsLoading(false);
    },
    [dispatch, moduleId, token]
  );

  const handleSaveForView = useCallback(
    async (comment, isToggled) => {
      if (selectedView === "SMEsrs16") {
        await handleSMSave(comment, isToggled, selectedItemId, setSmData);
      } else if (selectedView === "Disclosure") {
        await handleDRSave(comment, isToggled, selectedDrItemId, setDrData);
      }
    },
    [selectedView, handleSMSave, handleDRSave, selectedItemId, selectedDrItemId]
  );

  const mapToParentChildren = (items) => {
    const itemMap = {};
    const result = [];

    items.forEach((item) => {
      itemMap[item.id] = { ...item, children: [] };
    });

    items.forEach((item) => {
      if (item.parentId !== null) {
        itemMap[item.parentId].children.push(itemMap[item.id]);
      } else {
        result.push(itemMap[item.id]);
      }
    });

    return result;
  };

  const transformedESRSItems = useMemo(
    () => mapToParentChildren(smData) || [],
    [smData]
  );

  const transformedDRItems = useMemo(
    () => mapToParentChildren(drData) || [],
    [drData]
  );

  const handleSearchInputChange = useCallback((event) => {
    setSearchInput(event);
  }, []);

  const filterItems = (items, query) => {
    if (!query) return items;
    return items
      .map((item) => {
        if (
          item?.label?.toLowerCase().includes(query.toLowerCase()) ||
          item?.description?.toLowerCase().includes(query.toLowerCase())
        )
          return item;
        if (item.children) {
          const filteredChildren = filterItems(item.children, query);
          if (filteredChildren.length > 0)
            return { ...item, children: filteredChildren };
        }
        return null;
      })
      .filter(Boolean);
  };

  const filteredESRSItems = filterItems(transformedESRSItems, searchInput);
  const filteredDRItems = filterItems(transformedDRItems, searchInput);

  const handleCancel = useCallback(() => {
    console.log("Cancelled");
  }, []);

  const renderTreeView = (
    items,
    handleSelectClick,
    handleEditClick,
    selectedItemId,
    viewType,
    dotColorState,
    materialityState
  ) => (
    <Box
      className="motif-col-1 motif-col-lg-8 sm-file-explorer-container"
      sx={{
        border: "1px solid #e0e0e0",
        borderRadius: "8px",
        padding: "16px",
        height: "100%",
        overflowY: "auto",
      }}
    >
      <TreeView
        items={items}
        pageName="DgaContext"
        defaultExpandedItems={[]}
        defaultSelectedItems={selectedItemId ? [selectedItemId] : []}
        onEditClick={handleEditClick}
        onSelect={handleSelectClick}
        viewType={viewType}
        dotSmColorState={dotSmColorState}
        materialitySmState={materialitySmState}
        dotColorState={dotColorState}
        materialityState={materialityState}
      />
    </Box>
  );

  const renderCommentBox = (
    label,
    comment,
    setComment,
    isToggled,
    setIsToggled,
    handleSave,
    showExtraField = false,
    dmaResults = "",
    setDmaResults = () => {},
    dotColorState = {}
  ) => (
    <div className="motif-col-1 motif-col-lg-4 comment-box-container">
      <CommentBox
        onSave={handleSave}
        onCancel={handleCancel}
        label={label}
        cancelButtonLabel={t("Cancel")}
        saveButtonLabel={t("Save")}
        isMaterial={isToggled ? t("isMaterial") : t("Not material")}
        comment={comment}
        setComment={setComment}
        isToggled={isToggled}
        setIsToggled={setIsToggled}
        showExtraField={showExtraField}
        dmaResults={dmaResults}
        setDmaResults={setDmaResults}
        dotColorState={dotColorState}
      />
    </div>
  );

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [localErrorMessage, setLocalErrorMessage] = useState(false);

  // Whenever isError or errorMessage changes, reset showErrorModal
  useEffect(() => {
    if (isError && errorMessage) {
      setLocalErrorMessage(errorMessage);
      setShowErrorModal(true);
    }
  }, [isError, errorMessage]);

  const handleCancelErrorModal = () => {
    setShowErrorModal(false);
    dispatch(clearError());
  };

  if (showErrorModal && localErrorMessage) {
    return (
      <div>
        <ErrorModal
          setName={t("Error")}
          labelText={localErrorMessage}
          handleButtonClick={
            isGenericError
              ? () => handleLogoutError(dispatch, navigate)
              : handleCancelErrorModal
          }
          deleteButtonLabel={isGenericError ? t("Reload") : t("Cancel")}
        />
      </div>
    );
  }

  return (
    <>
      {isLoading && (
        <MotifProgressLoader
          data-testid="loading-spinner"
          className="loader"
          show
          variant="default"
        />
      )}
      {!isLoading && (
        <div className="context-selection-container">
          <ContextSelection
            pageName="DgaContext"
            downloadButtonText="Download longlist"
            onChangeSearchInput={handleSearchInputChange}
          />
        </div>
      )}
      <div className="esrs-checklist-assessment-subheader-section2">
        <MotifButton
          className={`dga-toggle-button ${
            selectedView === "SMEsrs16" ? "selected" : ""
          }`}
          onClick={() => setSelectedView("SMEsrs16")}
        >
          DMA results
        </MotifButton>
        <MotifButton
          className={`dga-toggle-button ${
            selectedView === "Disclosure" ? "selected" : ""
          }`}
          onClick={() => setSelectedView("Disclosure")}
        >
          Disclosure requirement review
        </MotifButton>
      </div>
      <div className="motif-row dga-assessment">
        {selectedView === "SMEsrs16" && (
          <>
            {renderTreeView(
              filteredESRSItems,
              handleSelectClick,
              handleSelectClick,
              selectedItemId,
              selectedView,
              dotSmColorState,
              materialitySmState
            )}
            {renderCommentBox(
              label,
              comment,
              setComment,
              isToggled,
              setIsToggled,
              handleSaveForView,
              false,
              "",
              {},
              dotSmColorState[selectedItemId]
            )}
          </>
        )}
        {selectedView === "Disclosure" && (
          <>
            {renderTreeView(
              filteredDRItems,
              handleSelectClick,
              handleEditClick,
              selectedDrItemId,
              selectedView,
              dotColorState,
              materialityState
            )}

            {/* Check if the selected item is a parent */}
            {(() => {
              const selectedItem = findItemById(
                filteredDRItems,
                selectedDrItemId
              );
              if (selectedItem?.children?.length > 0) {
                return null;
              }
              return renderCommentBox(
                drLabel,
                drComment,
                setDrComment,
                isDrToggled,
                setIsDrToggled,
                handleSaveForView
              );
            })()}
          </>
        )}
      </div>
    </>
  );
};

EsrsChecklistUnderstandTheContext.propTypes = {
  setAssessmentChecklistId: PropTypes.func,
  navigateToAssessment: PropTypes.func,
};

export default EsrsChecklistUnderstandTheContext;