import config from "../config";

const API_BASE_URL = config.apiBaseUrl;
const GRAPH_BASE_URL = "https://graph.microsoft.com/v1.0";
export const MS_GRAPH_URL = {
  GetUserPhoto: `${GRAPH_BASE_URL}/me/photo/$value`,
  GetOrganization: (organizationId) =>
    `${GRAPH_BASE_URL}/organization/${organizationId}?$select=displayName`,
};

//Users URL
export const FETCH_AD_USERS = "https://graph.microsoft.com/v1.0/users";
export const CHECK_USER_CAN_BE_ADMIN = `${API_BASE_URL}User/CheckIfUserCanBeAdmin`;
export const GET_LOGOUT_TOKEN = `${API_BASE_URL}User/logout`;

// Project URLs
export const GET_PROJECT_DETAILS_URL = `${API_BASE_URL}Project/Get`;
export const GET_ALL_PROJECTS = `${API_BASE_URL}Project/GetAll`;
export const CREATE_PROJECT_URL = `${API_BASE_URL}Project/Create`;
export const UPDATE_PROJECT_DETAILS_URL = `${API_BASE_URL}Project/Update`;
export const PROJECT_BASE_URL = `${API_BASE_URL}Project/`;
export const GET_USERS_URL = `GetAllUsers`;
export const CREATE_NEW_USER_URL = `AddUser`;
export const DELETE_USER_URL = `/DeleteUser`;
export const RETIRE_PROJECT_URL = `${API_BASE_URL}Project/Retire`;
export const UPDATE_USER_URL = `${API_BASE_URL}Project/UpdateProjectUser`;

//User URL's
export const GET_ALL_USERS_URL = `${API_BASE_URL}Users/GetAll`;

// Data URL's
export const GET_PROJECT_USER_ROLES = `${API_BASE_URL}Data/GetAllProjectRoles`;
export const GET_MODULE_TYPES_URL = `${API_BASE_URL}Data/GetAllModuleTypes`;
export const GET_MODULE_TYPES_FOR_SESSION_URL = `${API_BASE_URL}Module/GetAllModuleTypesForSession`;
export const FETCH_COUNTRIES_URL = `${API_BASE_URL}Data/GetAllCountries`;
export const FETCH_SET_MODES_URL = `${API_BASE_URL}Module/GetAllModuleAssessmentTypes`;
export const FETCH_PHASES_URL = `${API_BASE_URL}Module/GetAllPhaseInFilters`;
export const FETCH_SECTORS_URL = `${API_BASE_URL}Data/GetSectorList`;
export const GET_ALL_FRAMEWORK = `${API_BASE_URL}Data/GetAllFramework`;

// Session URL's
export const CREATE_SESSION_URL = `${API_BASE_URL}Session/Create`;
export const UPDATE_SESSION_URL = `${API_BASE_URL}Session/Update`;
export const RETIRE_SESSION_URL = `${API_BASE_URL}Session/Retire`;

//Module URL's
export const CREATE_MODULE_URL = `${API_BASE_URL}Module/Create`;
export const UPDATE_MODULE_URL = `${API_BASE_URL}Module/Update`;
export const GET_MODULE_BY_ID = `${API_BASE_URL}Module/GetModuleById`;
export const RETIRE_MODULE_URL = `${API_BASE_URL}Module/Retire`;

//File Manager screen URls
export const LIST_OF_FILES = `${API_BASE_URL}BlobStorage/GetAllFilesByProjectId`;
export const UPLOAD_FILES = `${API_BASE_URL}BlobStorage/UploadFile`;
export const DOWNLOAD_FILES = `${API_BASE_URL}BlobStorage/DownloadFile`;
export const DELETE_FILES = `${API_BASE_URL}BlobStorage/DeleteFile`;

// Maintenance URLs
export const GET_ALL_ADMIN_PROJECTS = `${API_BASE_URL}Maintenance/GetAllProjects`;
export const GET_ALL_Status_URL = `${API_BASE_URL}Maintenance/GetStatus`;
export const GET_MAINTENANCE_STATUS_URL = `${API_BASE_URL}Maintenance/UpdateStatus`;
export const GET_ALL_MODULES = `${API_BASE_URL}Maintenance/GetAllDMAModulesForCopy`;
export const GET_ALL_COPY_GEOPGRAPHICAL_LOCATION_TO_TEMPLATE = `${API_BASE_URL}Maintenance/CopyGeographicalLocationToTemplate`;
export const GET_ALL_COPY_STAKEHOLDER_TO_TEMPLATE = `${API_BASE_URL}Maintenance/CopyStakeHolderToTemplate`;
export const GET_ALL_COPY_VALUE_CHAIN_TO_TEMPLATE = `${API_BASE_URL}Maintenance/CopyValueChainToTemplate`;
export const GET_ALL_COPY_SOURCES_TO_TEMPLATE = `${API_BASE_URL}Maintenance/CopySourceToTemplate`;
export const GET_ALL_COPY_APS_TO_TEMPLATE = `${API_BASE_URL}Maintenance/CopyActProServiceToTemplate`;
export const GET_ALL_COPY_ALL_TO_TEMPLATE = `${API_BASE_URL}Maintenance/CopyAllModuleTablesToTemplate`;
export const GET_ALL_COPY_IRO_MODULE_TEMPLATE = `${API_BASE_URL}Maintenance/CopyIROToTemplate`;

// DMA APS URLs
export const GET_ALL_APS_TYPES_URL = `${API_BASE_URL}DMA/GetAllAPSTypes`;
export const GET_ALL_APS_URL = `${API_BASE_URL}DMA/GetAllAPS/`;
export const CREATE_APS_URL = `${API_BASE_URL}DMA/CreateAPS`;
export const UPDATE_APS_URL = `${API_BASE_URL}DMA/UpdateAPS`;
export const DELETE_APS_URL = `${API_BASE_URL}DMA/DeleteAPS`;

// DMA Sources URLs
export const GET_ALL_SOURCES_TYPES_URL = `${API_BASE_URL}DMA/GetSourceTypes`;
export const GET_ALL_SOURCES_URL = `${API_BASE_URL}DMA/GetAllSources`;
export const CREATE_SOURCES_URL = `${API_BASE_URL}DMA/CreateSource`;
export const UPDATE_SOURCES_URL = `${API_BASE_URL}DMA/UpdateSource`;
export const DELETE_SOURCES_URL = `${API_BASE_URL}DMA/DeleteSource`;

// DMA Geographical Location URLs
export const GET_ALL_GL_URL = `${API_BASE_URL}DMA/GetAllGeographicalLocation`;
export const CREATE_GL_URL = `${API_BASE_URL}DMA/CreateGeographicalLocation`;
export const EDIT_GL_URL = `${API_BASE_URL}DMA/UpdateGeographicalLocation`;
export const DELETE_GL_URL = `${API_BASE_URL}DMA/DeleteGeographicalLocation`;

// DMA Stakeholder URLs
export const GET_ALL_SH_URL = `${API_BASE_URL}DMA/GetAllStakeholders`;
export const CREATE_SH_URL = `${API_BASE_URL}DMA/CreateStakeholder`;
export const UPDATE_SH_URL = `${API_BASE_URL}DMA/UpdateStakeholder`;
export const GET_SH_TYPES_URL = `${API_BASE_URL}DMA/GetStakeholderTypes`;
export const GET_SH_IE_TYPES_URL = `${API_BASE_URL}DMA/GetStakeholderIntExtTypes`;
export const DELETE_SH = `${API_BASE_URL}DMA/DeleteStakeholder`;

// DMA Value Chain URLs
export const GET_VALUE_CHAIN_TYPES = `${API_BASE_URL}DMA/GetValueChainTypes`;
export const CREATE_VALUE_CHAIN = `${API_BASE_URL}DMA/CreateValueChain`;
export const UPDATE_VALUE_CHAIN = `${API_BASE_URL}DMA/UpdateValueChain`;
export const DELETE_VALUE_CHAIN = `${API_BASE_URL}DMA/DeleteValueChain`;
export const GET_VALUE_CHAIN = `${API_BASE_URL}DMA/GetAllValueChain`;

// DMA Sustainability Matters URLs
export const GET_ALL_SUSTAINABILITY_MATTERS = `${API_BASE_URL}DMA/GetAllModuleSustainabilityMatter`;
export const CREATE_SUSTAINABILITY_MATTERS = `${API_BASE_URL}DMA/CreateSustainabilityMatter`;
export const UPDATE_SUSTAINABILITY_MATTERS = `${API_BASE_URL}DMA/UpdateSustainabilityMatter`;
export const DELETE_SUSTAINABILITY_MATTERS = `${API_BASE_URL}DMA/DeleteSustainabilityMatter`;

// DMA ESRS URLs
export const GET_ESRS_URL = `${API_BASE_URL}DMA/GetFrameworkReferences`;
export const GET_ESRS_AR16_URL = `${API_BASE_URL}Data/GetAR16`;

// Get Overview Screen URLs
export const GET_UTC_COUNT = `${API_BASE_URL}DMA/GetCount`;
export const CREATE_UPDATE_THRESHOLD = `${API_BASE_URL}DMA/UpsertThreshold`;
export const GET_THRESHOLD = `${API_BASE_URL}DMA/GetThreshold`;
export const GET_THRESHOLD_TYPES = `${API_BASE_URL}DMA/GetThresholdTypes`;
export const GET_MODULE_COUNT = `${API_BASE_URL}DMA/GetModuleCounts`;
export const GET_IRO_COVERAGE = `${API_BASE_URL}DMA/GetModuleIROCoverage`;
// Get IRO Screen URLs
export const GET_ALL_IROs = `${API_BASE_URL}IRO/GetAllIROsForModule`;
export const CREATE_IRO = `${API_BASE_URL}IRO/Create`;
export const UPDATE_IRO = `${API_BASE_URL}IRO/Update`;
export const DELETE_IRO = `${API_BASE_URL}IRO/Delete`;
export const GET_SOURCES_IRO = `${API_BASE_URL}IRO/GetAllSourcesForModuleIRO`;
export const GET_VALUE_CHAIN_IRO = `${API_BASE_URL}IRO/GetModuleIROValueChains`;
export const GET_APS_IRO = `${API_BASE_URL}IRO/GetModuleIROActProServices`;
export const GET_GEOGRAPHIES_IRO = `${API_BASE_URL}IRO/GetModuleIROGeographicalLocations`;
export const GET_STAKEHOLDERS_IRO = `${API_BASE_URL}IRO/GetModuleIROStakeHolders`;
export const UPDATE_SOURCES_IRO = `${API_BASE_URL}IRO/UpdateModuleIROSources`;
export const UPDATE_VALUE_CHAIN_IRO = `${API_BASE_URL}IRO/UpdateModuleIROValueChains`;
export const UPDATE_APS_IRO = `${API_BASE_URL}IRO/UpdateModuleIROActProServices`;
export const UPDATE_GEOGRAPHIES_IRO = `${API_BASE_URL}IRO/UpdateModuleIROGeographicalLocations`;
export const UPDATE_STAKEHOLDERS_IRO = `${API_BASE_URL}IRO/UpdateModuleIROStakeHolders`;
export const GET_ALL_SUSTAINABILITY_MATTERS_IRO = `${API_BASE_URL}IRO/GetAllIROSustainabilityMatter`;
export const GET_SUSTAINABILITY_MATTERS_IRO = `${API_BASE_URL}IRO/GetIROSustainabilityMatters`;
export const UPDATE_SUSTAINABILITY_MATTERS_IRO = `${API_BASE_URL}IRO/UpdateIROSustainabilityMatter`;
export const GET_ALL_TIMEFRAME_TYPES = `${API_BASE_URL}Data/GetAllTimeframeTypes`;
export const GET_ALL_IRO_TYPES = `${API_BASE_URL}Data/GetAllIROTypes`;
export const GET_ALL_IRO_LIBRARY_SECTORS = `${API_BASE_URL}IRO/GetIROLibraryTypes`;
export const GET_LIBRARY_ITEMS = `${API_BASE_URL}IRO/GetIROLibraryItems`;
export const GET_LIBRARY_ITEMS_PAGED = `${API_BASE_URL}IRO/GetIROLibraryItemsPaged`;
export const GET_LIBRARY_IRO_TYPES = `${API_BASE_URL}IRO/GetIROTypes`;
export const GET_LIBRARY_IRO_AR16 = `${API_BASE_URL}Data/GetAR16`;
export const CREATE_SUSTAINABILITY_MATTERS_FROM_LIBRARY = `${API_BASE_URL}IRO/CreateSustainabilityMatterFromLibraryItems`;
export const FETCH_IRO_REPORTING_DATA = `${API_BASE_URL}IRO/GetIROReportingExcel`;

//Likelihood URLs
export const GET_ALL_LIKELIHOOD__URL = `${API_BASE_URL}DMA/GetAllModuleRatingLikelihoods`;
export const CREATE_LIKELIHOOD__URL = `${API_BASE_URL}DMA/CreateModuleRatingLikelihoods`;
export const UPDATE_LIKELIHOOD__URL = `${API_BASE_URL}DMA/UpdateModuleRatingLikelihoodViews`;
export const DELETE_LIKELIHOOD__URL = `${API_BASE_URL}DMA/DeleteModuleRatingLikelihoodViews`;

//Scores URL
export const GET_SCORES_URL = `${API_BASE_URL}DMA/GetAllModuleRatingScoreViews`;
export const CREATE_SCORES_URL = `${API_BASE_URL}DMA/CreateModuleRatingScoreViews`;
export const UPDATE_SCORES_URL = `${API_BASE_URL}DMA/UpdateModuleRatingScoreViews`;
export const DELETE_SCORES_URL = `${API_BASE_URL}DMA/DeleteModuleRatingScoreViews`;

//Clent URL
export const GET_CLIENTS_URL = `${API_BASE_URL}ClientOrganisation/GetClientOrganisationByProjectId`;
export const GET_CLIENTS_TYPES_URL = `${API_BASE_URL}ClientOrganisation/GetClientOrganisationTypes`;
export const CREATE_CLIENTS_URL = `${API_BASE_URL}ClientOrganisation/CreateClientOrganisationById`;
export const UPDATE_CLIENTS_URL = `${API_BASE_URL}ClientOrganisation/Update`;
export const DELETE_CLIENTS_URL = `${API_BASE_URL}ClientOrganisation/Delete`;

//Iro Assessment URL
export const GET_IRO_MODULE_BY_ID = `${API_BASE_URL}IRO/GetModuleIROById/`;
export const GET_ALL_MODULE_RATING = `${API_BASE_URL}IRO/GetAllModuleRating`;
export const GET_SOURCES_REFERENCE_LIST = `${API_BASE_URL}IRO/GetSourceReferenceList`;
export const GET_VALUE_CHAIN_REFERENCE_LIST = `${API_BASE_URL}IRO/GetValueChainReferenceList`;
export const GET_APS_REFERENCE_LIST = `${API_BASE_URL}IRO/GetAPSReferenceList`;
export const GET_STAKEHOLDER_REFERENCE_LIST = `${API_BASE_URL}IRO/GetStakeholderReferenceList`;
export const GET_GEOGRAPHICAL_REFERENCE_LIST = `${API_BASE_URL}IRO/GetGeographicalReferenceList`;
export const SAVE_RACIMAPPING_URL = `${API_BASE_URL}IRO/AddUpdateRACIMapping`;
export const CREATE_IRO_ASSESSMENT = `${API_BASE_URL}IRO/CreateIROAssessment`;
export const GET_IRO_ASSESSMENT = `${API_BASE_URL}IRO/GetAllAssessmentsList`;
export const UPDATE_IRO_ASSESSMENT = `${API_BASE_URL}IRO/UpdateIROAssessment`;
export const DELETE_IRO_ASSESSMENT = `${API_BASE_URL}IRO/DeleteIROAssessment`;

// DMA Reporting URLs
export const GET_ALL_DMA_SM_IDENTIFIED_MATERIAL_IMPACT_RISK_OPPORTUNITY = `${API_BASE_URL}IRO/GetReportingImplicationData`;

//Export Excel LongList URL
export const GET_EXCEL_DATA = `${API_BASE_URL}IRO/GetLongListData`;

//Export Excel Shortlist URL
export const GET_EXCEL_SHORTLIST_DATA = `${API_BASE_URL}IRO/GetShortlistMaterialSMs`;

//GET Materiallity MAtrix data
export const GET_MATERIALITY_MATRIX_DATA = `${API_BASE_URL}IRO/GetSMsMaterialityMatrix`;

//GET Shortlist Reporting data
export const GET_SHORTLIST_REPORTING_DATA = `${API_BASE_URL}IRO/GetShortListIRODetails`;

// DGA Context Section URLs
export const GET_ALL_DGA_SM = `${API_BASE_URL}DGA/GetAllStep1SustainableMatters`;
export const UPSERT_DGA_SM = `${API_BASE_URL}DGA/UpsertStep1SustainableMatter`;
export const GET_ALL_DR = `${API_BASE_URL}DGA/GetDisclosureReqirements`;
export const UPSERT_DR = `${API_BASE_URL}DGA/UpsertDisclosureRequirementStep2`;

// ESRS Checklist Context Section URLs
export const GET_ALL_ESRS_CHECKLIST_SM = `${API_BASE_URL}ESRS/GetAllESRSStep1SustainableMatters`;
export const UPSERT_ESRS_CHECKLIST_SM = `${API_BASE_URL}ESRS/UpsertESRSStep1SustainableMatter`;
export const GET_ALL_ESRS_CHECKLIST_DR = `${API_BASE_URL}ESRS/GetESRSDisclosureRequirements`;
export const UPSERT_ESRS_CHECKLIST_DR = `${API_BASE_URL}ESRS/UpsertESRSDisclosureRequirementStep2`;

//DGA Assessment URL
export const GET_ALL_DATAPOINT_QUERY = `${API_BASE_URL}DGA/GetDataPointsQuery`;
export const GET_ALL_MATURITY_LEVEL_DGA = `${API_BASE_URL}DGA/GetMaturityLevels`;
export const UPSERT_DATAPOINT = `${API_BASE_URL}DGA/UpsertDataPointStep3`;
export const GET_DATA_POINTS_BY_DR_ID = `${API_BASE_URL}DGA/GetDataPointsByDRId`;
export const GET_DATA_POINT_DETAILS = `${API_BASE_URL}DGA/GetDataPointDetails`;

//DGA Dashboard URL
export const GET_GAUGE_CHART1_IN_SCOPE = `${API_BASE_URL}DGA/GetDGAChart1Inscope`;
export const GET_GAUGE_CHART2_ASSESED = `${API_BASE_URL}/DGA/GetDGAChart2Assessed`;
export const GET_GAUGE_CHART3_MATURITY = `${API_BASE_URL}/DGA/GetDGAChart3Maturity`;