// ContextSubHeader.jsx
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./ContextSubHeader.scss";

const ContextSubHeader = ({ children, activeTab, setActiveTab }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const childrenArray = React.Children.toArray(children);
    const newIndex = childrenArray.findIndex((child) => {
      if (child.props.text && typeof child.props.text === "string") {
        return (
          child.props.text.trim().toLowerCase() ===
          activeTab.trim().toLowerCase()
        );
      }
      return false;
    });

    if (newIndex !== -1) {
      setActiveIndex(newIndex);
    }
  }, [activeTab, children]);

  const handleItemClick = (index, text) => {
    setActiveIndex(index);
    setActiveTab(text); // text is guaranteed to be a string
  };

  const childrenArray = React.Children.toArray(children);

  return (
    <>
      <div
        className="context-stepper context-background"
        style={{ fontFamily: "EYInterstate !important" }}
      >
        {childrenArray.map((child, index) => {
          const { text, icon } = child.props;
          const isActive = index === activeIndex;
          return (
            <button
              key={child.key || index}
              className={`context-box-item ${isActive ? "active" : ""}`}
              data-testid={`context-box-item-${index}`}
              onClick={() => handleItemClick(index, text)}
            >
              <div className="context-content">
                <div className="context-text">
                  <span className="context-label">{text}</span>
                  {icon ? (
                    <span className="context-icon">{icon}</span>
                  ) : (
                    <span className="context-icon icon-placeholder" />
                  )}
                </div>
              </div>
            </button>
          );
        })}
      </div>

      <div
        className="context-section-content"
        data-testid="context-section-content"
      >
        {childrenArray[activeIndex].props.children}
      </div>
    </>
  );
};

ContextSubHeader.propTypes = {
  children: PropTypes.node.isRequired,
  activeTab: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired,
};

export default ContextSubHeader;
